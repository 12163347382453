/**
 * TEK | Holiday Lights
 */


/* Simple SVG Christmas Lights Animation */

  
#Lights {
    height: 100%;
    margin: 0;
    width: 100%;
    z-index: 1000
  }
  
  .cls-9 {
    fill: none;
    stroke: #8C6239;
  }
  
  .cls-2 {
    fill: #603813;
  }

  .cls-3 {
    fill: #8C6239;
  }
  
  .teal_bulb {
    fill: #018AA8;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #018AA8);
    filter: drop-shadow(1px 1px 10px #018AA8);
  }
  
  .purple_bulb {
    fill: #685899;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #685899);
    filter: drop-shadow(1px 1px 10px #685899);
  }
  
  .white_bulb {
    fill: #FFECD7;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #FFECD7);
    filter: drop-shadow(1px 1px 10px #FFECD7);
  }
  
  .red_bulb {
    fill: #CE402C;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #CE402C);
    filter: drop-shadow(1px 1px 10px #CE402C);
  }
  
  .green_bulb {
    fill: #60AA20;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #60AA20);
    filter: drop-shadow(1px 1px 10px #60AA20);
  }

  .orange_bulb {
    fill: #FFA400;
    stroke: #6D6D6D;
    stroke-miterlimit: 10;
    -webkit-filter: drop-shadow(1px 1px 10px #FFA400);
    filter: drop-shadow(1px 1px 10px #FFA400);
  }
  
  
  /* Simply changing the opacity of the SVG fill to create the glow effect for the bulbs */
  
  .bulb {
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: glow;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
    animation-name: glow;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }
  
  @-webkit-keyframes glow {
    0% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: .25;
      -webkit-filter: initial;
      filter: initial;

    }
  }
  
  @keyframes glow {
    0% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: .25;
      -webkit-filter: initial;
      filter: initial;
      box-shadow: violet;

    }
  }
  
  
  /* Made a set of ten animation delays to create a more random effect of lights blinking.  Simplest way without jumping through SVG hoops. */
  
  .blink-1 {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .blink-2 {
    -webkit-animation-delay: .12s;
    animation-delay: .12s;
  }
  
  .blink-3 {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
  }
  
  .blink-4 {
    -webkit-animation-delay: .34s;
    animation-delay: .34s;
  }
  
  .blink-5 {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
  }
  
  .blink-6 {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }
  
  .blink-7 {
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
  }
  
  .blink-8 {
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
  }
  
  .blink-9 {
    -webkit-animation-delay: 0.95s;
    animation-delay: 0.95s;
  }
  
  .blink-10 {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }