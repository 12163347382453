/**
 * TEK | SLANTS
 */


//////////////////////////////////////
// Home page "e" background fix
//////////////////////////////////////

#e-fix-opacity {
	opacity: 0 !important;
}

#e-fix-position {
	position: absolute !important;
	top: 24% !important;
	left: -8% !important;
}

//////////////////////////////////////

 .slant-container {
 	position: relative;
 	z-index: 1;
 	margin: bsu(2) 0;

 	@include breakpoint( large ) {
		margin: bsu(4) 0;
	} //large
 }

.slant,
.accent-slant {
	position: relative;
	overflow: hidden;
}

.flickerfix {
	-webkit-transform: translate3d(0,0,0);
}

.slant {
	position: relative;
	transform: rotate(-6deg) translate(0, -20px); // Previous: rotate(-8deg);
	width: 130%;
	left: -15%;
	z-index: 1;

	.tek-overlay {
		position: absolute;
		mix-blend-mode: color-dodge;
		opacity: 0.35;

		path {
			fill: $blue;
		}
	}

	> div {
		position: relative;
		width: 99%;
		height: 125%;
		top: bsu(1);
		transform: rotate(6deg);// Previous: rotate(-8deg);
	}

	.wrap {
		width: 80%;
	}

	&.reverse {
		transform: rotate(8deg);

		> div {
			transform: rotate(-8deg);
			clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0 75%);
			background: $white;
		}
	}

	@include breakpoint( small ) {
		width: 120%;
		left: -10%;
	} //small

	@include breakpoint( medium ) {
		> div {
			top: bsu(1.85);
		}
	}

	@include breakpoint( large ) {
		> div {
			top: bsu(3);
		}
	}

	@include breakpoint( xlarge ) {
		transform: rotate(-6deg) translate(0, -20px); // Previous: rotate(-6deg);

		> div {
			transform: rotate(6deg);
		}

		&.reverse {
			transform: rotate(6deg);

			> div {
				transform: rotate(-6deg);
			}
		}
	} //xlarge

	@include breakpoint( mega ) {
		transform: rotate(-6deg) translate(0, 30px); // Previous: nothing

		> div {
			top: bsu(4);
		}
	} //mega
}

// banner specific slant styling
.banner-slant {
	> div {
		padding: bsu(8) 0 bsu(6);

		&:before,
		&:after {
			@include overlay();
		}

		&:after {
			background-color: rgba(18,28,98,0.4);
			height: 125%;
			z-index: -1;
			top: -10%;
		}
	}

	// background image
	img {
		@include cover-image();
		position: absolute;
		z-index: -2;
		top: -25%;
		left: 0px;
		height: 150%;
	}

	@include breakpoint( medium ) {
		> div {
			padding: bsu(10) 0 bsu(6);
		}
	} //medium

	@include breakpoint( large ) {
		> div {
			padding: bsu(13) 0 bsu(6);
		}
	} //large

	@include breakpoint( xlarge ) {

		> div {
			padding: bsu(12) 0 bsu(10);
		}
	} //xlarge

	@include breakpoint( mega ) {
		// background image
		img {
			top: 0;
			height: 120%; // Previous nothing
		}

		> div {
			padding: bsu(17) 0 bsu(8);
		}
	} //mega
} 

.dual-slant-overlay {
	position: absolute;
	overflow: hidden;
	width: 120%;
	height: 200px;
	z-index: 2;
	left: -10%;
	top: -20%;
	background: $white;
	z-index: 2;
	transform: rotate(8deg);

	&.reverse {
		transform: rotate(-8deg);
	}

	@include breakpoint( medium ) {
		height: 290px; // Previous: 250px;
		top: -25%;
	} //medium

	@include breakpoint( large ) {
		height: 400px;
	} //large

	@include breakpoint( xlarge ) {
		top: -28%;
		//height: 475px;
		transform: rotate(6deg);

		&.reverse {
			transform: rotate(-6deg);
		}
	} //xlarge

	@include breakpoint( mega ) {
		height: 550px;
	} //mega
}


///////////////////////////////
// ACCENT SLANTS
///////////////////////////////

.accent-slant {
	display: none;
	width: 600px;
	height: 200px;
	transform: rotate(20deg); // Previous: rotate(12deg); 
	float: right;
	left: initial;
	top: -150px;
	right: -200px;
	z-index: 0;

	> div {
		position: relative;
		width: 80%;
		height: 130%;
		transform: rotate(-20deg) translate(-70px, 35px); // Previous: rotate(-12deg); 

		&:before, &:after {
			@include overlay();
		}

		&:before {
			background: linear-gradient(115deg, $orange-light 0%,$orange 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 );
			mix-blend-mode: overlay;
		}

		&:after {
			background-image: url('../images/accent-facet-overlay.png');
			background-repeat: no-repeat;
			background-position: 50px -55px;
			background-size: cover;

			@include breakpoint ( xlarge ) {
				background-position: center center;
			}

			@include breakpoint ( mega ) {
				background-position: -60px -170px;
			}
		}
	}

	&.reverse {
		float: left;
		top: initial;
		bottom: -85%;
		right: initial;
		transform: rotate(16deg);

		> div {
			top: -200px;
			width: 100%;
			transform: rotate(-16deg);

			&:before {
				background: linear-gradient(115deg, $blue 0%,$blue-dark 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='$blue-dark',GradientType=1 );
				mix-blend-mode: overlay;
				height: 110%;
			}
		}
	}

	@include breakpoint( large ) {
		display: block;
	}

	@include breakpoint( xlarge ) {
		width: 700px;
		> div {
			width: 90%;
		}
	}//xlarge

	@include breakpoint( xxlarge ) {
		width: 820px;
		transform: rotate(20deg) translate(0px, -20px); // Previous: nothing
		top: -175px;

		> div {
			transform: rotate(-20deg) translate(-5px, 35px); // Previous: nothing
		}
	}//xxlarge

	@include breakpoint( mega ) {
		width: 870px; // Previous: nothing
		transform: rotate(23deg) translate(0px, 45px); // Previous: nothing
		height: 330px; // Previous: height: 300px;
		top: -250px;
		right: -250px;

		> div {
			transform: rotate(-23deg) translate(25px, 115px); // Previous: nothing
		}
	}//mega
}
