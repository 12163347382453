/**
 * TEK | BANNER
 */

.hero-banner {
  position: relative;

  .banner-slant,
  .accent-slant {
    position: relative;
    overflow: hidden;
  }

  .banner-slant {
    height: 200px;
    top: -100px;

    .tek-overlay {
      top: 50%;
      margin-top: -300px;
      left: -1500px;
      width: 2142px;
      height: 800px;
    }

    > div {
      padding: 0;
      top: 0;

      &:before {
        top: 100px;
        height: 80px;
        background: linear-gradient(to bottom, rgba(0, 18, 23, 0.7) 0%, rgba(0, 18, 23, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001217', endColorstr='#00001217', GradientType=0);
      }

      &:after {
        background-color: rgba(18, 28, 98, 0.7);
      }
    }

    // background image
    img {
      top: 0px;
    }
  }

  .accent-slant {
    top: -275px;
  }

  .wrap {
    text-align: center;
    color: $white;
    padding: bsu(8) bsu(0) bsu(5);
    @include breakpoint(large) {

      text-align: left;
    }
  }
  .secondary-title-wrapper {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
  }
  .secondary-title {
    margin: 0px !important;
  }

  .titlewrapper {
    display: inline-block;
  }

  //title rotate
  .primary-title, .secondary-title {
    text-align: left;
    display: inline-block;

  }
  .primary-title {

    margin-left: auto;
    text-align: center;
    display: block;
    @include breakpoint(large) {
      margin-left: rem-calc(70);
      display: inline-block;
    }
  }
  h2 {
    text-align: center;
  }
  .secondary-title-wrapper, .slick-slider {

  }
  // Prevents h2 text from shifting up/down during title transitions
  .secondary-title-wrapper {
    @include breakpoint(  zero  ) { height: 65px;  }
    @include breakpoint( medium ) { height: 75px;  }
    @include breakpoint( large  ) { height: 108px; }
  }
  .secondary-title {
    text-align: center;
    display: block;
    margin: auto;
    @include breakpoint(medium) {
      margin-left: rem-calc(70);
      display: inline-block;
    }

    &[hidden] {
      display: none;
    }

    &:before {
      content: "\00a0";
      font-size: 0em; //weird spacing fix
    }

  }
  // home page adjustments (parent selector)
  body.home & {

    .banner-slant {
      > div {
        &:after {
          background-color: initial;
        }
      }
    }

    h1 {
      @include fp(font-size, 48, 90);
    }

    h2 {
      @include fp(font-size, 18, 44);
      font-weight: 600;
      padding: bsu(1) bsu(1) 0;
      line-height: rem-calc(36px);
    }

    .banner-slant {
      height: auto;
      background-color: $white;
      img {
        height: 115%;
      }
    }

    video {
      display: none;
      position: fixed;
      z-index: -1;
      top: 50%;
      left: 50%;
      min-width: 120%;
      min-height: 120%;
      transform: translateX(-50%) translateY(-50%);
    }

    .hero-arrow {
      position: absolute;
      width: 138px;
      height: 90px;
      bottom: 60px;
      left: 50%;
      margin-left: -69px;
      z-index: 3;
    }

  }

  @include breakpoint(medium) {
    .banner-slant {
      height: 240px;

      > div {
        height: 150%;

        &:before {
          height: 120px;
        }
      }
    }

    body.home & {
      .banner-slant {
        img {
          height: 115%;
        }
      }
    }
  }
  //medium

  @include breakpoint(large) {
    .banner-slant {
      height: 300px;

      .tek-overlay {
        margin-top: -480px;
        left: -2220px;
        width: 3213px;
        height: 1200px;
      }
    }

    .wrap {
      padding: bsu(12) bsu(0) bsu(8);
    }

    body.home & {
      h2 {
        padding: bsu(1) bsu(4) 0;
      }

      video {
        display: block;
      }

      .hero-arrow {
        width: 214px;
        height: 140px;
        margin-left: -112px;
        bottom: 35px;
        @include transition-ease-in-out();

        &:hover {
          bottom: 15px;
        }
        
      }
    }
  }
  //large

  @include breakpoint(xxlarge) {

    .wrap {
      padding: bsu(16) bsu(0) bsu(12);
    }
  }
  //xxlarge

  @include breakpoint(mega) {
    .banner-slant {
      height: 350px;
      top: -150px;

      img {
          height: 115%;
        }
    }

    .accent-slant {
      top: -400px;
    }
  }
  //mega
}