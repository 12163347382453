/**
 * TEK | image-slant
 */

.image-slant {
	position: relative;
	top: -13em;
}


.image-slant-container {
	position: relative;
	z-index: 0;
	margin: bsu(0) 0;

	@include breakpoint( large ) {
	   margin: bsu(0) 0;
   } //large
}