/**
 * TEK | FOOTER CTA
 */

.footer-cta {

	color: $white;
	text-align: center;
	margin-top: 0px;

	[role="button"] {
		position: absolute;
		bottom: -50px;
		z-index: 2;
		left: 50%;
		margin-left: -125px;
		width: 250px;
	}

	/*
	.hero-arrow {
		position: absolute;
		width: 138px;
		height: 90px;
		top: 150px;
		left: 50%;
		margin-left: -69px;
		z-index: 3;
	} */

	@include breakpoint( zero ) {
		.hero-arrow {
			display: none;
		}
	}

	@include breakpoint( medium ) {
		.hero-arrow {
			position: absolute;
			display: block;
			width: 138px;
			height: 90px;
			top: 65px;
			left: 50%;
			margin-left: -69px;
			z-index: 3;
		}
	}

	@include breakpoint( large ) {
		.hero-arrow {
			width: 214px;
			height: 140px;
			margin-left: -112px;
			top: 140px;

			&:hover {
				top: 160px;
			}
		}
	}

	@include breakpoint( mega ) {
		.hero-arrow {
			top: 220px;

			&:hover {
				top: 240px;
			}
		}
	}

	.banner-slant {
		.tek-overlay {
			display: none;
			opacity: 0.5;
			top: 25%;
			left: 50%;
			width: 2142px;
			height: 800px;
		}

		> div {
			&:before {
				background-image: url('../images/footer-cta-facet-overlay.png');
				background-repeat: no-repeat;
				background-position: 20% 50%;
				height: 110%;
			}
		}
	}

	h2 {
		@include fp( font-size, 48, 90 );
		padding: 0 1rem;
	}

	@include breakpoint( xlarge ) {
		.banner-slant {
			.tek-overlay {
				display: block;
			}

			> div {
				&:before {
					background-position: 20% 0;
					background-size: cover;
					height: 125%;
				}
			}
		}	
	} //large

} // footer[role="contentinfo"]