/**
 * TEK | Arrow Link
 */
.arrow {
	@include flex-row( true, true );
	color: $blue;
	font-size: rem-calc(18);
	font-weight: $weight-extrabold;

	&:hover {
		color: $orange-light;

		> span:before {
			opacity: 1;
		}
	}

	> span, svg {
		position: relative;
	}

	// arrow circle
	> span {
		@include flex-row( true, true );
		justify-content: center;
		margin-right: bsu(1/2);
		width: 31px;
		height: 31px;
		border-radius: 9999px;
		overflow: hidden;
		background: linear-gradient( 155deg, $orange-light, $orange 80% );

		// hover gradient
		&:before {
			@include overlay();
			background: linear-gradient( 155deg, $blue, $blue-dark 80% );
			opacity: 0;
			@include transition-ease-in-out();
		}
	}

	svg {
		display: block;
	}
}
