/**
 * TEK | NAVIGATION
 */
header[role="banner"] {

	/**
	 * Toggle button
	 */
	button {

		&:hover {
		}

		&[aria-expanded="true"] {
		}

		@include breakpoint( large ) {
			display: none;
		}
	}		


	/**
	 * Main Navigation
	 */
	nav {
		@include transition-ease-in-out();
		display: block;
		position: fixed;
		top: -100%;
		z-index: 1;
		padding: 0 0 bsu(0.25);
		width: 100%;
		background: $white;
		overflow: hidden;
		left: 0px;

		&:before {
			@include overlay();
			height: 10px;
			top: initial;
			bottom: 0px;
			background: $orange;
			background: linear-gradient(to right, $orange-light 0%,$orange 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 );
		}
		
		// menu too tall for window
		// @see js/foot/navigation-animate.js
		&.scroll-y {
			bottom: 100%;
			overflow-y: scroll;

			&.toggled {
				bottom: 0;
			}
		}

		ul {
			@include transition-ease-in-out();
			margin: 0;
			padding: 0;
		}

		li {
			@include transition-ease-in-out();
			position: relative;
			margin: 0;

			&.sub-toggled {
				background: $gray-lighter;

				.sub-menu {
					display: block;
					padding-bottom: bsu(0.35);
				}

				.toggle-sub {
					color: $gray;
				}
			}

			&.active a {
				color: $orange-light;
			}
		}

		a {
			display: block;
			padding: bsu(1/3) bsu(1/2);
			text-decoration: none;
			color: $gray-darkest;
			border-top: 1px solid $gray-lightest;
			font-size: rem-calc(20px);
			font-weight: 600;

			&:hover{
				color: $orange-light;
			}
		}


		// triangle sub-menu toggler
		.toggle-sub {
			display: block;
			position: absolute;
			z-index: 10;
			right: bsu(1);
			top: 14px;
			padding: rem-calc(3) rem-calc(4);
			background: none;

			&:before {
				@include icon( '\f0d7' );
				color: $gray-darkest;
			}

			&:after {
				background: none;
			}
		}

		.sub-menu {
			display: none;

			li a {
				border: none;
				padding: bsu(1/5) bsu(1/3) bsu(1/5) bsu(1.5);
				font-size: rem-calc(15);
				color: $gray;

				&:before {
					@include transition-ease-in-out();
					@include overlay();
					content: '-';
					padding-top: bsu(0.15);
					padding-left: bsu(1);
				}
			}
		}



		/**
		 * Main Navigation - Desktop "menu break"
		 */
		@include breakpoint( large ) {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%; // defining SOMETHING makes this work
			overflow: visible;
			padding: bsu(1.0) 0 0 bsu(1);
			background: none;
			top: 0px!important;

			&:before {
				display: none;
			}

			ul {
				flex: 1 0 auto;
				display: flex;
				flex-flow: row nowrap;
				width: 50%;

				&#primary-left {
					justify-content: flex-end;
					padding-right: bsu(3.25);
				}

				&#primary-right {
					justify-content: flex-start;
					padding-left: bsu(3.25);
				}
			}

			li {
				display: block;
				margin: 0;
				width: auto;

				&:hover .sub-menu {
					left: -10px;
					opacity: 1;
				}
			}

			a {
				border: none;
				padding: rem-calc(20);
				color: $white;
			}

			.toggle-sub {
				display: none;
			}

			.sub-menu {
				display: block;
				position: absolute;
				top: rem-calc(75);
				left: -99999px;
				width: 200px;
				opacity: 0;
				transition: opacity ease-in-out 300ms;
				background-color: $orange-light;

				li a {
					display: block;
					padding: rem-calc(5) rem-calc(10) rem-calc(5) rem-calc(20);
					width: 100%;
					text-align: left;
					color: $gray-darkest;
					font-size: rem-calc(18px);

					&:before {
						content: '';
						background-color: $white;
						width: 6px;
						padding: 0px;
						opacity: 0;
					}

					&:hover {
						color: $white;

						&:before {
							opacity: 1;
						}
					}
				}
			} // .sub-menu
			
		} // large

		@include breakpoint( xlarge ) {
			a {
				padding: bsu(0.65) bsu(1);
			}
		} // xlarge
	} // nav

	@include breakpoint( large ) {
		&.scrolled {
			a {
				color: $gray-darkest;
			}
		}
	}
} // header[role="banner"]

// home page adjustments
body.home {
	@include breakpoint( xlarge ) {
		header[role="banner"] {
			nav {
				padding: bsu(3.4) 0 0 bsu(1); // Previous: bsu(3.4) 0 0 bsu(1);
				ul {
					&#primary-left {
						padding-right: bsu(5.25);
					}

					&#primary-right {
						padding-left: bsu(5.25);
					}
				}
			}

			&.scrolled {
				nav {
					padding: bsu(1.0) 0 0 bsu(1);

					ul {
						&#primary-left {
							padding-right: bsu(3.25);
						}

						&#primary-right {
							padding-left: bsu(3.25);
						}
					}
				}
			}
		}
	}
}