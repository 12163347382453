section#contact {
  h1 {
    text-align: center;
    padding: rem-calc(40) 0;
  }
  .form-wrapper {
    background: white;
    box-shadow: 0 0 133px rgba(0, 0, 0, 0.08);
    padding: bsu(1);

    @include breakpoint(large) {
      flex-basis: 70%;
      padding: 60px 80px 40px 80px;
    }

    .gform_wrapper {
      padding: 0;

      label {
        text-align: right;
        line-height: rem-calc(40);
      }
      input, textarea, select, .select2, .select2-container--default .select2-selection--single {
        background: linear-gradient(to left, $gray-lighter, $gray-lightest);
        outline: 0;
        border: 0;
      }
      input[type="text"], select {
        width: 100%;
        min-height: rem-calc(40);
        padding-left: rem-calc(15);

        @include breakpoint ( medium ) { width: 70%; }
      }
      input[type="submit"] {
        background: linear-gradient(to right, $orange-light, $orange);

        &:hover {
          transform: scale(1.06);
        }
      }
      .select2 {
        width: 70% !important;
        min-height: rem-calc(40);
        line-height: rem-calc(40);
        &.select2-container--default {
          .select2-selection--single {
            padding-top: rem-calc(7);
          }
          .select2-selection__arrow {
            top: rem-calc(7);
          }
        }
      }
    }
    h2 {
      font-size: 24px;
      color: $black;
      padding: 20px 10px;
      @include breakpoint(medium) {
        padding-bottom: bsu(1);
        padding-top: 0px;
      }
      span {
        font-size: 15px;
        display: block;
        font-weight: 400;
        line-height: 18px;
      }
      &::before {
        content: "\f0e6";
        display: inline-block;
        font-family: FontAwesome;
        padding-right: 20px;
        padding-bottom: 15px;
        font-size: calc(.83333vw + 19.33333px);
      }
    }
  }
}