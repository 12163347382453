/**
 * TEK | VIDEO
 */


/**
 * Center wp-video
 */

 .holiday-card .wp-video {
    margin: auto;
}