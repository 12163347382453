/**
 * TEK | TYPOGRAPHY
 */

*::selection {
	background: rgba( $black, 0.9 );
	color: $white;
	text-shadow: none;
}
*::-moz-selection {
	background: rgba( $black, 0.9 );
	color: $white;
	text-shadow: none;
}

body {
	font-size: $global-font-size;
	line-height: $global-line-height;
	color: $gray-darkest;
	font-family: $font-body;
	font-weight: $weight-regular;
	-webkit-font-smoothing: antialiased;
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	line-height: $line-height-xsmall;
}

h1, h2, h4 {
	font-weight: $weight-extrabold;
}

h1 {
	font-size: rem-calc(48);
}

h2 {
	font-size: rem-calc(36);
}


h3 {
	font-weight: $weight-bold;
	font-size: rem-calc(24);
	color: $blue;
	line-height: $line-height-medium;
}

h4 {
	color: $orange-light;
	font-size: rem-calc(18);
}


@include breakpoint( medium ) {
	
	h1 {
		font-size: rem-calc(60);
	}
}


/**
 * Paragraphs
 */
p {
	margin: bsu(1) 0 0;
	font-size: rem-calc( $global-font-size );
	color: $gray;
}


/**
 * Anchors
 */
a, a:visited {
	color: $orange-light;
	text-decoration: none;
	@include transition-ease-in-out();

	&:hover {
		color: $blue;
	}
}


/**
 * Blockquotes & Cites
 */
blockquote, q {
	quotes: "" "";

	&:before,
	&:after {
		content: "";
	}
	color: $gray;
}


/**
 * Horizontal Rule
 */
hr {
	margin: bsu(2) 0;
	border: none;
}


/**
 * Address
 */
address {
	font-style: normal;
}


/**
 * Lists
 */
ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;

	&.bulleted {
		@include bulleted-list();
	}
}


/**
 * WYSIWYG Content Output
 */
.entry-content {
	@include wysiwyg();
}


/**
 * Font Awesome
 */
.fa {
	font-weight: 400;
	@include kill-font-smoothing();
}


/**
* Increase font size when needed. 
*/
.bigger {
	font-size: larger;
}