/**
 * TEK | MAIN STRUCTURE
 */

html, body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	background: $white;
	overflow-x: hidden;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}


/**
 * Wrapper
 */
.wrap {
	position: relative;
	margin: 0 auto;
	width: 90%;
	max-width: 1140px;
}


/**
 * Main container
 */
#content {
	position: relative;
	// overflow-x: hidden;
	z-index: 2;

	@include breakpoint( xlarge ) {
		body.home & {
			main {
				&:before {
					@include overlay();
					opacity: 0.1;
					background-image: url('../images/logo-e.svg');
					background-repeat: no-repeat;
					background-size: 100%;
					background-position: center 700px;
					transform: rotate(-4deg);
				}
			}
		}
	}
}


/**
 * Main content container
 */
[role="main"] {
	display: block;

	&.default .wrap {
		max-width: 945px;
	}

	> article {
		width: 100%;
	}
}


/**
 * Figure & img fixes
 */
figure {
	margin: 0;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}