/**
 * TEK | HOME SERVICES VIRTUAL EVENTS CTA
 */


    .virtual-events-cta {
      position: relative;
      background: #fff;
      box-shadow: 22px 22px 52px 0 rgba(0,0,0,0.1);
      padding: 1rem;
      display: block;
      margin-bottom: 4rem;
      
      // bottom: bsu(5);
      @include breakpoint(large) {
        width: 60%;
        right: bsu(1.5);
        padding: bsu(1.5);
        margin-bottom: initial;

      }
     
      
      h1 {
        text-align: center;
      }
      h3 {
        text-align: center;
      }
      p {
        font-size: 1.2rem;
        color: #0f0f0f;
      }
      ul {
        list-style-type: initial;
        font-size: 1.3rem;
        padding: 0 1rem 0 4rem;
      }
      .vid-thumb-containter{
        width: 70%;
        margin: auto;
        // margin-bottom: 4rem;
      }
      
    
      

      .vid-thumb {
        position: relative;
        // top: 50%;
        // transform: translateX(-50%) translateY(-50%);
        // line-height: 0;
        // width: rem-calc(190);
        cursor: pointer;
        margin: auto;

        &:before,
        &:after {
          // @include overlay();
        }
        &:before {
          background-color: rgba($green-darker, 0.4);
          background-clip: content-box;
          z-index: 1;
          top: 0%;
          // padding: rem-calc(100);
        }
        // &:after {
        //   @include icon('\f144');
        //   z-index: 2;
        //   font-size: bsu(1.57);
        //   color: $white;
        //   // margin: auto;
        //   position: absolute;
        //   // width: auto;
        //   // height: auto;
        //   // padding: rem-calc(100);
        //   box-sizing: padding-box;
        //   left: 40%;
        //   top: 17%;
        //   // top: 50%;
        //   // margin-left: rem-calc(-24);

        //   @include breakpoint(large) {
        //     font-size: bsu(4.95);
        //     top: initial;
        //   }

        // }
      }
      .virtual-events-cta-video {

        .vid-modal {
          display: none;
        }
      }
      & .rem-1{
        padding: 1rem 0 1rem 0;
      }
      & .virtual-form-btn {
        top: initial;
      }

    }
    