.holiday-card {
    margin: auto;  
    background-image: url("/wp-content/uploads/2021/12/Snowflake-Background.jpg");
    background-repeat: no-repeat;
    background-size: 110%;
}


.holiday-video-container{
  width: auto;
  position: relative;
  transform: translate(-50%);
  left: 50%;



  @include breakpoint( medium ) {
		width: 640px;
	}

}

.ribbon {
  display: none;
  width: 300px;
  z-index: 2;
  position: absolute;
  right:-92px;
  top: -79px;

  @include breakpoint( medium ) {
		display: initial;
	}
}

.gform_confirmation_wrapper .gquiz-field-label, .gform_wrapper .gquiz-correct-choice {
color: #339900!important;
}

input#gform_submit_button_6 {
  background-color: #365e83;

  &:hover {
    background-color: #ff751a;
  }
}
.gform_heading {
  font-family: Birthstone;
  color: #01478b;
}
.gform_wrapper.gravity-theme .gfield_required {
  display: none;
  }
  .gfield_required{
    display: none!important;
  }

  
  #gform_6_validation_container {
  display: none;
  }

  #validation_message_6_1 {
  display: none;
  }
  #validation_message_6_2 {
  display: none;
  }
  #validation_message_6_3 {
  display: none;
  }
  #validation_message_6_4 {
  display: none;
  }
  #validation_message_6_5 {
  display: none;
  }
  #validation_message_6_6 {
  display: none;
  }
  #validation_message_6_7 {
  display: none;
  }
  #validation_message_6_8 {
    display: none;
    }
  #validation_message_6_9 {
    display: none;
    }
  #validation_message_6_10 {
    display: none;
    }

  // Gravity Forms

.gform_wrapper, #gform_confirmation_message_6{
    width: 95%;
    margin: auto;
  }

.gform_heading {
  text-align: center;
}
