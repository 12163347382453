/**
 * TEK | SERVICES
 */

 .subpages-menu-mobile-toggler {
  @include transition-ease-in-out();
  border: $black solid 3px;
  font-size: 1.6rem;
  width: 250px;
  margin: 2rem auto 1rem;

  &:hover {
    color: $orange;
    border-color: $orange;
  }

  @media screen and (min-width : 768px) { display: none; }
 }

.subpages-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;

  .page_item {

    flex: 1;
    width: 100%;
    max-width: 250px;
    padding: bsu(0.5) bsu(0.25);

    &.page-item-31 .angled {
      /*
      background: linear-gradient( 115deg, $green-dark 0%, $green-darker 100% );
      @TODO: add light white gradient to button after pseudo element
      */

      background: $green-darker;
      color: $green-darker;
    }
    &.page-item-37 .angled {
      background: $orange-red;
      color: $orange-red;
    }
    &.page-item-39 .angled {
      background: $green;
      color: $green;
    }
    &.page-item-35 .angled {
      background: $purple;
      color: $purple;
    }
    &.current_page_item .angled {
      color: $white;
    }
    &.current_page_item .angled:before, &.current_page_item .angled:after {
      background: none;
      background-color: transparent;
    }

    .angled { /*button*/

      width: 100%;
      color: $black;
      text-transform: none;
      font-weight: $weight-bold;
      font-size: rem-calc($global-font-size + 3px);

      &:before, &:after {
        background: #fff;
        margin: rem-calc(2);
        width: auto;
        height: auto;
        //-webkit-clip-path: polygon(0 10px, 66px 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 66px) 100%, 0 calc(100% - 10px));
        //clip-path: polygon(0 10px, 66px 0, 100% 10px, 100% calc(100% - 10px), calc(100% - 66px) 100%, 0 calc(100% - 10px));

        -webkit-clip-path: polygon(1px 11px, 66px 1px, calc(100% - 1px) 11px, calc(100% - 1px) calc(100% - 11px), calc(100% - 66px) calc(100% - 1px), 1px calc(100% - 11px)) !important;
        clip-path: polygon(1px 11px, 66px 1px, calc(100% - 1px) 11px, calc(100% - 1px) calc(100% - 11px), calc(100% - 66px) calc(100% - 1px), 1px calc(100% - 11px)) !important;
      }

      &:hover {
        color: $white;
      }

      &:hover:before, &:hover:after {
        background: none;
        background-color: transparent;
      }

      background: #000;
      span {
        display: inline-block;
        margin: 0 rem-calc(10) 0 0;
        width: rem-calc(20);
        height: rem-calc(20);
        position: relative;
        top: rem-calc(4);
        background-size: contain !important;
        background-repeat: no-repeat !important;

        svg {
          width: rem-calc(20);
          height: rem-calc(20);
          fill: currentColor;
        }
      }
    }
  }

  @include breakpoint(zero) {
    // Additional mobile styles:
    @include transition-ease-in-out();
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: 0px;
    // End additional mobile styles.

    > .page_item {
      width: 250px;
    }
  }

  @media screen and (min-width : 768px) {
    max-width: 100%;
    // Undo additional mobile style:
    height: auto;
    flex-direction: row;
    // End undone additional mobile styles.

    > .page_item {
      display: list-item;
      padding: bsu(1) bsu(0.1);

      a {
        white-space: nowrap;
      }
    }
  }
  @include breakpoint(large) {
    max-width: 85%;

    > .page_item {
      display: list-item;
      padding: bsu(1) bsu(0.25);
    }
  }
}

.services-container > h1 {
  text-align: center;
  padding-bottom: rem-calc(40);
}

#services-intro {
  text-align: center;
  margin-bottom: 4rem;

  @include breakpoint ( medium ) { margin-bottom: 1rem; }
}

#services-description {
  position: relative;
  z-index: 2;

  .service-logo-container {
    text-align: center;

    img {
      width: 100%;
      max-width: 280px;
    }
  }

  .description_container {

    @include flex-row();

    .col {
      padding: rem-calc(15);
      flex: 1;

      > div {
        text-align: center;

        @include breakpoint ( medium ) { text-align: left; }
      }

      h3 {
        padding-top: rem-calc(30);
      }
    }
    .col:nth-child(1) {
      img {
        max-width: 80%;
      }
    }
    .col:nth-child(2) {
      margin-left: rem-calc(50);
    }

    .description-image {
      position: relative;

      .vid-thumb {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 0;
        padding: rem-calc(100);
        cursor: pointer;

        &:before,
        &:after {
          @include overlay();
        }
        &:before {
          background-color: rgba($green-darker, 0.4);
          background-clip: content-box;
          z-index: 1;
          top: 0%;
          padding: rem-calc(100);
        }
        &:after {
          @include icon('\f04b');
          z-index: 2;
          font-size: rem-calc(48);
          color: $white;
          margin: auto;
          width: auto;
          height: auto;
          padding: rem-calc(100);
          box-sizing: padding-box;
          left: 25%;
          top: 25%;
          margin-left: rem-calc(-24);
        }
      }

      &:before {
        @include overlay();
        background-image: url("../images/general-image-overlay-faceting-effect.png");
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100%;
      }
      > div.tooltip-container {

        .tooltip-trigger {
          top: 0px;
          left: 0px;
          width: 500px;
          z-index: 0;
          -webkit-backface-visibility: hidden;

          &:hover { z-index: 3; }
          
          .tooltip-circle {
            position: absolute;
            top: -15px;
            left: -15px;
          }

          section.tips-popup {
            @include transition-ease-in-out();
            background-color: $white;
            box-shadow: 22px 22px 52px 0 rgba(0,0,0,.1);
            padding: 2rem 3rem;
            z-index: 1;

            img {
              width: 40%;
              margin-bottom: 1rem;
            }

            h4 {
              color: $black;
              margin-bottom: 1rem;
            }

            p {
              margin-bottom: 1rem;
            }

            a.arrow {
              @include transition-ease-in-out();
              color: $blue;

              span {
                background-image: url(../images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
                background-repeat: no-repeat;
                background-position: 50%;
                border-radius: 50%;
              }

              &:hover {
                color: $orange;
              }
            }

          }
        }

        .tooltip-trigger.closed > section.tips-popup { 
          opacity: 0;
        }
        .tooltip-trigger.open   > section.tips-popup { 
          opacity: 1;
        }
      }
    }
    .description-video {

      .vid-modal {
        display: none;
      }
    }

    @include breakpoint(zero) {
      display: block;
      div.col:nth-child(2) {
        margin-left: 0;
        margin-top: 3rem;
      }
      .description-image {
        max-width: 50%;
        margin: auto;
        > img, &:before {
          display: none;
        }
        .vid-thumb {
          position: static;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
          padding: 0;

          &:before, &:after {
            padding: 0;

          }
          &:after {
            left: 50%;
            top: 50%;
          }
        }
      }

    }

    @include breakpoint(large) {
      display: flex;

      div.col:nth-child(2) {
        margin-left: rem-calc(50);
      }
      .description-image {
        max-width: inherit;
        > img, &:before {
          display: block;
        }
        .vid-thumb {
          position: absolute;
          top: 50%;
          transform: translateX(-45%) translateY(-50%);
          line-height: 0;
          padding: rem-calc(100);

          &:before,
          &:after {
            @include overlay();
          }
          &:before {
            background-color: rgba($green-darker, 0.4);
            background-clip: content-box;
            z-index: 1;
            top: 0%;
            padding: rem-calc(100);
          }
          &:after {
            @include icon('\f04b');
            z-index: 2;
            font-size: rem-calc(48);
            color: $white;
            margin: auto;
            width: auto;
            height: auto;
            padding: rem-calc(100);
            box-sizing: padding-box;
            left: 25%;
            top: 25%;
            margin-left: rem-calc(-24);
          }
        }
        

      }

    }

  }
}

#services-overview {
  position: relative;

  .services-container {

    .slant-container {
      z-index: 1;
      top: -300px;
      margin-bottom: -200px;

      @include breakpoint(zero) {
        top: -50px;
        margin-bottom: rem-calc(100);
      }
      @include breakpoint(large) {
        top: -300px;
        margin-bottom: -200px;
      }

      .accent-wrapper {
        @include flex-row();
        position: absolute;
        z-index: 3;
        top: 250px;
        width: 100%;
        max-width: 975px;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(zero) {
          display: none;
        }
        @include breakpoint(large) {
          display: flex;
        }

        > div {
          flex: 1;
          position: relative;
          @include transition-ease-in-out();

          &:last-child {
            pointer-events: none;
          }

          .color-accent {
            position: absolute;
            z-index: -1;
            bottom: bsu(-6);
            left: bsu(-1);
            width: 70%;
            height: 70%;
            background: linear-gradient(to right, $orange-light, $orange);
            -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
            clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
            overflow: hidden;
            @include transition-ease-in-out();

            &:before {
              @include overlay();
              background-image: url('../images/accent-triangle-overlay.png');
              background-repeat: no-repeat;
              background-position: 240% 180%;
            }
          }

          &:hover {
            transform: translate(-1%, 1%);

            .color-accent {
              bottom: bsu(-6.2);
              left: bsu(-1.2);
            }
          }
        }
        // Tool Tip Pop-up
        > div.tooltip-container {

          .tooltip-trigger {
            top: 0px;
            left: 0px;
            width: 500px;
            z-index: 0;
            -webkit-backface-visibility: hidden;

            &:hover { z-index: 3; }
            
            .tooltip-circle {
              position: absolute;
              top: -15px;
              left: -15px;
            }

            section.tips-popup {
              @include transition-ease-in-out();
              background-color: $white;
              box-shadow: 22px 22px 52px 0 rgba(0,0,0,.1);
              padding: 2rem 3rem;
              z-index: 1;

              img {
                width: 40%;
                margin-bottom: 1rem;
              }

              h4 {
                color: $black;
                margin-bottom: 1rem;
              }

              p {
                margin-bottom: 1rem;
              }

              a.arrow {
                @include transition-ease-in-out();
                color: $blue;

                span {
                  background-image: url(../images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
                  background-repeat: no-repeat;
                  background-position: 50%;
                  border-radius: 50%;
                }

                &:hover {
                  color: $orange;
                }
              }

            }
          }

          .tooltip-trigger.closed > section.tips-popup { 
            opacity: 0;
          }
          .tooltip-trigger.open   > section.tips-popup { 
            opacity: 1;
          }
        }

      }

      .slant {
        .tek-overlay {
          display: none;
          opacity: 0.5;
          top: 25%;
          left: 50%;
          width: 2142px;
          height: 800px;
        }

        > div {


          &:before {
            @include overlay();
            background-image: url('../images/overview-facet-overlay@2x.png');
            background-repeat: no-repeat;
            background-position: 20% 50%;
            height: 110%;
            background-blend-mode: multiply;
            mix-blend-mode: multiply;
            z-index: 1 !important;
          }
        }
        // background image
        > div > img {
          @include cover-image();
          position: absolute;
          z-index: 0; // Negative values cause cross-browser issues
          top: -25%;
          left: 0px;
          height: 150%;
        }
        .wrap {

          color: $white;
          min-height: rem-calc(400);
          @include flex-row();
          z-index: 2;

          .col {
            padding: 0 rem-calc(5);
            flex: 1;

          }
          .col:nth-child(2) {
            margin-left: rem-calc(100);
          }
          h1 {
            padding-bottom: rem-calc(20);
          }

          @include breakpoint(zero) {
            flex-flow: row wrap;
            h1 {
              text-align: center;
            }
            .col {
              flex: 1 100%;
            }
            .col:nth-child(2) {
              margin-left: rem-calc(0);
            }
          }
          @include breakpoint(large) {
            flex-flow: row nowrap;
            h1 {
              text-align: left;
            }
            .col {

            }
            .col:nth-child(2) {
              margin-left: rem-calc(100);
            }
          }

          .overview-list {
            @include flex-row();
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: rem-calc(100);
            padding-left: bsu(1);

            @include bulleted-list();
            li {
              flex: 1 0 auto;
              line-height: 1.75em;
              padding-right: rem-calc(2);
              padding-bottom: rem-calc(8);
            }

            @include breakpoint(zero) {
              li {
                flex: 1 100%;
                margin-left: rem-calc(10);
              }
            }
            @include breakpoint(medium) {
              li {
                flex: 1 50%;
                max-width: 50%;
                margin-left: 0;
              }
            }
            @include breakpoint(medium) {
              padding-left: 0;
            }
          }
        }

        @include breakpoint(zero) {
          > div {
            padding: bsu(5) 0 bsu(1);
          }
        }

        @include breakpoint(medium) {
          > div {
            padding: bsu(4) 0 bsu(1);
          }
        }
        //medium

        @include breakpoint(large) {
          > div {
            padding: bsu(6) 0 bsu(2);
          }
        }
        //large

        @include breakpoint(xlarge) {

          > div {
            padding: bsu(8) 0 bsu(3);
          }
        }
        //xlarge

        @include breakpoint(mega) {
          // background image
          > div > img {
            top: 0;
          }

          > div {
            padding: bsu(10) 0 bsu(5);
          }
        }
        //mega
      }

      @include breakpoint(xlarge) {
        .slant {
          .tek-overlay {
            display: block;
          }

          > div {
            &:before {
              @include overlay();
              background-position: 0% 20%;
              background-size: cover;
              height: 125%;
            }
          }
        }
      }
      //large
    }

  }
  .overview-gallery {
    position: relative;
    z-index: 2;
  }

  .slider-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: rem-calc(-44);
    left: 0px;
    z-index: 2;

    .slick-arrow {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;

      &:after {
        display: none;
      }

      &.slick-prev {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }
  .slider-arrows-P {
    position: absolute;
    top: 50%;
    margin-top: rem-calc(-44);
    left: 0px;
    z-index: 2;

    .slick-arrow-P {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;


      &:after {
        display: none;
      }

      &.slick-prev-P {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev-P {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }
  .slider-arrows-N {
    position: absolute;
    top: 50%;
    margin-top: rem-calc(-44);
    right: 0px;
    z-index: 2;
    

    .slick-arrow-N {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;

      

      &:after {
        display: none;
      }

      &.slick-prev {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }



  .services-slider {
    .gallery-wrapper {
      padding: bsu(0.25);
    }
    .slide-bg {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: bsu(5);

      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 0;
        content: "";
      }

      h2, p {
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36);
      }

      h2 {
        display: none;
        color: $white;
      }

      p {
        color: $white;
        font-weight: 700;
        font-size: rem-calc(14px);
        margin-top: bsu(0);

        span {
          color: $orange-light;
          font-size: rem-calc(24px);
          padding-right: bsu(0.25);
        }
      }
    }
  }
}

.slick-lightbox {

  } 

.vid-modal video {
  width: 95vw;
  height: auto;

  @include breakpoint( medium ){
    width: 75vw;
    height: auto;
  }
  @include breakpoint( xlarge ){
    width: 50vw;
    height: auto;
  }
}

.slick-lightbox .slick-prev.slick-arrow {

    position: absolute;
    top: 40%;
    margin-top: 2.9rem;
    left: 0;
    z-index: 1;
    background-color: #ff731a;
    text-indent: -999px;
    background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
    background-position: 50%;
    background-repeat: no-repeat;
    transform: rotate(-180deg);
    display: none !important;

 @include breakpoint( large ){
    display: inherit !important;
  }


}

.slick-lightbox .slick-prev.slick-arrow::after {

    background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
    background-repeat: no-repeat;
    background-position: 25%;
    width: 0;
    height: 100%;
    display: none !important;

 @include breakpoint( large ){
    display: inherit !important;
  }

}

.slick-lightbox .slick-next.slick-arrow {

    position: absolute;
    top: 40%;
    margin-top: 2.9rem;
    right: 0;
    z-index: 1;
    background-color: #ff731a;
    text-indent: -999px;
    background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
    background-position: 50%;
    background-repeat: no-repeat;
    display: none !important;

 @include breakpoint( large ){
    display: inherit !important;
  }

}

.slick-lightbox .slick-next.slick-arrow::after {

    background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
    background-repeat: no-repeat;
    background-position: 25%;
    width: 0;
    height: 100%;
    display: none !important;

 @include breakpoint( large ){
    display: inherit !important;
  }

}

.slick-lightbox .slick-lightbox-close {
  display: none !important;
}
 @include breakpoint( large ){
    display: inherit !important;
  }

  .slick-lightbox .slick-lightbox-close::after {
  display: none !important;
}
 @include breakpoint( large ){
    display: inherit !important;
  }