#congratulations-intro {
    text-align: center;
    padding: rem-calc(40) 0;
  
  
}


#congratulations{

  position: relative;
  z-index: 2;
  padding-bottom: 5%;


  .congratulations-container {

    @include flex-row();
    flex-flow: row wrap;
    text-align: center;
    
    .col {
      position: relative;
      padding: rem-calc(15);
      flex: 1 25%;
      min-width: 300px;
      box-shadow: 5px 5px 48px 0 rgba(0,0,0,.1);
      margin: 20px;
      
      
      .thumbnail {
        position: relative;
        -webkit-background-size: cover;
        background-size: cover;
        @include breakpoint( zero ) {
          max-width: 100%;
          height: auto;
        }
      }
      .info {
        > p { margin: initial;}
      }
    }
    

  }
}

