/**
 * TEK | TOOLTIPS
 */

.tooltip-container {
	position: relative;
	display: none;

	@include breakpoint( large ) {
		display: block;
	}
}

.tooltip-trigger {
	position: absolute;
	width: 400px;

	.tooltip-circle {
		@include transition-ease-in-out();
		width: 30px;
		height: 30px;
		background: transparent;
		border: 10px solid $orange-light;
		border-radius: 50%;
		padding: 0;
		text-indent: -9999px;
		z-index: 1;

		&:after {
			display: none;
		}
	}

	.text-container {
		@include transition-ease-in-out();
		opacity: 0;
		position: relative;
		left: 15px;
		padding: bsu(1);
		background: $white;
		box-shadow: 22px 22px 52px 0px rgba(0,0,0,0.1);
		margin-top: -25px;
	}

	p {
		margin: 0;
	}

	&.closed {
		.tooltip-circle {
			animation: pulse 2.5s infinite;
		}
	}

	&.open {
		.tooltip-circle {
			background: transparent;
			border: 6px solid $orange-light;
			transform: scale(2);
		}

		.text-container {
			opacity: 1;
		}
	}
}

@keyframes pulse {
  0% {
    
  }
  30% {
    transform: scale(1);
    // background: $orange-light;
    border: 10px solid $orange-light;
  }
  50% {
    transform: scale(1.5);
    // background: $orange;
    border: 10px solid $orange;
  }
  70% {
    transform: scale(1);
    // background: $orange-light;
    border: 10px solid $orange-light;
  }
  100% {
    
  }
}