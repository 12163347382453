/**
 * TEK | RESPONSIVE EMBED
 */

.embed-container { 
	position: relative; 
	padding-bottom: 56.25%;
	width: 100%;
	max-width: 100%;
	height: auto;
	max-height: 440px;
	overflow: hidden;

	iframe, object, embed, video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}