/**
 * TEK | HOME SERVICES
 */

#tek-tips-front {
	position: relative;
	z-index: 1;

	.tek-tips-logo {
		margin-bottom: bsu(0.5);
	}

	header {
		margin-bottom: bsu(2);

		> div {
			margin-bottom: bsu(1);
		}
	}

	@include breakpoint( large ) {

		header {
			width: 50%;
			padding-right: bsu(2);
		}

		.tooltip-container {
			position: absolute;
			top: -180px;
			right: -150px;

			&:before {
				@include overlay();
				background-image: url('../images/shape-purple-diamond.png');
				background-repeat: no-repeat;
				background-size: cover;
				width: 600px;
				height: 600px;
				z-index: -1;
				top: -100px;
				left: 300px;
			}
		}
	} //large
}