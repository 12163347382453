/**
 * TEK | About
 */

#about-intro {

  position: relative;
  z-index: 2;

  .accent-wrapper {
    @include flex-row();
    position: absolute;
    z-index: 3;
    top: 0px;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(zero) {
      display: none;
      > div {
        display: block;
      }
    }
    @include breakpoint(large) {
      display: flex;
    }

    > div {
      flex: 1;
      position: relative;
      &.intro-image {
        position: relative;
        @include transition-ease-in-out();

        .color-accent {
          position: absolute;
          z-index: -1;
          bottom: bsu(-6);
          left: bsu(-1);
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, $orange-light, $orange);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          @include transition-ease-in-out();

          &:before {
            @include overlay();
            background-image: url('../images/accent-triangle-overlay.png');
            background-repeat: no-repeat;
            background-position: 240% 180%;
          }
        }

        &:before {
          @include overlay();
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%;
        }

        &:hover {
          -moz-transform: translate(-1%, 1%);
          -webkit-transform: translate(-1%, 1%);
          transform: translate(-1%, 1%);

          .color-accent {
            bottom: bsu(-6.2);
            left: bsu(-1.2);
          }
        }
      }
    }
    .intro-video {

      .vid-modal {
        display: none;
      }
    }
  }

  .intro_container {

    @include flex-row();

    .col {
      padding: rem-calc(15);
      flex: 1;

      h1 {
        font-size: 12vw;

        @include breakpoint( small ) { font-size: 4rem; }
      }

      h3 {
        padding-top: rem-calc(30);
      }
    }
    .col:nth-child(2) {
    }

    @include breakpoint(zero) {
      display: block;
      > div {
        display: block;
      }
      .col:nth-child(2) {
        margin-left: rem-calc(0);
      }
    }
    @include breakpoint(large) {
      display: flex;

      .col:nth-child(2) {
        margin-left: rem-calc(50);
      }
    }
  }

}

#why {
  position: relative;
  h3 {
    padding: rem-calc(30) 0 rem-calc(60) 0;
    text-align: center;
  }
  .why_container {

    @include flex-row();

    .col {
      position: relative;
      padding: rem-calc(15);
      flex: 1;
      margin: 0 rem-calc(10);
      background: $white;
      -webkit-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      -moz-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);

      @include transition-ease-in-out();
      top: 0;

      &:hover {
        top: -50px;
      }

      h3 {
        padding: rem-calc(10) 0;
        text-align: center;

      }
      p {
        padding-top: 0;
        margin-top: 0;
        text-align: center;
      }
    }
    .col:nth-child(2) {

    }
    @include breakpoint( zero ) {
      display: block;
      .col {
        margin-bottom: rem-calc(20);
      }
    }
    @include breakpoint( medium ) {
      display: flex;

      height: 100%;
      .col {
        margin-bottom: 0;
        height: 100%;
      }
    }
  }

}

#team {
  position: relative;
  z-index: 2;
  padding-bottom: 4rem;
  h1 {
    padding: rem-calc(40);
    text-align: center;
  }
  .team_container {

    @include flex-row();
    flex-flow: row wrap;

    .col {
      position: relative;
      flex: 1 25%;
      min-width: 215px;

      .active-bio {
        .bio {
          opacity: 1 !important;
          top: 140% !important;
          z-index: 1 !important;
        }

        img:first-child {
          opacity: 0;
        }
        img:nth-child(2) {
          opacity: 1 !important;
        }
      }

      .thumbnail {
        position: relative;
        background: url("../images/avatar_bg@2x.png") no-repeat 50% 50%;
        -webkit-background-size: cover;
        background-size: cover;

        @include breakpoint( zero ) {
          max-width: 100%;
          height: auto;
          padding: rem-calc(90);
          margin: rem-calc(10);
        }

        .bio {
          transition: .4s ease-in-out;
          background-color: $white;
          position: absolute;
          width: 100%;
          padding: 1rem;
          left: 0;
          top: 100%;
          z-index: -1;
          border-top: 4px solid $blue;
          box-shadow: 0 0 45px 9px hsla(0, 0%, 44%, 0.1);
          opacity: 0;

          > p { font-size: 0.9rem; text-align: left; }

          > p:nth-child(odd) { font-weight: bold; color: $black; }
        }

        img:nth-child(2) {
          opacity: 0;
        }

        @include breakpoint ( large ) {
          &:hover {
            .bio {
              opacity: 1;
              top: 140%;
              z-index: 1;
            }

            img:first-child {
              opacity: 0;
            }
            img:nth-child(2) {
              opacity: 1;
            }
          }
        }

      }
      .thumbnail img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%) rotate(0);
        -ms-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
        mix-blend-mode: multiply;
        padding-top: 1rem;

        -webkit-backface-visibility: hidden;
      }

      h3 {
        padding: rem-calc(10) 0 0 0;
        text-align: center;
        font-size: rem-calc(15);
        font-weight: $weight-extrabold;

      }
      p {
        padding-top: 0;
        margin-top: 0;
        text-align: center;
      }
    }
    .col:nth-child(2) {

    }

  }
}

#corporate {

  position: relative;
  z-index: 1;
  padding-top: rem-calc(200);
  margin-bottom: rem-calc(-300);

  @include breakpoint ( large ) {
    margin-bottom: 0;    
  }

  h1 {
    text-align: center;
    margin-bottom: rem-calc(40);
    padding: 1.5rem;
    font-size: 10vw;

    @include breakpoint( medium ) {
      font-size: 4rem;
    }
  }
  .accent-wrapper {
    @include flex-row();
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translate(-54%, 0%);

    @include breakpoint( zero ) {
      display: none;
    }
    @include breakpoint( large ) {
      display: flex;
    }

    > div {
      flex: 1;
      position: relative;
      &.secondary-image {
        position: relative;

        .color-accent {
          position: absolute;
          z-index: -1;
          bottom: bsu(-1);
          right: bsu(-1);
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, $orange-light, $orange);
          -webkit-clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
          clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
          overflow: hidden;

          &:before {
            @include overlay();
            background-image: url('../images/accent-triangle-overlay.png');
            background-repeat: no-repeat;
            background-position: 240% 180%;
          }
        }

        &:before {
          @include overlay();
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%;
        }
        .thumb {
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          line-height: 0;
          padding: rem-calc(100);

          &:before,
          &:after {
            @include overlay();
          }

        }
      }
    }
    .intro-video {

      .vid-modal {
        display: none;
      }
    }
  }
  .slant-container {

    top: -250px;
    margin-bottom: 0;

    &:nth-child(2) {
      top: 0;
      margin-bottom: -100px;

      @include breakpoint( large ) {

      }
    }

    .dual-slant-overlay.reverse {
      top: -25%;
    }

    .slant {
      width: 150%;
      left: -25%;
      background: $orange-light;



      > div {

        padding: bsu(2) 0 bsu(6);
        &:before {
          @include overlay();
          background-repeat: no-repeat;
          background-position: 0% 50%;
          height: 110%;
          mix-blend-mode: multiply;
          @include breakpoint( xlarge ) {
            background-image: url('../images/resources-facet-overlay@2x.png');
          }
        }
      }
      // background image
      > div > img {
        @include cover-image();
        position: absolute;
        z-index: -2;
        top: -25%;
        left: 0px;
        height: 150%;
      }
      .wrap {

        color: $white;
        .corporate_container {
          width: 90%;
          margin: 0 auto;
          @include flex-row();
          
          @include breakpoint( large ) {
            width: auto;
            margin: 0;
          }

          .col {
            padding: rem-calc(60);
            flex: 2;

            @include breakpoint( small ) {
              padding: rem-calc(90);
            }
            @include breakpoint( medium ) {
              padding: rem-calc(10);
            }
            @include breakpoint( large ) {
              padding: rem-calc(5);
            }

            h4 {
              color: $black !important;
            }

            .box {
              position: relative;
              padding: rem-calc(40);
              background: $white;
              border-radius: rem-calc(5);
              margin-bottom: rem-calc(20);

              p {
                @include breakpoint ( large ) {
                  max-height: 150px;
                  overflow: hidden; //scroll;
                }
              }
            }
          }
          .col:nth-child(2) {
            margin-left: rem-calc(20);
            flex: 3;
            margin-bottom: rem-calc(20);
            position: relative;

            div.primary_image {
              position: absolute; // relative
              border-radius: rem-calc(5);
              height: 98%; // 100%
              width: 98%;
              overflow: hidden;

              img {
                position: absolute;
                left: 50%;
                top: 50%;
                height: 100%;
                width: auto !important;
                max-width: none;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }
            }
          }

        }
      }
      @include breakpoint(zero) {
        width: 180%;
        left: -40%;

        > div {
          padding: bsu(2) bsu(0.5) bsu(1);
        }
        .wrap {
          display: block;

          .col {
            width: 100%;
          }
          .col:nth-child(2) {
            display: none;
          }
        }

      }
      @include breakpoint(medium) {
        > div {
          padding: bsu(2) 0 bsu(1);
        }
        padding: rem-calc(0) rem-calc(150) rem-calc(100);
        width: 150%;
        left: -25%;
      }
      @include breakpoint(large) {
        > div {
          padding: bsu(2) 0 bsu(2);
        }
        padding: rem-calc(0) rem-calc(150) rem-calc(250);
        width: 150%;
        left: -25%;
        .wrap {
          .col:nth-child(2) {
            display: block;
          }
        }
      }
      @include breakpoint(xlarge) {
        padding: rem-calc(50) rem-calc(0) rem-calc(250);
        > div {
          padding: bsu(2) 0 bsu(3);
        }
      }


      @include breakpoint(mega) {
        // background image
        > div > img {
          top: 0;
        }

        > div {
          padding: bsu(4) 0 bsu(5);
        }
      }
      //mega
    }

    @include breakpoint(xlarge) {
      .slant {
        .tek-overlay {
          display: block;
        }

        > div {
          &:before {
            @include overlay();
            background-position: 0% 20%;
            background-size: cover;
            height: 200%;
          }
        }
      }
    }
    //large
  }

}