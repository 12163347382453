/**
 * TEK | HOME SERVICES
 */

#services-front {
	position: relative;
	//z-index: 2;

	.intro {
		text-align: center;
		margin-bottom: bsu(2);
	}

	.service-container {
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;
	}
	// .service-container-inner {
	// 	top: -180px;
	// 	position: relative;
	// }

	.service-images {
		display: none;
	}

	.service-text {

		.service-listings {
			svg {
				width: rem-calc(50);
				height: rem-calc(50);
				fill: currentColor;
				margin: 0 auto;
				display: block;

				@include breakpoint( medium ) {
					width: rem-calc(80);
					height: rem-calc(80);
				}

				@include breakpoint( large ) {
					width: rem-calc(50);
					height: rem-calc(50);
				}

				@include breakpoint( xlarge ) {
					width: rem-calc(75);
					height: rem-calc(75);
				}
				
				.cls-3 {
					fill: currentColor;
				}
			}
			li {
				@include transition-ease-in-out();
				position: relative;
				margin-bottom: bsu(1.5);
			}

			a {
				display: flex;
				align-items: center;
			}

			div:nth-child(1) {
				width: 25%;
				padding: 0  bsu(0.75) 0 0;
				-moz-transform: translateY(10%) scale(1.3);
				-webkit-transform: translateY(10%) scale(1.3);
				transform: translateY(10%) scale(1.3);
			}

			div:nth-child(2) {
				width: 75%;
			}

			h2 {
				font-size: rem-calc(18px);
				color: $gray-darkest;
			}

			p {
				margin-top: bsu(0.25);
				@include fp( font-size, 14, 15 );
			}

			.button {
				@include transition-ease-in-out();
				text-decoration: underline;
			}
		}
	}

	@include breakpoint( large ) {

		// top: -180px;
    	margin-bottom: -200px;

		.service-container > div {
			display: flex;
			// align-items: center;
			width: 100%;
			top: -180px;
			position: relative;
			flex-wrap: wrap;
		}
		// .service-container-inner {
		// 	top: -180px;
		// 	position: relative;
		// }

		.intro {
			text-align: left;
		}

		.service-images {
			position: relative;
			display: block;
			width: 60%;
			height: 434px;

			> div {
				@include transition-ease-in-out();
				position: absolute;
				width: 440px;
				height: 434px;
				opacity: 0;
				right: bsu(0);

				&.active {
					opacity: 1;
				}
			}

			.service-image-main,
			.service-image-secondary {
				background-repeat: no-repeat;
				background-size: cover;
			}

			.service-image-main {
				position: absolute;
				z-index: 0;
				top: -120px;
				left: -300px;
				width: 600px;
				height: 600px;
			}

			.service-image-secondary {
				position: relative;
				z-index: 1;
				width: 75%;
				height: 75%;
				margin-left: 25%;
			}
		}

		.service-text {
			width: 40%;
			display: none; // need to remove when going live

			.service-listings {
				li {
					margin-bottom: bsu(1.5);
				}

				a {
					position: relative;
					padding: bsu(0.65) bsu(1);
				}

			}
		}
	} //large

	@include breakpoint( xlarge ) {
		.service-images {
			> div {
				right: bsu(3);
			}
			.service-image-main {
				position: absolute;
				z-index: 0;
				top: -80px;
				left: -300px;
				width: 600px;
				height: 600px;
			}

			.service-image-secondary {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;
				margin-left: 0;
			}

			.color-accent {
				@include transition-ease-in-out();
				position: absolute;
				top: bsu(5.5);
				left: bsu(-4);
				width: 480px;
				height: 400px;
				background-color: $orange;
				-webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
				clip-path: polygon(100% 0, 0% 100%, 100% 100%);
				overflow: hidden;
				z-index: -1;

				&:before {
					@include overlay();
					background-image: url('../images/accent-facet-overlay.png');
					background-repeat: no-repeat;
					background-position: 240% 180%;
				}
			}
		}

		.service-text {
			.service-listings {
				.color-accent {
					@include transition-ease-in-out();
					position: absolute;
					top: bsu(-1);
					left: bsu(-2);
					width: 60%;
					height: 150%;
					background-color: $orange;
					-webkit-clip-path: polygon(100% 0, 0 0, 0 98%);
					clip-path: polygon(100% 0, 0 0, 0 98%);
					overflow: hidden;
					opacity: 0;

					&:before {
						@include overlay();
						background-image: url('../images/accent-facet-overlay.png');
						background-repeat: no-repeat;
						background-position: -420px -120px;
					}
				}

				.button {
					position: absolute;
					bottom: 20px;
					right: 40px;
					opacity: 0;

					span {
						display: none;
					}

					&:before {
						@include overlay();
						content: '...';
						color: $white;
						background: linear-gradient( 155deg, $orange-light 18%, $orange 40%, $orange 55%, $orange-light );
						height: 40px;
						width: 40px;
						text-align: center;
						font-weight: 700;
						line-height: 32px;
						letter-spacing: 1px;
						border-radius: 50%;
					}
				}

				li:hover,
				li.active {
					.color-accent {
						left: bsu(-1);
						opacity: 1;
					}

					a {
						background: $white;
						box-shadow: 22px 22px 52px 0px rgba(0,0,0,0.1);
					}

					.button {
						right: 80px;
						opacity: 1;
					}
				}
			}
		}
	} //xlarge

	@include breakpoint( xxlarge ) {
	} //xxlarge
}