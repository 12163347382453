/**
 * TEK | HOME SERVICES CTA
 */


    .cta {
      display: flex;
      flex-wrap: wrap;
      @include breakpoint(large) {
        flex-shrink: 0;
        width: 120%;
      }
      
      
      h1 {
        @include breakpoint(large) {
        }
        width: 100%;
        // text-align: center;
        margin-bottom: 1rem;
        span {
          font-style: italic;
          color: #0281a6;
        }
      }
      h3 {
        text-align: center;
      }
      p {
        font-size: 1.1rem;
        color: #ffffff;
      }
      .cta-text {
        flex: 2;
        background: linear-gradient(180deg, rgb(31,127,169)0%, rgb(17,77,140) 50%);
        padding: 0 2rem 2rem 2rem;
        margin: 0 0 2rem 0;
        flex-direction: column;
        display: flex;
      }

      .cta-form-btn{
        display: flex;
        position: relative;
        top: 2rem;
        margin:auto;
        

        @include breakpoint(large) {
          padding-left: 5rem;
          margin: initial;
        }

      }

      .cta-vid-thumb-containter{
        cursor: pointer;
        // background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem;

        @include breakpoint(large) {
          flex: 1.2;
          // margin-top: initial;
          width: 125%;
          transform: translateX(-50%);

        }
      }
      
      .cta-video {
        .vid-modal {
          display: none;
        }
      }

    
      .vid-thumb {
        position: relative;
        cursor: pointer;
        margin: auto;
      }
      

    }
    
    .cta-services-container {
      display: flex;
      justify-content: space-around;
      position: relative;
      bottom: -5rem;
      margin-bottom: 8rem;
      flex-flow: row wrap;
      transition: all .2s ease-in-out;
      z-index: 2;
      &.show {
        z-index: 1;
      }

      .cta-services {
        transition: all .2s ease-in-out;
        position: relative;
        flex: 0 24%;
        min-width: 285px;
        transform: scale(1.2);
        &:hover{
          transform: scale(1.1);
        }
        @include breakpoint(large) {
          min-width: 300px;
          transform: initial;
        }
        @include breakpoint(large) {
          min-width: initial;
        }
        

        .cta-services-content {
          position: relative;
          padding: 8%;
          z-index: 2;
          margin-bottom: 15rem;
          
          @include breakpoint(large) {
            margin-bottom: initial;
          }
          
          & p {
            color: white;
          }
          & h2 {
            color: white;
            font-size: 2rem;
          }

        }

        & img {
          position: absolute;
          object-fit: cover;
          top: 0%;
          z-index: 0;
        }
      }
    }