@charset "UTF-8";
/**
 * TEK | MAIN STYLES
 */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/**
 * TEK | VENDOR PARTIALS
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease; }
  .slick-lightbox .slick-loading .slick-list {
    background-color: transparent; }
  .slick-lightbox .slick-prev {
    left: 15px; }
  .slick-lightbox .slick-next {
    right: 15px; }

.slick-lightbox-hide {
  opacity: 0; }
  .slick-lightbox-hide.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0; }
  .slick-lightbox-hide-init.slick-lightbox-ie {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden; }
  .slick-lightbox-slick-item:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; }
  .slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 20px; }
  .slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
    display: block;
    text-align: center; }

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%; }

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%; }

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white; }

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none; }
  .slick-lightbox-close:focus {
    outline: none; }
  .slick-lightbox-close:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '×'; }

/**
 * TEK | VENDOR PARTIALS
 */
@media (min-width: 1024px) {
  .has-ednbar nav {
    margin-top: 57px; } }

.edn-opanel-trigger-image {
  animation-name: rob-example;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative; }

@keyframes rob-example {
  0% {
    bottom: -136px; }
  50% {
    bottom: 0px; }
  100% {
    bottom: 0px; } }

/**
 * TEK | MIXINS
 */
/**
 * Object-fit "cover"
 */
/**
 * Centered block
 */
/**
 * Centered element trick!
 */
/**
 * Make a flex "row"
 */
/**
 * Font Icon
 *
 * @param string  $content  escaped content code, such as FontAwesome's \1055
 */
/**
 * Remove bottom margin if last child
 */
/**
 * Remove top margin if first child
 */
/**
 * Text gradient
 */
/**
 * List
 */
/**
* Bulleted list
*/
/**
 * Numbered list
 */
/**
 * WYSIWYG Content Format
 */
/**
 * Kill font smoothing
 */
/**
 * Fluid Property!
 */
/**
 * Overlay
 */
/**
 * Generic transition
 */
/**
 * Clearfix
 */
/**
 * Rem Calc!
 */
/**
 * TEK | BREAKPOINTS
 The numbers below represend min-width.
 */
/**
 * TEK | VARIABLES
 */
/**
 * Primary & Secondary Colors
 */
/**
 * Neutral & Support Colors
 */
/**
 * Fonts & typography spacing
 */
/**
 * Spacing
 */
/**
 * TEK | TYPOGRAPHY
 */
*::selection {
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  text-shadow: none; }

*::-moz-selection {
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  text-shadow: none; }

body {
  font-size: 15px;
  line-height: 1.99;
  color: #0f0f0f;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased; }

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.2; }

h1, h2, h4 {
  font-weight: 800; }

h1 {
  font-size: 3.2rem; }

h2 {
  font-size: 2.4rem; }

h3 {
  font-weight: 600;
  font-size: 1.6rem;
  color: #0281a6;
  line-height: 1.5; }

h4 {
  color: #f6b830;
  font-size: 1.2rem; }

@media (min-width: 640px) {
  h1 {
    font-size: 4rem; } }

/**
 * Paragraphs
 */
p {
  margin: 1.99rem 0 0;
  font-size: 1rem;
  color: #666666; }

/**
 * Anchors
 */
a, a:visited {
  color: #f6b830;
  text-decoration: none;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  a:hover, a:visited:hover {
    color: #0281a6; }

/**
 * Blockquotes & Cites
 */
blockquote, q {
  quotes: "" "";
  color: #666666; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: ""; }

/**
 * Horizontal Rule
 */
hr {
  margin: 3.98rem 0;
  border: none; }

/**
 * Address
 */
address {
  font-style: normal; }

/**
 * Lists
 */
ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  ul.bulleted li, ol.bulleted li {
    position: relative;
    padding-left: 2rem;
    /*&:after {
			top: 10px;
			left: 5px;
			width: 6px;
			height: 6px;
			background: $white;
		}*/ }
    ul.bulleted li:before, ul.bulleted li:after, ol.bulleted li:before, ol.bulleted li:after {
      content: '';
      border-radius: 50%;
      display: inline-block;
      position: absolute; }
    ul.bulleted li:before, ol.bulleted li:before {
      top: 5px;
      left: 0;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: solid #f6b830 5px;
      background: linear-gradient(to top, #ff731a, #f6b830) 1 100%;
      clip-path: circle(50% at 50% 50%); }
  ul.bulleted ul li, ol.bulleted ul li {
    padding-left: 1.32667rem; }
    ul.bulleted ul li:before, ul.bulleted ul li:after, ol.bulleted ul li:before, ol.bulleted ul li:after {
      width: auto;
      height: auto;
      top: auto;
      background: none; }
    ul.bulleted ul li:before, ol.bulleted ul li:before {
      content: '-';
      color: #ff731a;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.5; }

/**
 * WYSIWYG Content Output
 */
.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.388rem; }

.entry-content h1:last-child, .entry-content > h2:last-child, .entry-content > h3:last-child, .entry-content > h4:last-child, .entry-content > h5:last-child, .entry-content > h6:last-child, .entry-content > p:last-child, .entry-content > ol:last-child, .entry-content > ul:last-child, .entry-content > img:last-child {
  margin-bottom: 0; }

.entry-content h1:first-child, .entry-content > h2:first-child, .entry-content > h3:first-child, .entry-content > h4:first-child, .entry-content > h5:first-child, .entry-content > h6:first-child, .entry-content > p:first-child, .entry-content > ol:first-child, .entry-content > ul:first-child, .entry-content > img:first-child {
  margin-top: 0; }

.entry-content ul, .entry-content ol {
  color: #666666;
  margin-top: 1.99rem;
  margin-bottom: 2.985rem; }
  .entry-content ul li, .entry-content ol li {
    margin: 0.995rem 0;
    position: relative;
    padding-left: 1.99rem; }
    .entry-content ul li:before, .entry-content ul li:after, .entry-content ol li:before, .entry-content ol li:after {
      position: absolute; }
  .entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
    margin-top: 0; }
    .entry-content ul ul li, .entry-content ul ol li, .entry-content ol ul li, .entry-content ol ol li {
      margin: 0.398rem 0; }
  @media (min-width: 640px) {
    .entry-content ul li, .entry-content ol li {
      margin: 1.32667rem 0; } }
  .entry-content ul:last-child, .entry-content ol:last-child {
    margin-bottom: 0; }

.entry-content ul li {
  position: relative;
  padding-left: 2rem;
  /*&:after {
			top: 10px;
			left: 5px;
			width: 6px;
			height: 6px;
			background: $white;
		}*/ }
  .entry-content ul li:before, .entry-content ul li:after {
    content: '';
    border-radius: 50%;
    display: inline-block;
    position: absolute; }
  .entry-content ul li:before {
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: solid #f6b830 5px;
    background: linear-gradient(to top, #ff731a, #f6b830) 1 100%;
    clip-path: circle(50% at 50% 50%); }

.entry-content ul ul li {
  padding-left: 1.32667rem; }
  .entry-content ul ul li:before, .entry-content ul ul li:after {
    width: auto;
    height: auto;
    top: auto;
    background: none; }
  .entry-content ul ul li:before {
    content: '-';
    color: #ff731a;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5; }

.entry-content ol {
  counter-reset: section; }
  .entry-content ol li:before {
    counter-increment: section;
    content: counters(section, ".") ".";
    left: 0;
    top: -1px;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 800;
    color: #ff731a;
    background: linear-gradient(#f6b830, #ff731a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .entry-content ol ol li {
    padding-left: 2.4875rem; }
    .entry-content ol ol li:before {
      font-size: 1.2rem;
      line-height: 1.5; }

.entry-content [role="button"] {
  margin: 0.4975rem 0.4975rem 0.4975rem 0; }

/**
 * Font Awesome
 */
.fa {
  font-weight: 400;
  -webkit-font-smoothing: auto; }

/**
* Increase font size when needed. 
*/
.bigger {
  font-size: larger; }

/**
 * TEK | MAIN STRUCTURE
 */
html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #ffffff;
  overflow-x: hidden; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

/**
 * Wrapper
 */
.wrap {
  position: relative;
  margin: 0 auto;
  width: 90%;
  max-width: 1140px; }

/**
 * Main container
 */
#content {
  position: relative;
  z-index: 2; }
  @media (min-width: 1200px) {
    body.home #content main:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url("../images/logo-e.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center 700px;
      transform: rotate(-4deg); } }

/**
 * Main content container
 */
[role="main"] {
  display: block; }
  [role="main"].default .wrap {
    max-width: 945px; }
  [role="main"] > article {
    width: 100%; }

/**
 * Figure & img fixes
 */
figure {
  margin: 0; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

/**
 * TEK | COMPONENT PARTIALS
 */
/**
 * TEK | RESPONSIVE EMBED
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 440px;
  overflow: hidden; }
  .embed-container iframe, .embed-container object, .embed-container embed, .embed-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
 * TEK | BUTTON COMPONENT
 */
/**
 * Angled button
 */
/**
 * Base button
 */
button, [role="button"], [type="submit"], [type="button"] {
  position: relative;
  z-index: 0;
  display: inline-block;
  padding: 0.93333rem 1.86667rem 1.06667rem;
  text-align: center;
  font-size: 1.13333rem;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  button:after, [role="button"]:after, [type="submit"]:after, [type="button"]:after {
    z-index: -2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(155deg, #f6b830 18%, #ff731a 40%, #ff731a 55%, #f6b830);
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  button .fa, [role="button"] .fa, [type="submit"] .fa, [type="button"] .fa {
    margin: 0 0.4975rem 0 -2px; }
  button:hover, button:focus, [role="button"]:hover, [role="button"]:focus, [type="submit"]:hover, [type="submit"]:focus, [type="button"]:hover, [type="button"]:focus {
    color: #ffffff; }
    button:hover:after, button:focus:after, [role="button"]:hover:after, [role="button"]:focus:after, [type="submit"]:hover:after, [type="submit"]:focus:after, [type="button"]:hover:after, [type="button"]:focus:after {
      left: -100%; }
  button.angled, [role="button"].angled, [type="submit"].angled, [type="button"].angled {
    padding: 1.73333rem 1.86667rem;
    text-transform: uppercase;
    font-size: 1.33333rem;
    clip-path: polygon(0 10px, 66px 0px, 100% 10px, 100% calc( 100% - 10px), calc( 100% - 66px) 100%, 0 calc( 100% - 10px)); }
    button.angled:after, [role="button"].angled:after, [type="submit"].angled:after, [type="button"].angled:after {
      background: linear-gradient(to right, #f6b830, #ff731a);
      width: 100%; }
    button.angled:before, [role="button"].angled:before, [type="submit"].angled:before, [type="button"].angled:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to right, #fac019, #f3661c);
      clip-path: polygon(0 10px, 100% calc( 100% - 10px), 100% 100%, 0 100%); }
    button.angled:hover:after, button.angled:focus:after, [role="button"].angled:hover:after, [role="button"].angled:focus:after, [type="submit"].angled:hover:after, [type="submit"].angled:focus:after, [type="button"].angled:hover:after, [type="button"].angled:focus:after {
      left: 0; }
    button.angled:visited, [role="button"].angled:visited, [type="submit"].angled:visited, [type="button"].angled:visited {
      color: #ffffff; }
  button.btn-sm, [role="button"].btn-sm, [type="submit"].btn-sm, [type="button"].btn-sm {
    padding: 0.46667rem 0.93333rem 0.53333rem;
    font-size: 1rem;
    color: #ffffff;
    margin-right: 0.66667rem; }
  button.btn-primary:after, [role="button"].btn-primary:after, [type="submit"].btn-primary:after, [type="button"].btn-primary:after {
    background: linear-gradient(155deg, #0281a6 18%, #01478b 40%, #01478b 55%, #0281a6); }

/**
 * Base button for Video
 */
.wp-video [role=button]:after, [type=button]:after, [type=submit]:after, button:after {
  background: none; }

.mejs-overlay:hover > .mejs-overlay-button {
  background-position: 0px -39px !important; }

/**
 * TEK | FORM COMPONENT
 */
form ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

input:focus, textarea:focus, select:focus, label:focus {
  outline: none; }

input, textarea {
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

/**
 * TEK | Arrow Link
 */
.arrow {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #0281a6;
  font-size: 1.2rem;
  font-weight: 800; }
  .arrow:hover {
    color: #f6b830; }
    .arrow:hover > span:before {
      opacity: 1; }
  .arrow > span, .arrow svg {
    position: relative; }
  .arrow > span {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-right: 0.995rem;
    width: 31px;
    height: 31px;
    border-radius: 9999px;
    overflow: hidden;
    background: linear-gradient(155deg, #f6b830, #ff731a 80%); }
    .arrow > span:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(155deg, #0281a6, #01478b 80%);
      opacity: 0;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .arrow svg {
    display: block; }

/**
 * TEK | SLANTS
 */
#e-fix-opacity {
  opacity: 0 !important; }

#e-fix-position {
  position: absolute !important;
  top: 24% !important;
  left: -8% !important; }

.slant-container {
  position: relative;
  z-index: 1;
  margin: 3.98rem 0; }
  @media (min-width: 1024px) {
    .slant-container {
      margin: 7.96rem 0; } }

.slant,
.accent-slant {
  position: relative;
  overflow: hidden; }

.flickerfix {
  -webkit-transform: translate3d(0, 0, 0); }

.slant {
  position: relative;
  transform: rotate(-6deg) translate(0, -20px);
  width: 130%;
  left: -15%;
  z-index: 1; }
  .slant .tek-overlay {
    position: absolute;
    mix-blend-mode: color-dodge;
    opacity: 0.35; }
    .slant .tek-overlay path {
      fill: #0281a6; }
  .slant > div {
    position: relative;
    width: 99%;
    height: 125%;
    top: 1.99rem;
    transform: rotate(6deg); }
  .slant .wrap {
    width: 80%; }
  .slant.reverse {
    transform: rotate(8deg); }
    .slant.reverse > div {
      transform: rotate(-8deg);
      clip-path: polygon(0% 25%, 100% 0%, 100% 100%, 0 75%);
      background: #ffffff; }
  @media (min-width: 460px) {
    .slant {
      width: 120%;
      left: -10%; } }
  @media (min-width: 640px) {
    .slant > div {
      top: 3.6815rem; } }
  @media (min-width: 1024px) {
    .slant > div {
      top: 5.97rem; } }
  @media (min-width: 1200px) {
    .slant {
      transform: rotate(-6deg) translate(0, -20px); }
      .slant > div {
        transform: rotate(6deg); }
      .slant.reverse {
        transform: rotate(6deg); }
        .slant.reverse > div {
          transform: rotate(-6deg); } }
  @media (min-width: 2000px) {
    .slant {
      transform: rotate(-6deg) translate(0, 30px); }
      .slant > div {
        top: 7.96rem; } }

.banner-slant > div {
  padding: 15.92rem 0 11.94rem; }
  .banner-slant > div:before, .banner-slant > div:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%; }
  .banner-slant > div:after {
    background-color: rgba(18, 28, 98, 0.4);
    height: 125%;
    z-index: -1;
    top: -10%; }

.banner-slant img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: -25%;
  left: 0px;
  height: 150%; }

@media (min-width: 640px) {
  .banner-slant > div {
    padding: 19.9rem 0 11.94rem; } }

@media (min-width: 1024px) {
  .banner-slant > div {
    padding: 25.87rem 0 11.94rem; } }

@media (min-width: 1200px) {
  .banner-slant > div {
    padding: 23.88rem 0 19.9rem; } }

@media (min-width: 2000px) {
  .banner-slant img {
    top: 0;
    height: 120%; }
  .banner-slant > div {
    padding: 33.83rem 0 15.92rem; } }

.dual-slant-overlay {
  position: absolute;
  overflow: hidden;
  width: 120%;
  height: 200px;
  z-index: 2;
  left: -10%;
  top: -20%;
  background: #ffffff;
  z-index: 2;
  transform: rotate(8deg); }
  .dual-slant-overlay.reverse {
    transform: rotate(-8deg); }
  @media (min-width: 640px) {
    .dual-slant-overlay {
      height: 290px;
      top: -25%; } }
  @media (min-width: 1024px) {
    .dual-slant-overlay {
      height: 400px; } }
  @media (min-width: 1200px) {
    .dual-slant-overlay {
      top: -28%;
      transform: rotate(6deg); }
      .dual-slant-overlay.reverse {
        transform: rotate(-6deg); } }
  @media (min-width: 2000px) {
    .dual-slant-overlay {
      height: 550px; } }

.accent-slant {
  display: none;
  width: 600px;
  height: 200px;
  transform: rotate(20deg);
  float: right;
  left: initial;
  top: -150px;
  right: -200px;
  z-index: 0; }
  .accent-slant > div {
    position: relative;
    width: 80%;
    height: 130%;
    transform: rotate(-20deg) translate(-70px, 35px); }
    .accent-slant > div:before, .accent-slant > div:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%; }
    .accent-slant > div:before {
      background: linear-gradient(115deg, #f6b830 0%, #ff731a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 );
      mix-blend-mode: overlay; }
    .accent-slant > div:after {
      background-image: url("../images/accent-facet-overlay.png");
      background-repeat: no-repeat;
      background-position: 50px -55px;
      background-size: cover; }
      @media (min-width: 1200px) {
        .accent-slant > div:after {
          background-position: center center; } }
      @media (min-width: 2000px) {
        .accent-slant > div:after {
          background-position: -60px -170px; } }
  .accent-slant.reverse {
    float: left;
    top: initial;
    bottom: -85%;
    right: initial;
    transform: rotate(16deg); }
    .accent-slant.reverse > div {
      top: -200px;
      width: 100%;
      transform: rotate(-16deg); }
      .accent-slant.reverse > div:before {
        background: linear-gradient(115deg, #0281a6 0%, #01478b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='$blue-dark',GradientType=1 );
        mix-blend-mode: overlay;
        height: 110%; }
  @media (min-width: 1024px) {
    .accent-slant {
      display: block; } }
  @media (min-width: 1200px) {
    .accent-slant {
      width: 700px; }
      .accent-slant > div {
        width: 90%; } }
  @media (min-width: 1440px) {
    .accent-slant {
      width: 820px;
      transform: rotate(20deg) translate(0px, -20px);
      top: -175px; }
      .accent-slant > div {
        transform: rotate(-20deg) translate(-5px, 35px); } }
  @media (min-width: 2000px) {
    .accent-slant {
      width: 870px;
      transform: rotate(23deg) translate(0px, 45px);
      height: 330px;
      top: -250px;
      right: -250px; }
      .accent-slant > div {
        transform: rotate(-23deg) translate(25px, 115px); } }

/**
 * TEK | DEFAULT WORDPRESS COMPONENTS
 */
/*
	CLEARINGS
*/
.clear:before,
.entry-content:before,
.comment-content:before,
.site-header:before,
.site-footer:before {
  content: " ";
  display: table; }

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-footer:after {
  clear: both;
  content: " ";
  display: table; }

/*
	ALIGNMENTS
*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 3.98rem;
  margin-bottom: 1.99rem; }

.alignright {
  display: inline;
  float: right;
  margin-left: 3.98rem;
  margin-bottom: 1.99rem; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*
	CAPTIONS / MEDIA
*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-caption .wp-caption-text {
    margin: 0.8075em 0; }

.wp-caption-text {
  text-align: center; }

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

/*
	SCREEN READERS
*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #000000;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

/*
	JETPACK
*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none; }

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block; }

/**
 * TEK | TOOLTIPS
 */
.tooltip-container {
  position: relative;
  display: none; }
  @media (min-width: 1024px) {
    .tooltip-container {
      display: block; } }

.tooltip-trigger {
  position: absolute;
  width: 400px; }
  .tooltip-trigger .tooltip-circle {
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 10px solid #f6b830;
    border-radius: 50%;
    padding: 0;
    text-indent: -9999px;
    z-index: 1; }
    .tooltip-trigger .tooltip-circle:after {
      display: none; }
  .tooltip-trigger .text-container {
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    opacity: 0;
    position: relative;
    left: 15px;
    padding: 1.99rem;
    background: #ffffff;
    box-shadow: 22px 22px 52px 0px rgba(0, 0, 0, 0.1);
    margin-top: -25px; }
  .tooltip-trigger p {
    margin: 0; }
  .tooltip-trigger.closed .tooltip-circle {
    animation: pulse 2.5s infinite; }
  .tooltip-trigger.open .tooltip-circle {
    background: transparent;
    border: 6px solid #f6b830;
    transform: scale(2); }
  .tooltip-trigger.open .text-container {
    opacity: 1; }

@keyframes pulse {
  0% { }
  30% {
    transform: scale(1);
    border: 10px solid #f6b830; }
  50% {
    transform: scale(1.5);
    border: 10px solid #ff731a; }
  70% {
    transform: scale(1);
    border: 10px solid #f6b830; }
  100% { } }

/**
 * TEK | Testimonials
 */
#testimonials {
  position: relative;
  padding: 6.66667rem 0 6.66667rem;
  z-index: 2; }
  #testimonials h1 {
    font-size: 10vw; }
    @media (min-width: 640px) {
      #testimonials h1 {
        font-size: 4rem; } }
  #testimonials .slider-arrows {
    padding: 1rem 0; }
    @media (min-width: 640px) {
      #testimonials .slider-arrows {
        position: absolute;
        top: 105%;
        left: 0px;
        z-index: 2; } }
    #testimonials .slider-arrows .slick-arrow {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #testimonials .slider-arrows .slick-arrow:after {
        display: none; }
      #testimonials .slider-arrows .slick-arrow.slick-prev {
        transform: rotate(-180deg);
        margin-right: 0.4975rem; }
      @media (min-width: 1024px) {
        #testimonials .slider-arrows .slick-arrow:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #testimonials .slider-arrows .slick-arrow:hover.slick-prev {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #testimonials .testimonial-slider {
    clip-path: inset(0 1.66667rem 0 0);
    margin-left: -1.66667rem;
    padding-left: 1.66667rem;
    overflow: hidden; }
    #testimonials .testimonial-slider .slick-list {
      overflow: visible; }
      #testimonials .testimonial-slider .slick-list .slick-slide p {
        font-size: 1rem; }
    #testimonials .testimonial-slider .testimonial-wrapper {
      position: relative;
      margin: 0 1.33333rem 0 -1.33333rem;
      padding: 0 1.66667rem 0 1.66667rem; }
      #testimonials .testimonial-slider .testimonial-wrapper p {
        padding-right: 1rem;
        padding-bottom: 1rem; }
      #testimonials .testimonial-slider .testimonial-wrapper:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        background-image: url("../images/quote.png");
        background-repeat: no-repeat; }

/**
 * TEK | image-slant
 */
.image-slant {
  position: relative;
  top: -13em; }

.image-slant-container {
  position: relative;
  z-index: 0;
  margin: 0rem 0; }
  @media (min-width: 1024px) {
    .image-slant-container {
      margin: 0rem 0; } }

/**
 * TEK | HERO VIDEOPLAYER MUTE
 */
.mute-controls {
  position: relative;
  left: 10%; }

.mute-btn {
  background-color: transparent;
  padding: initial;
  font-size: 3rem; }

.mute {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mute.unmuted {
    opacity: 0.5; }
    .mute.unmuted .slash {
      display: none; }
  .mute .slash {
    position: absolute;
    width: 3px;
    height: 100%;
    background: white;
    transform: rotate(-50deg);
    display: block; }

@media (min-width: 0px) {
  .mute-controls {
    position: relative;
    left: 15%; } }

@media (min-width: 460px) {
  .mute-controls {
    left: 10%; } }

/**
 * TEK | VIDEO
 */
/**
 * Center wp-video
 */
.holiday-card .wp-video {
  margin: auto; }

/**
 * TEK | Holiday Lights
 */
/* Simple SVG Christmas Lights Animation */
#Lights {
  height: 100%;
  margin: 0;
  width: 100%;
  z-index: 1000; }

.cls-9 {
  fill: none;
  stroke: #8C6239; }

.cls-2 {
  fill: #603813; }

.cls-3 {
  fill: #8C6239; }

.teal_bulb {
  fill: #018AA8;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #018AA8);
  filter: drop-shadow(1px 1px 10px #018AA8); }

.purple_bulb {
  fill: #685899;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #685899);
  filter: drop-shadow(1px 1px 10px #685899); }

.white_bulb {
  fill: #FFECD7;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #FFECD7);
  filter: drop-shadow(1px 1px 10px #FFECD7); }

.red_bulb {
  fill: #CE402C;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #CE402C);
  filter: drop-shadow(1px 1px 10px #CE402C); }

.green_bulb {
  fill: #60AA20;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #60AA20);
  filter: drop-shadow(1px 1px 10px #60AA20); }

.orange_bulb {
  fill: #FFA400;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
  -webkit-filter: drop-shadow(1px 1px 10px #FFA400);
  filter: drop-shadow(1px 1px 10px #FFA400); }

/* Simply changing the opacity of the SVG fill to create the glow effect for the bulbs */
.bulb {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: glow;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  animation-name: glow;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate; }

@-webkit-keyframes glow {
  0% {
    fill-opacity: 1; }
  100% {
    fill-opacity: .25;
    -webkit-filter: initial;
    filter: initial; } }

@keyframes glow {
  0% {
    fill-opacity: 1; }
  100% {
    fill-opacity: .25;
    -webkit-filter: initial;
    filter: initial;
    box-shadow: violet; } }

/* Made a set of ten animation delays to create a more random effect of lights blinking.  Simplest way without jumping through SVG hoops. */
.blink-1 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.blink-2 {
  -webkit-animation-delay: .12s;
  animation-delay: .12s; }

.blink-3 {
  -webkit-animation-delay: .8s;
  animation-delay: .8s; }

.blink-4 {
  -webkit-animation-delay: .34s;
  animation-delay: .34s; }

.blink-5 {
  -webkit-animation-delay: .5s;
  animation-delay: .5s; }

.blink-6 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s; }

.blink-7 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s; }

.blink-8 {
  -webkit-animation-delay: .7s;
  animation-delay: .7s; }

.blink-9 {
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s; }

.blink-10 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

/**
 * TEK | LAYOUT PARTIALS
 */
/**
 * TEK | HEADER
 */
header[role="banner"] {
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  padding: 0.995rem;
  position: fixed;
  width: 100%;
  z-index: 3;
  /**
	 * Admin bar fix - for "non-static" headers
	 */ }
  header[role="banner"] > div {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  header[role="banner"] #menu-toggle {
    display: flex;
    align-items: center;
    padding: 0;
    line-height: 1;
    overflow: unset;
    background: none; }
    header[role="banner"] #menu-toggle:after {
      background: none; }
    header[role="banner"] #menu-toggle span {
      display: inline-block; }
      header[role="banner"] #menu-toggle span:last-child {
        margin-left: 0.597rem;
        width: 29px;
        height: 17px; }
  header[role="banner"].scrolled, header[role="banner"].toggled {
    background-color: #ffffff; }
    header[role="banner"].scrolled #menu-toggle, header[role="banner"].toggled #menu-toggle {
      color: #0f0f0f; }
    header[role="banner"].scrolled rect, header[role="banner"].toggled rect {
      fill: #0f0f0f; }
  header[role="banner"].scrolled:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    height: 10px;
    top: initial;
    bottom: -10px;
    background: #ff731a;
    background: linear-gradient(to right, #f6b830 0%, #ff731a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 ); }
  @media (min-width: 1024px) {
    header[role="banner"] {
      padding: 1.5rem; }
      header[role="banner"].toggled {
        background: none; }
      header[role="banner"].scrolled {
        background-color: #ffffff; }
      header[role="banner"] > div {
        justify-content: center;
        max-width: 300px;
        margin: 0 auto; }
      header[role="banner"] .site-logo {
        margin-left: 30px; }
      header[role="banner"] #menu-toggle {
        display: none; } }
  @media (min-width: 600px) {
    body.admin-bar header[role="banner"] {
      top: 46px !important; } }
  @media (min-width: 782px) {
    body.admin-bar header[role="banner"] {
      top: 32px !important; } }

.site-logo {
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  display: block;
  width: 108px;
  height: 30px; }
  .site-logo p {
    display: none; }
  @media (min-width: 640px) {
    .site-logo {
      height: 35px; } }
  @media (min-width: 1024px) {
    .site-logo {
      width: 160px;
      height: 60px; } }

@media (min-width: 1200px) {
  body.home header[role="banner"] .site-logo {
    width: 268px;
    height: 100px;
    margin-top: 50px; }
    body.home header[role="banner"] .site-logo:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.7);
      box-shadow: 0 0 60px 60px rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      opacity: 0.25;
      top: 50px; }
  body.home header[role="banner"].scrolled .site-logo {
    width: 160px;
    height: 60px;
    margin-top: 0; }
    body.home header[role="banner"].scrolled .site-logo:before {
      opacity: 0; } }

/**
 * TEK | NAVIGATION
 */
header[role="banner"] {
  /**
	 * Toggle button
	 */
  /**
	 * Main Navigation
	 */ }
  @media (min-width: 1024px) {
    header[role="banner"] button {
      display: none; } }
  header[role="banner"] nav {
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    display: block;
    position: fixed;
    top: -100%;
    z-index: 1;
    padding: 0 0 0.4975rem;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    left: 0px;
    /**
		 * Main Navigation - Desktop "menu break"
		 */ }
    header[role="banner"] nav:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      height: 10px;
      top: initial;
      bottom: 0px;
      background: #ff731a;
      background: linear-gradient(to right, #f6b830 0%, #ff731a 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 ); }
    header[role="banner"] nav.scroll-y {
      bottom: 100%;
      overflow-y: scroll; }
      header[role="banner"] nav.scroll-y.toggled {
        bottom: 0; }
    header[role="banner"] nav ul {
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      margin: 0;
      padding: 0; }
    header[role="banner"] nav li {
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: relative;
      margin: 0; }
      header[role="banner"] nav li.sub-toggled {
        background: #f5f5f5; }
        header[role="banner"] nav li.sub-toggled .sub-menu {
          display: block;
          padding-bottom: 0.6965rem; }
        header[role="banner"] nav li.sub-toggled .toggle-sub {
          color: #666666; }
      header[role="banner"] nav li.active a {
        color: #f6b830; }
    header[role="banner"] nav a {
      display: block;
      padding: 0.66333rem 0.995rem;
      text-decoration: none;
      color: #0f0f0f;
      border-top: 1px solid #fafafa;
      font-size: 1.33333rem;
      font-weight: 600; }
      header[role="banner"] nav a:hover {
        color: #f6b830; }
    header[role="banner"] nav .toggle-sub {
      display: block;
      position: absolute;
      z-index: 10;
      right: 1.99rem;
      top: 14px;
      padding: 0.2rem 0.26667rem;
      background: none; }
      header[role="banner"] nav .toggle-sub:before {
        content: "";
        font-family: "FontAwesome";
        font-weight: 400;
        color: #0f0f0f; }
      header[role="banner"] nav .toggle-sub:after {
        background: none; }
    header[role="banner"] nav .sub-menu {
      display: none; }
      header[role="banner"] nav .sub-menu li a {
        border: none;
        padding: 0.398rem 0.66333rem 0.398rem 2.985rem;
        font-size: 1rem;
        color: #666666; }
        header[role="banner"] nav .sub-menu li a:before {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          content: '-';
          padding-top: 0.2985rem;
          padding-left: 1.99rem; }
    @media (min-width: 1024px) {
      header[role="banner"] nav {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        overflow: visible;
        padding: 1.99rem 0 0 1.99rem;
        background: none;
        top: 0px !important; }
        header[role="banner"] nav:before {
          display: none; }
        header[role="banner"] nav ul {
          flex: 1 0 auto;
          display: flex;
          flex-flow: row nowrap;
          width: 50%; }
          header[role="banner"] nav ul#primary-left {
            justify-content: flex-end;
            padding-right: 6.4675rem; }
          header[role="banner"] nav ul#primary-right {
            justify-content: flex-start;
            padding-left: 6.4675rem; }
        header[role="banner"] nav li {
          display: block;
          margin: 0;
          width: auto; }
          header[role="banner"] nav li:hover .sub-menu {
            left: -10px;
            opacity: 1; }
        header[role="banner"] nav a {
          border: none;
          padding: 1.33333rem;
          color: #ffffff; }
        header[role="banner"] nav .toggle-sub {
          display: none; }
        header[role="banner"] nav .sub-menu {
          display: block;
          position: absolute;
          top: 5rem;
          left: -99999px;
          width: 200px;
          opacity: 0;
          transition: opacity ease-in-out 300ms;
          background-color: #f6b830; }
          header[role="banner"] nav .sub-menu li a {
            display: block;
            padding: 0.33333rem 0.66667rem 0.33333rem 1.33333rem;
            width: 100%;
            text-align: left;
            color: #0f0f0f;
            font-size: 1.2rem; }
            header[role="banner"] nav .sub-menu li a:before {
              content: '';
              background-color: #ffffff;
              width: 6px;
              padding: 0px;
              opacity: 0; }
            header[role="banner"] nav .sub-menu li a:hover {
              color: #ffffff; }
              header[role="banner"] nav .sub-menu li a:hover:before {
                opacity: 1; } }
    @media (min-width: 1200px) {
      header[role="banner"] nav a {
        padding: 1.2935rem 1.99rem; } }
  @media (min-width: 1024px) {
    header[role="banner"].scrolled a {
      color: #0f0f0f; } }

@media (min-width: 1200px) {
  body.home header[role="banner"] nav {
    padding: 6.766rem 0 0 1.99rem; }
    body.home header[role="banner"] nav ul#primary-left {
      padding-right: 10.4475rem; }
    body.home header[role="banner"] nav ul#primary-right {
      padding-left: 10.4475rem; }
  body.home header[role="banner"].scrolled nav {
    padding: 1.99rem 0 0 1.99rem; }
    body.home header[role="banner"].scrolled nav ul#primary-left {
      padding-right: 6.4675rem; }
    body.home header[role="banner"].scrolled nav ul#primary-right {
      padding-left: 6.4675rem; } }

/**
 * TEK | BANNER
 */
.hero-banner {
  position: relative; }
  .hero-banner .banner-slant,
  .hero-banner .accent-slant {
    position: relative;
    overflow: hidden; }
  .hero-banner .banner-slant {
    height: 200px;
    top: -100px; }
    .hero-banner .banner-slant .tek-overlay {
      top: 50%;
      margin-top: -300px;
      left: -1500px;
      width: 2142px;
      height: 800px; }
    .hero-banner .banner-slant > div {
      padding: 0;
      top: 0; }
      .hero-banner .banner-slant > div:before {
        top: 100px;
        height: 80px;
        background: linear-gradient(to bottom, rgba(0, 18, 23, 0.7) 0%, rgba(0, 18, 23, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001217', endColorstr='#00001217', GradientType=0); }
      .hero-banner .banner-slant > div:after {
        background-color: rgba(18, 28, 98, 0.7); }
    .hero-banner .banner-slant img {
      top: 0px; }
  .hero-banner .accent-slant {
    top: -275px; }
  .hero-banner .wrap {
    text-align: center;
    color: #ffffff;
    padding: 15.92rem 0rem 9.95rem; }
    @media (min-width: 1024px) {
      .hero-banner .wrap {
        text-align: left; } }
  .hero-banner .secondary-title-wrapper {
    text-align: left;
    display: inline-block;
    white-space: nowrap; }
  .hero-banner .secondary-title {
    margin: 0px !important; }
  .hero-banner .titlewrapper {
    display: inline-block; }
  .hero-banner .primary-title, .hero-banner .secondary-title {
    text-align: left;
    display: inline-block; }
  .hero-banner .primary-title {
    margin-left: auto;
    text-align: center;
    display: block; }
    @media (min-width: 1024px) {
      .hero-banner .primary-title {
        margin-left: 4.66667rem;
        display: inline-block; } }
  .hero-banner h2 {
    text-align: center; }
  @media (min-width: 0px) {
    .hero-banner .secondary-title-wrapper {
      height: 65px; } }
  @media (min-width: 640px) {
    .hero-banner .secondary-title-wrapper {
      height: 75px; } }
  @media (min-width: 1024px) {
    .hero-banner .secondary-title-wrapper {
      height: 108px; } }
  .hero-banner .secondary-title {
    text-align: center;
    display: block;
    margin: auto; }
    @media (min-width: 640px) {
      .hero-banner .secondary-title {
        margin-left: 4.66667rem;
        display: inline-block; } }
    .hero-banner .secondary-title[hidden] {
      display: none; }
    .hero-banner .secondary-title:before {
      content: "\00a0";
      font-size: 0em; }
  body.home .hero-banner .banner-slant > div:after {
    background-color: initial; }
  body.home .hero-banner h1 {
    font-size: calc(4.375vw + 34px); }
    @media (max-width: 320px) {
      body.home .hero-banner h1 {
        font-size: 48px; } }
    @media (min-width: 1280px) {
      body.home .hero-banner h1 {
        font-size: 90px; } }
  body.home .hero-banner h2 {
    font-size: calc(2.70833vw + 9.33333px);
    font-weight: 600;
    padding: 1.99rem 1.99rem 0;
    line-height: 2.4rem; }
    @media (max-width: 320px) {
      body.home .hero-banner h2 {
        font-size: 18px; } }
    @media (min-width: 1280px) {
      body.home .hero-banner h2 {
        font-size: 44px; } }
  body.home .hero-banner .banner-slant {
    height: auto;
    background-color: #ffffff; }
    body.home .hero-banner .banner-slant img {
      height: 115%; }
  body.home .hero-banner video {
    display: none;
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    min-width: 120%;
    min-height: 120%;
    transform: translateX(-50%) translateY(-50%); }
  body.home .hero-banner .hero-arrow {
    position: absolute;
    width: 138px;
    height: 90px;
    bottom: 60px;
    left: 50%;
    margin-left: -69px;
    z-index: 3; }
  @media (min-width: 640px) {
    .hero-banner .banner-slant {
      height: 240px; }
      .hero-banner .banner-slant > div {
        height: 150%; }
        .hero-banner .banner-slant > div:before {
          height: 120px; }
    body.home .hero-banner .banner-slant img {
      height: 115%; } }
  @media (min-width: 1024px) {
    .hero-banner .banner-slant {
      height: 300px; }
      .hero-banner .banner-slant .tek-overlay {
        margin-top: -480px;
        left: -2220px;
        width: 3213px;
        height: 1200px; }
    .hero-banner .wrap {
      padding: 23.88rem 0rem 15.92rem; }
    body.home .hero-banner h2 {
      padding: 1.99rem 7.96rem 0; }
    body.home .hero-banner video {
      display: block; }
    body.home .hero-banner .hero-arrow {
      width: 214px;
      height: 140px;
      margin-left: -112px;
      bottom: 35px;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      body.home .hero-banner .hero-arrow:hover {
        bottom: 15px; } }
  @media (min-width: 1440px) {
    .hero-banner .wrap {
      padding: 31.84rem 0rem 23.88rem; } }
  @media (min-width: 2000px) {
    .hero-banner .banner-slant {
      height: 350px;
      top: -150px; }
      .hero-banner .banner-slant img {
        height: 115%; }
    .hero-banner .accent-slant {
      top: -400px; } }

/**
 * TEK | FOOTER CTA
 */
.footer-cta {
  color: #ffffff;
  text-align: center;
  margin-top: 0px;
  /*
	.hero-arrow {
		position: absolute;
		width: 138px;
		height: 90px;
		top: 150px;
		left: 50%;
		margin-left: -69px;
		z-index: 3;
	} */ }
  .footer-cta [role="button"] {
    position: absolute;
    bottom: -50px;
    z-index: 2;
    left: 50%;
    margin-left: -125px;
    width: 250px; }
  @media (min-width: 0px) {
    .footer-cta .hero-arrow {
      display: none; } }
  @media (min-width: 640px) {
    .footer-cta .hero-arrow {
      position: absolute;
      display: block;
      width: 138px;
      height: 90px;
      top: 65px;
      left: 50%;
      margin-left: -69px;
      z-index: 3; } }
  @media (min-width: 1024px) {
    .footer-cta .hero-arrow {
      width: 214px;
      height: 140px;
      margin-left: -112px;
      top: 140px; }
      .footer-cta .hero-arrow:hover {
        top: 160px; } }
  @media (min-width: 2000px) {
    .footer-cta .hero-arrow {
      top: 220px; }
      .footer-cta .hero-arrow:hover {
        top: 240px; } }
  .footer-cta .banner-slant .tek-overlay {
    display: none;
    opacity: 0.5;
    top: 25%;
    left: 50%;
    width: 2142px;
    height: 800px; }
  .footer-cta .banner-slant > div:before {
    background-image: url("../images/footer-cta-facet-overlay.png");
    background-repeat: no-repeat;
    background-position: 20% 50%;
    height: 110%; }
  .footer-cta h2 {
    font-size: calc(4.375vw + 34px);
    padding: 0 1rem; }
    @media (max-width: 320px) {
      .footer-cta h2 {
        font-size: 48px; } }
    @media (min-width: 1280px) {
      .footer-cta h2 {
        font-size: 90px; } }
  @media (min-width: 1200px) {
    .footer-cta .banner-slant .tek-overlay {
      display: block; }
    .footer-cta .banner-slant > div:before {
      background-position: 20% 0;
      background-size: cover;
      height: 125%; } }

/**
 * TEK | FOOTER
 */
footer[role="contentinfo"] {
  position: relative;
  text-align: center;
  padding: 0.995rem; }
  footer[role="contentinfo"]:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    height: 10px;
    top: initial;
    bottom: 0px;
    background: #ff731a;
    background: linear-gradient(to right, #f6b830 0%, #ff731a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 ); }
  footer[role="contentinfo"] > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
  footer[role="contentinfo"] .menu,
  footer[role="contentinfo"] .sub-menu {
    display: none; }
  footer[role="contentinfo"] section {
    padding: 1.99rem 0; }
    footer[role="contentinfo"] section p {
      margin: 0px;
      font-size: 0.93333rem;
      color: #0f0f0f; }
  @media (min-width: 1024px) {
    footer[role="contentinfo"] .menu {
      display: flex;
      width: 50%; }
      footer[role="contentinfo"] .menu#primary-left-footer {
        padding-right: 2.4875rem;
        justify-content: flex-end; }
      footer[role="contentinfo"] .menu#primary-right-footer {
        padding-left: 2.4875rem;
        justify-content: flex-start; }
      footer[role="contentinfo"] .menu li {
        padding: 0 1.99rem; }
      footer[role="contentinfo"] .menu a {
        color: #0f0f0f;
        font-weight: 600; }
        footer[role="contentinfo"] .menu a:hover {
          color: #ff731a; }
    footer[role="contentinfo"] .wrap {
      display: block;
      margin: auto;
      text-align: center; }
    footer[role="contentinfo"] section {
      padding: 3.98rem 0 0.995rem; } }

/**
 * TEK | LAYOUT PAGES
 */
/**
 * TEK | HOME
 */
/**
 * TEK | HOME SERVICES
 */
#services-front {
  position: relative; }
  #services-front .intro {
    text-align: center;
    margin-bottom: 3.98rem; }
  #services-front .service-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto; }
  #services-front .service-images {
    display: none; }
  #services-front .service-text .service-listings svg {
    width: 3.33333rem;
    height: 3.33333rem;
    fill: currentColor;
    margin: 0 auto;
    display: block; }
    @media (min-width: 640px) {
      #services-front .service-text .service-listings svg {
        width: 5.33333rem;
        height: 5.33333rem; } }
    @media (min-width: 1024px) {
      #services-front .service-text .service-listings svg {
        width: 3.33333rem;
        height: 3.33333rem; } }
    @media (min-width: 1200px) {
      #services-front .service-text .service-listings svg {
        width: 5rem;
        height: 5rem; } }
    #services-front .service-text .service-listings svg .cls-3 {
      fill: currentColor; }
  #services-front .service-text .service-listings li {
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    position: relative;
    margin-bottom: 2.985rem; }
  #services-front .service-text .service-listings a {
    display: flex;
    align-items: center; }
  #services-front .service-text .service-listings div:nth-child(1) {
    width: 25%;
    padding: 0 1.4925rem 0 0;
    -moz-transform: translateY(10%) scale(1.3);
    -webkit-transform: translateY(10%) scale(1.3);
    transform: translateY(10%) scale(1.3); }
  #services-front .service-text .service-listings div:nth-child(2) {
    width: 75%; }
  #services-front .service-text .service-listings h2 {
    font-size: 1.2rem;
    color: #0f0f0f; }
  #services-front .service-text .service-listings p {
    margin-top: 0.4975rem;
    font-size: calc(0.10417vw + 13.66667px); }
    @media (max-width: 320px) {
      #services-front .service-text .service-listings p {
        font-size: 14px; } }
    @media (min-width: 1280px) {
      #services-front .service-text .service-listings p {
        font-size: 15px; } }
  #services-front .service-text .service-listings .button {
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    text-decoration: underline; }
  @media (min-width: 1024px) {
    #services-front {
      margin-bottom: -200px; }
      #services-front .service-container > div {
        display: flex;
        width: 100%;
        top: -180px;
        position: relative;
        flex-wrap: wrap; }
      #services-front .intro {
        text-align: left; }
      #services-front .service-images {
        position: relative;
        display: block;
        width: 60%;
        height: 434px; }
        #services-front .service-images > div {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          position: absolute;
          width: 440px;
          height: 434px;
          opacity: 0;
          right: 0rem; }
          #services-front .service-images > div.active {
            opacity: 1; }
        #services-front .service-images .service-image-main,
        #services-front .service-images .service-image-secondary {
          background-repeat: no-repeat;
          background-size: cover; }
        #services-front .service-images .service-image-main {
          position: absolute;
          z-index: 0;
          top: -120px;
          left: -300px;
          width: 600px;
          height: 600px; }
        #services-front .service-images .service-image-secondary {
          position: relative;
          z-index: 1;
          width: 75%;
          height: 75%;
          margin-left: 25%; }
      #services-front .service-text {
        width: 40%;
        display: none; }
        #services-front .service-text .service-listings li {
          margin-bottom: 2.985rem; }
        #services-front .service-text .service-listings a {
          position: relative;
          padding: 1.2935rem 1.99rem; } }
  @media (min-width: 1200px) {
    #services-front .service-images > div {
      right: 5.97rem; }
    #services-front .service-images .service-image-main {
      position: absolute;
      z-index: 0;
      top: -80px;
      left: -300px;
      width: 600px;
      height: 600px; }
    #services-front .service-images .service-image-secondary {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      margin-left: 0; }
    #services-front .service-images .color-accent {
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: absolute;
      top: 10.945rem;
      left: -7.96rem;
      width: 480px;
      height: 400px;
      background-color: #ff731a;
      -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      overflow: hidden;
      z-index: -1; }
      #services-front .service-images .color-accent:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/accent-facet-overlay.png");
        background-repeat: no-repeat;
        background-position: 240% 180%; }
    #services-front .service-text .service-listings .color-accent {
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: absolute;
      top: -1.99rem;
      left: -3.98rem;
      width: 60%;
      height: 150%;
      background-color: #ff731a;
      -webkit-clip-path: polygon(100% 0, 0 0, 0 98%);
      clip-path: polygon(100% 0, 0 0, 0 98%);
      overflow: hidden;
      opacity: 0; }
      #services-front .service-text .service-listings .color-accent:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/accent-facet-overlay.png");
        background-repeat: no-repeat;
        background-position: -420px -120px; }
    #services-front .service-text .service-listings .button {
      position: absolute;
      bottom: 20px;
      right: 40px;
      opacity: 0; }
      #services-front .service-text .service-listings .button span {
        display: none; }
      #services-front .service-text .service-listings .button:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        content: '...';
        color: #ffffff;
        background: linear-gradient(155deg, #f6b830 18%, #ff731a 40%, #ff731a 55%, #f6b830);
        height: 40px;
        width: 40px;
        text-align: center;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 1px;
        border-radius: 50%; }
    #services-front .service-text .service-listings li:hover .color-accent,
    #services-front .service-text .service-listings li.active .color-accent {
      left: -1.99rem;
      opacity: 1; }
    #services-front .service-text .service-listings li:hover a,
    #services-front .service-text .service-listings li.active a {
      background: #ffffff;
      box-shadow: 22px 22px 52px 0px rgba(0, 0, 0, 0.1); }
    #services-front .service-text .service-listings li:hover .button,
    #services-front .service-text .service-listings li.active .button {
      right: 80px;
      opacity: 1; } }

/**
 * TEK | HOME PORTFOLIO
 */
#portfolio-front {
  position: relative; }
  #portfolio-front .intro {
    z-index: 2; }
  #portfolio-front .slider-arrows {
    position: absolute;
    bottom: 7.96rem;
    left: 1.99rem;
    z-index: 2; }
    #portfolio-front .slider-arrows .slick-arrow {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #portfolio-front .slider-arrows .slick-arrow:after {
        display: none; }
      #portfolio-front .slider-arrows .slick-arrow.slick-prev {
        transform: rotate(-180deg);
        margin-right: 0.4975rem; }
      @media (min-width: 1024px) {
        #portfolio-front .slider-arrows .slick-arrow:hover.slick-next {
          -moz-transform: rotate(0deg) scale(1.1);
          -webkit-transform: rotate(0deg) scale(1.1);
          transform: rotate(0deg) scale(1.1); }
        #portfolio-front .slider-arrows .slick-arrow:hover.slick-prev {
          margin-right: 0.4975rem;
          -moz-transform: rotate(-180deg) scale(1.1);
          -webkit-transform: rotate(-180deg) scale(1.1);
          transform: rotate(-180deg) scale(1.1); } }
  #portfolio-front .portfolio-slider .slide-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 19.9rem 1.99rem 11.94rem; }
    @media (min-width: 640px) {
      #portfolio-front .portfolio-slider .slide-bg {
        padding: 19.9rem 1.99rem 5.97rem; } }
    #portfolio-front .portfolio-slider .slide-bg:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0, 18, 23, 0.35) 0%, rgba(0, 18, 23, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001217', endColorstr='#00001217',GradientType=0 ); }
    #portfolio-front .portfolio-slider .slide-bg h2, #portfolio-front .portfolio-slider .slide-bg p {
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36); }
    #portfolio-front .portfolio-slider .slide-bg h2 {
      color: #ffffff;
      font-size: 10vw;
      max-width: 500px; }
    #portfolio-front .portfolio-slider .slide-bg p {
      color: #ffffff;
      font-weight: 700;
      font-size: 0.93333rem;
      margin-top: 0rem; }
      #portfolio-front .portfolio-slider .slide-bg p span {
        color: #f6b830;
        font-size: 1.6rem;
        padding-right: 0.4975rem; }
  #portfolio-front .slant-container {
    margin-top: 0px;
    top: -50px; }
  @media (min-width: 640px) {
    #portfolio-front .intro {
      display: none; }
    #portfolio-front .slider-arrows {
      bottom: 9.95rem;
      left: 1.99rem; }
    #portfolio-front .portfolio-slider .slide-bg {
      padding: 14.925rem 1.99rem 15.92rem; }
      #portfolio-front .portfolio-slider .slide-bg h2 {
        display: block;
        font-size: 4rem;
        max-width: 500px; }
      #portfolio-front .portfolio-slider .slide-bg p {
        display: block; } }
  @media (min-width: 1024px) {
    #portfolio-front .slider-arrows {
      bottom: 13.93rem; }
    #portfolio-front .portfolio-slider .slide-bg {
      padding: 21.89rem 1.99rem 21.89rem; } }
  @media (min-width: 1200px) {
    #portfolio-front .slider-arrows {
      max-width: 1140px;
      left: 50%;
      margin-left: -580px; } }
  @media (min-width: 2000px) {
    #portfolio-front .slider-arrows {
      bottom: 13.93rem; }
    #portfolio-front .portfolio-slider .slide-bg {
      padding: 25.87rem 1.99rem 22.885rem; } }

/**
 * TEK | HOME SERVICES
 */
#tek-tips-front {
  position: relative;
  z-index: 1; }
  #tek-tips-front .tek-tips-logo {
    margin-bottom: 0.995rem; }
  #tek-tips-front header {
    margin-bottom: 3.98rem; }
    #tek-tips-front header > div {
      margin-bottom: 1.99rem; }
  @media (min-width: 1024px) {
    #tek-tips-front header {
      width: 50%;
      padding-right: 3.98rem; }
    #tek-tips-front .tooltip-container {
      position: absolute;
      top: -180px;
      right: -150px; }
      #tek-tips-front .tooltip-container:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/shape-purple-diamond.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 600px;
        height: 600px;
        z-index: -1;
        top: -100px;
        left: 300px; } }

/**
 * TEK | HOME SERVICES VIRTUAL EVENTS CTA
 */
.virtual-events-cta {
  position: relative;
  background: #fff;
  box-shadow: 22px 22px 52px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: block;
  margin-bottom: 4rem; }
  @media (min-width: 1024px) {
    .virtual-events-cta {
      width: 60%;
      right: 2.985rem;
      padding: 2.985rem;
      margin-bottom: initial; } }
  .virtual-events-cta h1 {
    text-align: center; }
  .virtual-events-cta h3 {
    text-align: center; }
  .virtual-events-cta p {
    font-size: 1.2rem;
    color: #0f0f0f; }
  .virtual-events-cta ul {
    list-style-type: initial;
    font-size: 1.3rem;
    padding: 0 1rem 0 4rem; }
  .virtual-events-cta .vid-thumb-containter {
    width: 70%;
    margin: auto; }
  .virtual-events-cta .vid-thumb {
    position: relative;
    cursor: pointer;
    margin: auto; }
    .virtual-events-cta .vid-thumb:before {
      background-color: rgba(0, 110, 136, 0.4);
      background-clip: content-box;
      z-index: 1;
      top: 0%; }
  .virtual-events-cta .virtual-events-cta-video .vid-modal {
    display: none; }
  .virtual-events-cta .rem-1 {
    padding: 1rem 0 1rem 0; }
  .virtual-events-cta .virtual-form-btn {
    top: initial; }

/**
 * TEK | HOME SERVICES CTA
 */
.cta {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1024px) {
    .cta {
      flex-shrink: 0;
      width: 120%; } }
  .cta h1 {
    width: 100%;
    margin-bottom: 1rem; }
    .cta h1 span {
      font-style: italic;
      color: #0281a6; }
  .cta h3 {
    text-align: center; }
  .cta p {
    font-size: 1.1rem;
    color: #ffffff; }
  .cta .cta-text {
    flex: 2;
    background: linear-gradient(180deg, #1f7fa9 0%, #114d8c 50%);
    padding: 0 2rem 2rem 2rem;
    margin: 0 0 2rem 0;
    flex-direction: column;
    display: flex; }
  .cta .cta-form-btn {
    display: flex;
    position: relative;
    top: 2rem;
    margin: auto; }
    @media (min-width: 1024px) {
      .cta .cta-form-btn {
        padding-left: 5rem;
        margin: initial; } }
  .cta .cta-vid-thumb-containter {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5rem; }
    @media (min-width: 1024px) {
      .cta .cta-vid-thumb-containter {
        flex: 1.2;
        width: 125%;
        transform: translateX(-50%); } }
  .cta .cta-video .vid-modal {
    display: none; }
  .cta .vid-thumb {
    position: relative;
    cursor: pointer;
    margin: auto; }

.cta-services-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: -5rem;
  margin-bottom: 8rem;
  flex-flow: row wrap;
  transition: all .2s ease-in-out;
  z-index: 2; }
  .cta-services-container.show {
    z-index: 1; }
  .cta-services-container .cta-services {
    transition: all .2s ease-in-out;
    position: relative;
    flex: 0 24%;
    min-width: 285px;
    transform: scale(1.2); }
    .cta-services-container .cta-services:hover {
      transform: scale(1.1); }
    @media (min-width: 1024px) {
      .cta-services-container .cta-services {
        min-width: 300px;
        transform: initial; } }
    @media (min-width: 1024px) {
      .cta-services-container .cta-services {
        min-width: initial; } }
    .cta-services-container .cta-services .cta-services-content {
      position: relative;
      padding: 8%;
      z-index: 2;
      margin-bottom: 15rem; }
      @media (min-width: 1024px) {
        .cta-services-container .cta-services .cta-services-content {
          margin-bottom: initial; } }
      .cta-services-container .cta-services .cta-services-content p {
        color: white; }
      .cta-services-container .cta-services .cta-services-content h2 {
        color: white;
        font-size: 2rem; }
    .cta-services-container .cta-services img {
      position: absolute;
      object-fit: cover;
      top: 0%;
      z-index: 0; }

/**
 * TEK | SERVICES
 */
.subpages-menu-mobile-toggler {
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  border: #000000 solid 3px;
  font-size: 1.6rem;
  width: 250px;
  margin: 2rem auto 1rem; }
  .subpages-menu-mobile-toggler:hover {
    color: #ff731a;
    border-color: #ff731a; }
  @media screen and (min-width: 768px) {
    .subpages-menu-mobile-toggler {
      display: none; } }

.subpages-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto; }
  .subpages-menu .page_item {
    flex: 1;
    width: 100%;
    max-width: 250px;
    padding: 0.995rem 0.4975rem; }
    .subpages-menu .page_item.page-item-31 .angled {
      /*
      background: linear-gradient( 115deg, $green-dark 0%, $green-darker 100% );
      @TODO: add light white gradient to button after pseudo element
      */
      background: #006e88;
      color: #006e88; }
    .subpages-menu .page_item.page-item-37 .angled {
      background: #c14a19;
      color: #c14a19; }
    .subpages-menu .page_item.page-item-39 .angled {
      background: #699e22;
      color: #699e22; }
    .subpages-menu .page_item.page-item-35 .angled {
      background: #534e81;
      color: #534e81; }
    .subpages-menu .page_item.current_page_item .angled {
      color: #ffffff; }
    .subpages-menu .page_item.current_page_item .angled:before, .subpages-menu .page_item.current_page_item .angled:after {
      background: none;
      background-color: transparent; }
    .subpages-menu .page_item .angled {
      /*button*/
      width: 100%;
      color: #000000;
      text-transform: none;
      font-weight: 600;
      font-size: 1.2rem;
      background: #000; }
      .subpages-menu .page_item .angled:before, .subpages-menu .page_item .angled:after {
        background: #fff;
        margin: 0.13333rem;
        width: auto;
        height: auto;
        -webkit-clip-path: polygon(1px 11px, 66px 1px, calc(100% - 1px) 11px, calc(100% - 1px) calc(100% - 11px), calc(100% - 66px) calc(100% - 1px), 1px calc(100% - 11px)) !important;
        clip-path: polygon(1px 11px, 66px 1px, calc(100% - 1px) 11px, calc(100% - 1px) calc(100% - 11px), calc(100% - 66px) calc(100% - 1px), 1px calc(100% - 11px)) !important; }
      .subpages-menu .page_item .angled:hover {
        color: #ffffff; }
      .subpages-menu .page_item .angled:hover:before, .subpages-menu .page_item .angled:hover:after {
        background: none;
        background-color: transparent; }
      .subpages-menu .page_item .angled span {
        display: inline-block;
        margin: 0 0.66667rem 0 0;
        width: 1.33333rem;
        height: 1.33333rem;
        position: relative;
        top: 0.26667rem;
        background-size: contain !important;
        background-repeat: no-repeat !important; }
        .subpages-menu .page_item .angled span svg {
          width: 1.33333rem;
          height: 1.33333rem;
          fill: currentColor; }
  @media (min-width: 0px) {
    .subpages-menu {
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      height: 0px; }
      .subpages-menu > .page_item {
        width: 250px; } }
  @media screen and (min-width: 768px) {
    .subpages-menu {
      max-width: 100%;
      height: auto;
      flex-direction: row; }
      .subpages-menu > .page_item {
        display: list-item;
        padding: 1.99rem 0.199rem; }
        .subpages-menu > .page_item a {
          white-space: nowrap; } }
  @media (min-width: 1024px) {
    .subpages-menu {
      max-width: 85%; }
      .subpages-menu > .page_item {
        display: list-item;
        padding: 1.99rem 0.4975rem; } }

.services-container > h1 {
  text-align: center;
  padding-bottom: 2.66667rem; }

#services-intro {
  text-align: center;
  margin-bottom: 4rem; }
  @media (min-width: 640px) {
    #services-intro {
      margin-bottom: 1rem; } }

#services-description {
  position: relative;
  z-index: 2; }
  #services-description .service-logo-container {
    text-align: center; }
    #services-description .service-logo-container img {
      width: 100%;
      max-width: 280px; }
  #services-description .description_container {
    display: flex;
    flex-flow: row nowrap; }
    #services-description .description_container .col {
      padding: 1rem;
      flex: 1; }
      #services-description .description_container .col > div {
        text-align: center; }
        @media (min-width: 640px) {
          #services-description .description_container .col > div {
            text-align: left; } }
      #services-description .description_container .col h3 {
        padding-top: 2rem; }
    #services-description .description_container .col:nth-child(1) img {
      max-width: 80%; }
    #services-description .description_container .col:nth-child(2) {
      margin-left: 3.33333rem; }
    #services-description .description_container .description-image {
      position: relative; }
      #services-description .description_container .description-image .vid-thumb {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 0;
        padding: 6.66667rem;
        cursor: pointer; }
        #services-description .description_container .description-image .vid-thumb:before, #services-description .description_container .description-image .vid-thumb:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%; }
        #services-description .description_container .description-image .vid-thumb:before {
          background-color: rgba(0, 110, 136, 0.4);
          background-clip: content-box;
          z-index: 1;
          top: 0%;
          padding: 6.66667rem; }
        #services-description .description_container .description-image .vid-thumb:after {
          content: "";
          font-family: "FontAwesome";
          font-weight: 400;
          z-index: 2;
          font-size: 3.2rem;
          color: #ffffff;
          margin: auto;
          width: auto;
          height: auto;
          padding: 6.66667rem;
          box-sizing: padding-box;
          left: 25%;
          top: 25%;
          margin-left: -1.6rem; }
      #services-description .description_container .description-image:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/general-image-overlay-faceting-effect.png");
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100%; }
      #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger {
        top: 0px;
        left: 0px;
        width: 500px;
        z-index: 0;
        -webkit-backface-visibility: hidden; }
        #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger:hover {
          z-index: 3; }
        #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger .tooltip-circle {
          position: absolute;
          top: -15px;
          left: -15px; }
        #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          background-color: #ffffff;
          box-shadow: 22px 22px 52px 0 rgba(0, 0, 0, 0.1);
          padding: 2rem 3rem;
          z-index: 1; }
          #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup img {
            width: 40%;
            margin-bottom: 1rem; }
          #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup h4 {
            color: #000000;
            margin-bottom: 1rem; }
          #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup p {
            margin-bottom: 1rem; }
          #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow {
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            color: #0281a6; }
            #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow span {
              background-image: url(../images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
              background-repeat: no-repeat;
              background-position: 50%;
              border-radius: 50%; }
            #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow:hover {
              color: #ff731a; }
      #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger.closed > section.tips-popup {
        opacity: 0; }
      #services-description .description_container .description-image > div.tooltip-container .tooltip-trigger.open > section.tips-popup {
        opacity: 1; }
    #services-description .description_container .description-video .vid-modal {
      display: none; }
    @media (min-width: 0px) {
      #services-description .description_container {
        display: block; }
        #services-description .description_container div.col:nth-child(2) {
          margin-left: 0;
          margin-top: 3rem; }
        #services-description .description_container .description-image {
          max-width: 50%;
          margin: auto; }
          #services-description .description_container .description-image > img, #services-description .description_container .description-image:before {
            display: none; }
          #services-description .description_container .description-image .vid-thumb {
            position: static;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            padding: 0; }
            #services-description .description_container .description-image .vid-thumb:before, #services-description .description_container .description-image .vid-thumb:after {
              padding: 0; }
            #services-description .description_container .description-image .vid-thumb:after {
              left: 50%;
              top: 50%; } }
    @media (min-width: 1024px) {
      #services-description .description_container {
        display: flex; }
        #services-description .description_container div.col:nth-child(2) {
          margin-left: 3.33333rem; }
        #services-description .description_container .description-image {
          max-width: inherit; }
          #services-description .description_container .description-image > img, #services-description .description_container .description-image:before {
            display: block; }
          #services-description .description_container .description-image .vid-thumb {
            position: absolute;
            top: 50%;
            transform: translateX(-45%) translateY(-50%);
            line-height: 0;
            padding: 6.66667rem; }
            #services-description .description_container .description-image .vid-thumb:before, #services-description .description_container .description-image .vid-thumb:after {
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
              width: 100%;
              height: 100%; }
            #services-description .description_container .description-image .vid-thumb:before {
              background-color: rgba(0, 110, 136, 0.4);
              background-clip: content-box;
              z-index: 1;
              top: 0%;
              padding: 6.66667rem; }
            #services-description .description_container .description-image .vid-thumb:after {
              content: "";
              font-family: "FontAwesome";
              font-weight: 400;
              z-index: 2;
              font-size: 3.2rem;
              color: #ffffff;
              margin: auto;
              width: auto;
              height: auto;
              padding: 6.66667rem;
              box-sizing: padding-box;
              left: 25%;
              top: 25%;
              margin-left: -1.6rem; } }

#services-overview {
  position: relative; }
  #services-overview .services-container .slant-container {
    z-index: 1;
    top: -300px;
    margin-bottom: -200px; }
    @media (min-width: 0px) {
      #services-overview .services-container .slant-container {
        top: -50px;
        margin-bottom: 6.66667rem; } }
    @media (min-width: 1024px) {
      #services-overview .services-container .slant-container {
        top: -300px;
        margin-bottom: -200px; } }
    #services-overview .services-container .slant-container .accent-wrapper {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      z-index: 3;
      top: 250px;
      width: 100%;
      max-width: 975px;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 0px) {
        #services-overview .services-container .slant-container .accent-wrapper {
          display: none; } }
      @media (min-width: 1024px) {
        #services-overview .services-container .slant-container .accent-wrapper {
          display: flex; } }
      #services-overview .services-container .slant-container .accent-wrapper > div {
        flex: 1;
        position: relative;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        #services-overview .services-container .slant-container .accent-wrapper > div:last-child {
          pointer-events: none; }
        #services-overview .services-container .slant-container .accent-wrapper > div .color-accent {
          position: absolute;
          z-index: -1;
          bottom: -11.94rem;
          left: -1.99rem;
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #services-overview .services-container .slant-container .accent-wrapper > div .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
        #services-overview .services-container .slant-container .accent-wrapper > div:hover {
          transform: translate(-1%, 1%); }
          #services-overview .services-container .slant-container .accent-wrapper > div:hover .color-accent {
            bottom: -12.338rem;
            left: -2.388rem; }
      #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger {
        top: 0px;
        left: 0px;
        width: 500px;
        z-index: 0;
        -webkit-backface-visibility: hidden; }
        #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger:hover {
          z-index: 3; }
        #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger .tooltip-circle {
          position: absolute;
          top: -15px;
          left: -15px; }
        #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          background-color: #ffffff;
          box-shadow: 22px 22px 52px 0 rgba(0, 0, 0, 0.1);
          padding: 2rem 3rem;
          z-index: 1; }
          #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup img {
            width: 40%;
            margin-bottom: 1rem; }
          #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup h4 {
            color: #000000;
            margin-bottom: 1rem; }
          #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup p {
            margin-bottom: 1rem; }
          #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow {
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            color: #0281a6; }
            #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow span {
              background-image: url(../images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
              background-repeat: no-repeat;
              background-position: 50%;
              border-radius: 50%; }
            #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow:hover {
              color: #ff731a; }
      #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger.closed > section.tips-popup {
        opacity: 0; }
      #services-overview .services-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger.open > section.tips-popup {
        opacity: 1; }
    #services-overview .services-container .slant-container .slant .tek-overlay {
      display: none;
      opacity: 0.5;
      top: 25%;
      left: 50%;
      width: 2142px;
      height: 800px; }
    #services-overview .services-container .slant-container .slant > div:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url("../images/overview-facet-overlay@2x.png");
      background-repeat: no-repeat;
      background-position: 20% 50%;
      height: 110%;
      background-blend-mode: multiply;
      mix-blend-mode: multiply;
      z-index: 1 !important; }
    #services-overview .services-container .slant-container .slant > div > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: -25%;
      left: 0px;
      height: 150%; }
    #services-overview .services-container .slant-container .slant .wrap {
      color: #ffffff;
      min-height: 26.66667rem;
      display: flex;
      flex-flow: row nowrap;
      z-index: 2; }
      #services-overview .services-container .slant-container .slant .wrap .col {
        padding: 0 0.33333rem;
        flex: 1; }
      #services-overview .services-container .slant-container .slant .wrap .col:nth-child(2) {
        margin-left: 6.66667rem; }
      #services-overview .services-container .slant-container .slant .wrap h1 {
        padding-bottom: 1.33333rem; }
      @media (min-width: 0px) {
        #services-overview .services-container .slant-container .slant .wrap {
          flex-flow: row wrap; }
          #services-overview .services-container .slant-container .slant .wrap h1 {
            text-align: center; }
          #services-overview .services-container .slant-container .slant .wrap .col {
            flex: 1 100%; }
          #services-overview .services-container .slant-container .slant .wrap .col:nth-child(2) {
            margin-left: 0; } }
      @media (min-width: 1024px) {
        #services-overview .services-container .slant-container .slant .wrap {
          flex-flow: row nowrap; }
          #services-overview .services-container .slant-container .slant .wrap h1 {
            text-align: left; }
          #services-overview .services-container .slant-container .slant .wrap .col:nth-child(2) {
            margin-left: 6.66667rem; } }
      #services-overview .services-container .slant-container .slant .wrap .overview-list {
        display: flex;
        flex-flow: row nowrap;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 6.66667rem;
        padding-left: 1.99rem; }
        #services-overview .services-container .slant-container .slant .wrap .overview-list li {
          position: relative;
          padding-left: 2rem;
          /*&:after {
			top: 10px;
			left: 5px;
			width: 6px;
			height: 6px;
			background: $white;
		}*/ }
          #services-overview .services-container .slant-container .slant .wrap .overview-list li:before, #services-overview .services-container .slant-container .slant .wrap .overview-list li:after {
            content: '';
            border-radius: 50%;
            display: inline-block;
            position: absolute; }
          #services-overview .services-container .slant-container .slant .wrap .overview-list li:before {
            top: 5px;
            left: 0;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: solid #f6b830 5px;
            background: linear-gradient(to top, #ff731a, #f6b830) 1 100%;
            clip-path: circle(50% at 50% 50%); }
        #services-overview .services-container .slant-container .slant .wrap .overview-list ul li {
          padding-left: 1.32667rem; }
          #services-overview .services-container .slant-container .slant .wrap .overview-list ul li:before, #services-overview .services-container .slant-container .slant .wrap .overview-list ul li:after {
            width: auto;
            height: auto;
            top: auto;
            background: none; }
          #services-overview .services-container .slant-container .slant .wrap .overview-list ul li:before {
            content: '-';
            color: #ff731a;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.5; }
        #services-overview .services-container .slant-container .slant .wrap .overview-list li {
          flex: 1 0 auto;
          line-height: 1.75em;
          padding-right: 0.13333rem;
          padding-bottom: 0.53333rem; }
        @media (min-width: 0px) {
          #services-overview .services-container .slant-container .slant .wrap .overview-list li {
            flex: 1 100%;
            margin-left: 0.66667rem; } }
        @media (min-width: 640px) {
          #services-overview .services-container .slant-container .slant .wrap .overview-list li {
            flex: 1 50%;
            max-width: 50%;
            margin-left: 0; } }
        @media (min-width: 640px) {
          #services-overview .services-container .slant-container .slant .wrap .overview-list {
            padding-left: 0; } }
    @media (min-width: 0px) {
      #services-overview .services-container .slant-container .slant > div {
        padding: 9.95rem 0 1.99rem; } }
    @media (min-width: 640px) {
      #services-overview .services-container .slant-container .slant > div {
        padding: 7.96rem 0 1.99rem; } }
    @media (min-width: 1024px) {
      #services-overview .services-container .slant-container .slant > div {
        padding: 11.94rem 0 3.98rem; } }
    @media (min-width: 1200px) {
      #services-overview .services-container .slant-container .slant > div {
        padding: 15.92rem 0 5.97rem; } }
    @media (min-width: 2000px) {
      #services-overview .services-container .slant-container .slant > div > img {
        top: 0; }
      #services-overview .services-container .slant-container .slant > div {
        padding: 19.9rem 0 9.95rem; } }
    @media (min-width: 1200px) {
      #services-overview .services-container .slant-container .slant .tek-overlay {
        display: block; }
      #services-overview .services-container .slant-container .slant > div:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-position: 0% 20%;
        background-size: cover;
        height: 125%; } }
  #services-overview .overview-gallery {
    position: relative;
    z-index: 2; }
  #services-overview .slider-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2.93333rem;
    left: 0px;
    z-index: 2; }
    #services-overview .slider-arrows .slick-arrow {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #services-overview .slider-arrows .slick-arrow:after {
        display: none; }
      #services-overview .slider-arrows .slick-arrow.slick-prev {
        transform: rotate(-180deg); }
      #services-overview .slider-arrows .slick-arrow.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #services-overview .slider-arrows .slick-arrow:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #services-overview .slider-arrows .slick-arrow:hover.slick-prev {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #services-overview .slider-arrows-P {
    position: absolute;
    top: 50%;
    margin-top: -2.93333rem;
    left: 0px;
    z-index: 2; }
    #services-overview .slider-arrows-P .slick-arrow-P {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #services-overview .slider-arrows-P .slick-arrow-P:after {
        display: none; }
      #services-overview .slider-arrows-P .slick-arrow-P.slick-prev-P {
        transform: rotate(-180deg); }
      #services-overview .slider-arrows-P .slick-arrow-P.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #services-overview .slider-arrows-P .slick-arrow-P:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #services-overview .slider-arrows-P .slick-arrow-P:hover.slick-prev-P {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #services-overview .slider-arrows-N {
    position: absolute;
    top: 50%;
    margin-top: -2.93333rem;
    right: 0px;
    z-index: 2; }
    #services-overview .slider-arrows-N .slick-arrow-N {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #services-overview .slider-arrows-N .slick-arrow-N:after {
        display: none; }
      #services-overview .slider-arrows-N .slick-arrow-N.slick-prev {
        transform: rotate(-180deg); }
      #services-overview .slider-arrows-N .slick-arrow-N.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #services-overview .slider-arrows-N .slick-arrow-N:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #services-overview .slider-arrows-N .slick-arrow-N:hover.slick-prev {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #services-overview .services-slider .gallery-wrapper {
    padding: 0.4975rem; }
  #services-overview .services-slider .slide-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 9.95rem; }
    #services-overview .services-slider .slide-bg:before {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: 0;
      content: ""; }
    #services-overview .services-slider .slide-bg h2, #services-overview .services-slider .slide-bg p {
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36); }
    #services-overview .services-slider .slide-bg h2 {
      display: none;
      color: #ffffff; }
    #services-overview .services-slider .slide-bg p {
      color: #ffffff;
      font-weight: 700;
      font-size: 0.93333rem;
      margin-top: 0rem; }
      #services-overview .services-slider .slide-bg p span {
        color: #f6b830;
        font-size: 1.6rem;
        padding-right: 0.4975rem; }

.vid-modal video {
  width: 95vw;
  height: auto; }
  @media (min-width: 640px) {
    .vid-modal video {
      width: 75vw;
      height: auto; } }
  @media (min-width: 1200px) {
    .vid-modal video {
      width: 50vw;
      height: auto; } }

.slick-lightbox .slick-prev.slick-arrow {
  position: absolute;
  top: 40%;
  margin-top: 2.9rem;
  left: 0;
  z-index: 1;
  background-color: #ff731a;
  text-indent: -999px;
  background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
  background-position: 50%;
  background-repeat: no-repeat;
  transform: rotate(-180deg);
  display: none !important; }
  @media (min-width: 1024px) {
    .slick-lightbox .slick-prev.slick-arrow {
      display: inherit !important; } }

.slick-lightbox .slick-prev.slick-arrow::after {
  background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
  background-repeat: no-repeat;
  background-position: 25%;
  width: 0;
  height: 100%;
  display: none !important; }
  @media (min-width: 1024px) {
    .slick-lightbox .slick-prev.slick-arrow::after {
      display: inherit !important; } }

.slick-lightbox .slick-next.slick-arrow {
  position: absolute;
  top: 40%;
  margin-top: 2.9rem;
  right: 0;
  z-index: 1;
  background-color: #ff731a;
  text-indent: -999px;
  background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
  background-position: 50%;
  background-repeat: no-repeat;
  display: none !important; }
  @media (min-width: 1024px) {
    .slick-lightbox .slick-next.slick-arrow {
      display: inherit !important; } }

.slick-lightbox .slick-next.slick-arrow::after {
  background-image: url(https://tekstaging.wpengine.com/wp-content/themes/tek-productions/assets/images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
  background-repeat: no-repeat;
  background-position: 25%;
  width: 0;
  height: 100%;
  display: none !important; }
  @media (min-width: 1024px) {
    .slick-lightbox .slick-next.slick-arrow::after {
      display: inherit !important; } }

.slick-lightbox .slick-lightbox-close {
  display: none !important; }

@media (min-width: 1024px) {
  display: inherit !important; }

.slick-lightbox .slick-lightbox-close::after {
  display: none !important; }

@media (min-width: 1024px) {
  display: inherit !important; }

/**
 * TEK | TIPS
 */
#tips-intro {
  position: relative;
  z-index: 2; }
  #tips-intro .accent-wrapper {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    z-index: 3;
    top: 0px;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 0px) {
      #tips-intro .accent-wrapper {
        display: none; } }
    @media (min-width: 1024px) {
      #tips-intro .accent-wrapper {
        display: flex; } }
    #tips-intro .accent-wrapper > div {
      flex: 1;
      position: relative; }
      #tips-intro .accent-wrapper > div.intro-image {
        position: relative;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        #tips-intro .accent-wrapper > div.intro-image .color-accent {
          position: absolute;
          z-index: -1;
          bottom: -11.94rem;
          left: -1.99rem;
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #tips-intro .accent-wrapper > div.intro-image .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
        #tips-intro .accent-wrapper > div.intro-image:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%; }
        #tips-intro .accent-wrapper > div.intro-image:hover {
          -moz-transform: translate(-1%, 1%);
          -webkit-transform: translate(-1%, 1%);
          transform: translate(-1%, 1%); }
          #tips-intro .accent-wrapper > div.intro-image:hover .color-accent {
            bottom: -12.338rem;
            left: -2.388rem; }
    #tips-intro .accent-wrapper .intro-video .vid-modal {
      display: none; }
  #tips-intro .intro_container {
    display: flex;
    flex-flow: row nowrap; }
    #tips-intro .intro_container .col {
      padding: 1rem;
      flex: 1; }
      #tips-intro .intro_container .col .tek-tips-logo img {
        min-width: 256px; }
        @media (min-width: 640px) {
          #tips-intro .intro_container .col .tek-tips-logo img {
            width: 350px; } }
      #tips-intro .intro_container .col h3 {
        padding-top: 2rem; }
    #tips-intro .intro_container .col:nth-child(2) {
      margin-left: 3.33333rem; }
    @media (min-width: 0px) {
      #tips-intro .intro_container {
        display: block; }
        #tips-intro .intro_container > div {
          display: block; }
        #tips-intro .intro_container .col:nth-child(2) {
          margin-left: 0; } }
    @media (min-width: 1024px) {
      #tips-intro .intro_container {
        display: flex; }
        #tips-intro .intro_container .col:nth-child(2) {
          margin-left: 3.33333rem; } }

#tips-resources {
  position: relative;
  margin-bottom: 14rem; }
  @media (min-width: 1024px) {
    #tips-resources {
      margin-bottom: 0; } }
  #tips-resources .tips-container {
    position: relative; }
    #tips-resources .tips-container .patch {
      width: 100%;
      height: 700px;
      position: absolute;
      bottom: -100px;
      background-color: #f5f5f5; }
    #tips-resources .tips-container .slant-container {
      top: -250px;
      margin-bottom: 0; }
      @media (min-width: 0px) {
        #tips-resources .tips-container .slant-container {
          top: 0; } }
      @media (min-width: 1024px) {
        #tips-resources .tips-container .slant-container {
          top: -150px; } }
      #tips-resources .tips-container .slant-container .dual-slant-overlay.reverse {
        transform: translate(0, -36px) rotate(-8deg); }
      #tips-resources .tips-container .slant-container .slant {
        padding: 10rem 6.66667rem 1.66667rem;
        background: #f6b830; }
        #tips-resources .tips-container .slant-container .slant > div {
          padding: 3.98rem 0 11.94rem; }
          #tips-resources .tips-container .slant-container .slant > div:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 0% 50%;
            height: 110%;
            mix-blend-mode: multiply; }
            @media (min-width: 1200px) {
              #tips-resources .tips-container .slant-container .slant > div:before {
                background-image: url("../images/resources-facet-overlay@2x.png"); } }
        #tips-resources .tips-container .slant-container .slant > div > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -2;
          top: -25%;
          left: 0px;
          height: 150%; }
        #tips-resources .tips-container .slant-container .slant h2 {
          color: #ffffff;
          padding-bottom: 1.33333rem;
          padding-right: 2rem;
          padding-left: 2rem;
          text-align: center;
          flex: 1 1 100%;
          z-index: 2;
          width: 80%;
          margin: 0 auto; }
          @media (min-width: 640px) {
            #tips-resources .tips-container .slant-container .slant h2 {
              width: 100%; } }
        #tips-resources .tips-container .slant-container .slant .tips_slider .slick-slide:not(.slick-active):before {
          visibility: hidden; }
        #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow {
          position: relative;
          background-image: url("../images/arrow-right-icon-white.png");
          background-repeat: no-repeat;
          background-position: center center;
          text-indent: -9999px;
          border-radius: 0px;
          background-color: transparent; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            content: "";
            font-family: "FontAwesome";
            font-weight: 400;
            color: #ffffff;
            text-align: center; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow:after {
            display: none; }
          @media (min-width: 640px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow {
              position: absolute;
              z-index: 2;
              top: 50%; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow.slick-prev {
                margin-left: 0;
                left: -50px; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow.slick-next {
                left: initial;
                top: 50%;
                right: -50px; } }
          @media (min-width: 1024px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow:hover.slick-next {
              -moz-transform: rotate(0deg) scale(1.1);
              -webkit-transform: rotate(0deg) scale(1.1);
              transform: rotate(0deg) scale(1.1); }
            #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow:hover.slick-prev {
              margin-right: 0.4975rem;
              -moz-transform: rotate(-180deg) scale(1.1);
              -webkit-transform: rotate(-180deg) scale(1.1);
              transform: rotate(-180deg) scale(1.1); } }
          @media (min-width: 1200px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow.slick-prev {
              left: -100px; }
            #tips-resources .tips-container .slant-container .slant .tips_slider .slick-arrow.slick-next {
              right: -100px; } }
        #tips-resources .tips-container .slant-container .slant .tips_slider .slick-prev {
          transform: rotate(-180deg);
          margin-left: 30%; }
        #tips-resources .tips-container .slant-container .slant .tips_slider .slick-next {
          position: absolute;
          left: 55%;
          top: 0;
          z-index: 2; }
        @media (min-width: 640px) {
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-prev {
            margin-left: 10%; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-next {
            left: 20%; } }
        @media (min-width: 1024px) {
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-prev {
            margin-left: 5px; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .slick-next {
            left: 8%; } }
        #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper {
          color: #ffffff;
          display: flex;
          flex-flow: row nowrap;
          flex-flow: row wrap;
          max-width: 945px;
          margin: 0 auto;
          justify-content: center; }
          @media (min-width: 1024px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper {
              justify-content: flex-start; } }
          @media (min-width: 0px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper > * {
              width: 70%; } }
          @media (min-width: 460px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper > * {
              width: 51%; } }
          @media (min-width: 640px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper > * {
              width: 50%; } }
          @media (min-width: 1024px) {
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper > * {
              width: 33%; } }
          #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col {
            padding: 0.33333rem;
            position: relative;
            background-color: #ffffff;
            background-clip: content-box; }
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box {
              color: #000000;
              background: #ffffff;
              height: 100%;
              padding: 1.33333rem;
              margin-bottom: 1.66667rem;
              line-height: 2;
              clear: both; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box .vid-modal {
                display: none; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box div.buttons-placeholder {
                visibility: hidden;
                float: left;
                width: 260px;
                height: 50px;
                margin-bottom: 1rem; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box div.buttons {
                position: absolute;
                bottom: 1rem; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box h4 {
                color: #000000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper .col .tip-box p {
                margin: 1rem 0; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper h2, #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav {
            padding-bottom: 1.33333rem;
            padding-right: 2rem;
            padding-left: 2rem;
            text-align: center;
            flex: 1 100%; }
          #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav {
            margin-top: 1.33333rem; }
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav span.current {
              text-decoration: underline;
              font-weight: 600; }
            #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav a {
              color: #ffffff; }
              #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav a:visited, #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav a:active, #tips-resources .tips-container .slant-container .slant .tips_slider .tips-wrapper nav a:hover {
                color: #c14a19; }
        @media (min-width: 0px) {
          #tips-resources .tips-container .slant-container .slant {
            width: 200%;
            left: -50%;
            padding: 0 0 6.66667rem; }
            #tips-resources .tips-container .slant-container .slant > div {
              padding: 1.99rem 1.99rem 1.99rem; }
            #tips-resources .tips-container .slant-container .slant .wrap {
              padding: 0 4rem 0 3.33333rem;
              justify-content: center; } }
        @media (min-width: 640px) {
          #tips-resources .tips-container .slant-container .slant {
            width: 130%;
            left: -15%; }
            #tips-resources .tips-container .slant-container .slant > div {
              padding: 3.98rem 0 1.99rem; } }
        @media (min-width: 1024px) {
          #tips-resources .tips-container .slant-container .slant {
            width: 130%;
            left: -15%;
            padding: 6.66667rem 0 8rem; }
            #tips-resources .tips-container .slant-container .slant > div {
              padding: 3.98rem 0 3.98rem; }
            #tips-resources .tips-container .slant-container .slant .wrap {
              padding: 0;
              justify-content: flex-start; } }
        @media (min-width: 1200px) {
          #tips-resources .tips-container .slant-container .slant > div {
            padding: 3.98rem 0 5.97rem; } }
        @media (min-width: 2000px) {
          #tips-resources .tips-container .slant-container .slant > div > img {
            top: 0; }
          #tips-resources .tips-container .slant-container .slant > div {
            padding: 7.96rem 0 9.95rem; } }
      @media (min-width: 1200px) {
        #tips-resources .tips-container .slant-container .slant .tek-overlay {
          display: block; }
        #tips-resources .tips-container .slant-container .slant > div:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-position: 0% 20%;
          background-size: cover;
          height: 200%; } }

#tips-glossary {
  position: relative;
  z-index: 1;
  background: #f5f5f5;
  margin-top: -350px;
  padding-bottom: 5.33333rem; }
  #tips-glossary h1 {
    text-align: center;
    margin-bottom: 2.66667rem; }
  @media (min-width: 0px) {
    #tips-glossary {
      margin-top: -150px; } }
  @media (min-width: 1024px) {
    #tips-glossary {
      margin-top: 0; } }
  #tips-glossary .glossary_container {
    display: flex;
    flex-flow: row nowrap; }
    #tips-glossary .glossary_container .progress {
      display: block;
      text-align: center;
      width: 0;
      height: 3px;
      background: #f5f5f5;
      transition: width .3s; }
    #tips-glossary .glossary_container .progress.hide {
      opacity: 0;
      transition: opacity 1.3s; }
    #tips-glossary .glossary_container .col {
      padding: 1rem;
      flex: 1; }
      #tips-glossary .glossary_container .col > dl {
        max-height: 60vh;
        overflow-y: scroll; }
      #tips-glossary .glossary_container .col dl {
        position: relative;
        margin: 0;
        padding: 0; }
        #tips-glossary .glossary_container .col dl dt {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 800;
          font-size: 2.4rem; }
        #tips-glossary .glossary_container .col dl dd.group {
          background: #fafafa;
          margin: 0.66667rem 0;
          padding: 1.33333rem;
          border-radius: 0.33333rem; }
          #tips-glossary .glossary_container .col dl dd.group:hover {
            background: #ffffff; }
        #tips-glossary .glossary_container .col dl dd a {
          display: block;
          margin-left: 1.33333rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #0281a6;
          font-weight: 600; }
        #tips-glossary .glossary_container .col dl dd.active {
          position: relative; }
          #tips-glossary .glossary_container .col dl dd.active a {
            color: #f6b830;
            position: relative; }
          #tips-glossary .glossary_container .col dl dd.active:before, #tips-glossary .glossary_container .col dl dd.active:after {
            content: '';
            border-radius: 50%;
            display: inline-block;
            position: absolute; }
          #tips-glossary .glossary_container .col dl dd.active:before {
            top: 5px;
            left: 0;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: solid #f6b830 5px;
            background: linear-gradient(to top, #ff731a, #f6b830) 1 100%;
            clip-path: circle(50% at 50% 50%); }
    #tips-glossary .glossary_container .col:nth-child(2) {
      margin-left: 0.06667rem;
      margin-top: 1.66667rem;
      flex: 2;
      padding: 3.33333rem;
      border-radius: 0.33333rem;
      background: #ffffff;
      -webkit-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      -moz-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1); }
      #tips-glossary .glossary_container .col:nth-child(2) h4 {
        color: #000000;
        margin-bottom: 1.66667rem; }
      #tips-glossary .glossary_container .col:nth-child(2) div.item_image {
        margin-top: 1rem; }
      #tips-glossary .glossary_container .col:nth-child(2) p.definition {
        color: #0281a6;
        font-weight: 600; }
      @media (min-width: 1024px) {
        #tips-glossary .glossary_container .col:nth-child(2) .details {
          clear: both; } }
      #tips-glossary .glossary_container .col:nth-child(2) blockquote {
        border: solid 3px #f5f5f5;
        border-radius: 0.33333rem;
        margin: 3.33333rem 0;
        padding: 6.66667rem 1.33333rem 1.33333rem;
        position: relative; }
        #tips-glossary .glossary_container .col:nth-child(2) blockquote:before {
          content: "";
          font-family: "FontAwesome";
          font-weight: 400;
          font-size: 4.8rem;
          position: absolute;
          left: 50%;
          top: 50px;
          transform: translateY(-50%);
          margin: 0;
          margin-left: -40px;
          line-height: 4.8rem;
          padding-left: 0.33333rem;
          overflow: hidden;
          background: -webkit-gradient(linear, left top, left bottom, from(#ff731a), to(#f6b830));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; }
        @media (min-width: 1024px) {
          #tips-glossary .glossary_container .col:nth-child(2) blockquote {
            padding: 1.33333rem 1.33333rem 1.33333rem 6.66667rem; }
            #tips-glossary .glossary_container .col:nth-child(2) blockquote:before {
              left: 1.33333rem;
              top: 50%;
              margin: 0; } }
      #tips-glossary .glossary_container .col:nth-child(2) a.download_link {
        font-weight: 800;
        color: #0281a6;
        position: relative;
        display: block;
        padding-left: 2rem;
        margin-top: 1.33333rem; }
        #tips-glossary .glossary_container .col:nth-child(2) a.download_link:before {
          content: "";
          font-family: "FontAwesome";
          font-weight: 400;
          font-size: 1.6rem;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          line-height: 1.66667rem;
          padding-left: 0.33333rem;
          overflow: hidden;
          background: -webkit-gradient(linear, left top, left bottom, from(#ff731a), to(#f6b830));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: initial; }
    @media (min-width: 0px) {
      #tips-glossary .glossary_container {
        display: block; }
        #tips-glossary .glossary_container .col > dl {
          max-height: 26vh;
          overflow-y: scroll; } }
    @media (min-width: 640px) {
      #tips-glossary .glossary_container {
        display: flex; }
        #tips-glossary .glossary_container .col > dl {
          max-height: 60vh;
          overflow-y: scroll; } }

.vid-modal video {
  width: 95vw;
  height: auto; }
  @media (min-width: 640px) {
    .vid-modal video {
      width: 75vw;
      height: auto; } }
  @media (min-width: 1200px) {
    .vid-modal video {
      width: 50vw;
      height: auto; } }

/**
 * TEK | About
 */
#about-intro {
  position: relative;
  z-index: 2; }
  #about-intro .accent-wrapper {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    z-index: 3;
    top: 0px;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 0px) {
      #about-intro .accent-wrapper {
        display: none; }
        #about-intro .accent-wrapper > div {
          display: block; } }
    @media (min-width: 1024px) {
      #about-intro .accent-wrapper {
        display: flex; } }
    #about-intro .accent-wrapper > div {
      flex: 1;
      position: relative; }
      #about-intro .accent-wrapper > div.intro-image {
        position: relative;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        #about-intro .accent-wrapper > div.intro-image .color-accent {
          position: absolute;
          z-index: -1;
          bottom: -11.94rem;
          left: -1.99rem;
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #about-intro .accent-wrapper > div.intro-image .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
        #about-intro .accent-wrapper > div.intro-image:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%; }
        #about-intro .accent-wrapper > div.intro-image:hover {
          -moz-transform: translate(-1%, 1%);
          -webkit-transform: translate(-1%, 1%);
          transform: translate(-1%, 1%); }
          #about-intro .accent-wrapper > div.intro-image:hover .color-accent {
            bottom: -12.338rem;
            left: -2.388rem; }
    #about-intro .accent-wrapper .intro-video .vid-modal {
      display: none; }
  #about-intro .intro_container {
    display: flex;
    flex-flow: row nowrap; }
    #about-intro .intro_container .col {
      padding: 1rem;
      flex: 1; }
      #about-intro .intro_container .col h1 {
        font-size: 12vw; }
        @media (min-width: 460px) {
          #about-intro .intro_container .col h1 {
            font-size: 4rem; } }
      #about-intro .intro_container .col h3 {
        padding-top: 2rem; }
    @media (min-width: 0px) {
      #about-intro .intro_container {
        display: block; }
        #about-intro .intro_container > div {
          display: block; }
        #about-intro .intro_container .col:nth-child(2) {
          margin-left: 0; } }
    @media (min-width: 1024px) {
      #about-intro .intro_container {
        display: flex; }
        #about-intro .intro_container .col:nth-child(2) {
          margin-left: 3.33333rem; } }

#why {
  position: relative; }
  #why h3 {
    padding: 2rem 0 4rem 0;
    text-align: center; }
  #why .why_container {
    display: flex;
    flex-flow: row nowrap; }
    #why .why_container .col {
      position: relative;
      padding: 1rem;
      flex: 1;
      margin: 0 0.66667rem;
      background: #ffffff;
      -webkit-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      -moz-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      top: 0; }
      #why .why_container .col:hover {
        top: -50px; }
      #why .why_container .col h3 {
        padding: 0.66667rem 0;
        text-align: center; }
      #why .why_container .col p {
        padding-top: 0;
        margin-top: 0;
        text-align: center; }
    @media (min-width: 0px) {
      #why .why_container {
        display: block; }
        #why .why_container .col {
          margin-bottom: 1.33333rem; } }
    @media (min-width: 640px) {
      #why .why_container {
        display: flex;
        height: 100%; }
        #why .why_container .col {
          margin-bottom: 0;
          height: 100%; } }

#team {
  position: relative;
  z-index: 2;
  padding-bottom: 4rem; }
  #team h1 {
    padding: 2.66667rem;
    text-align: center; }
  #team .team_container {
    display: flex;
    flex-flow: row nowrap;
    flex-flow: row wrap; }
    #team .team_container .col {
      position: relative;
      flex: 1 25%;
      min-width: 215px; }
      #team .team_container .col .active-bio .bio {
        opacity: 1 !important;
        top: 140% !important;
        z-index: 1 !important; }
      #team .team_container .col .active-bio img:first-child {
        opacity: 0; }
      #team .team_container .col .active-bio img:nth-child(2) {
        opacity: 1 !important; }
      #team .team_container .col .thumbnail {
        position: relative;
        background: url("../images/avatar_bg@2x.png") no-repeat 50% 50%;
        -webkit-background-size: cover;
        background-size: cover; }
        @media (min-width: 0px) {
          #team .team_container .col .thumbnail {
            max-width: 100%;
            height: auto;
            padding: 6rem;
            margin: 0.66667rem; } }
        #team .team_container .col .thumbnail .bio {
          transition: .4s ease-in-out;
          background-color: #ffffff;
          position: absolute;
          width: 100%;
          padding: 1rem;
          left: 0;
          top: 100%;
          z-index: -1;
          border-top: 4px solid #0281a6;
          box-shadow: 0 0 45px 9px rgba(112, 112, 112, 0.1);
          opacity: 0; }
          #team .team_container .col .thumbnail .bio > p {
            font-size: 0.9rem;
            text-align: left; }
          #team .team_container .col .thumbnail .bio > p:nth-child(odd) {
            font-weight: bold;
            color: #000000; }
        #team .team_container .col .thumbnail img:nth-child(2) {
          opacity: 0; }
        @media (min-width: 1024px) {
          #team .team_container .col .thumbnail:hover .bio {
            opacity: 1;
            top: 140%;
            z-index: 1; }
          #team .team_container .col .thumbnail:hover img:first-child {
            opacity: 0; }
          #team .team_container .col .thumbnail:hover img:nth-child(2) {
            opacity: 1; } }
      #team .team_container .col .thumbnail img {
        position: absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        width: auto;
        -webkit-transform: translate(-50%, -50%) rotate(0);
        -ms-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0);
        mix-blend-mode: multiply;
        padding-top: 1rem;
        -webkit-backface-visibility: hidden; }
      #team .team_container .col h3 {
        padding: 0.66667rem 0 0 0;
        text-align: center;
        font-size: 1rem;
        font-weight: 800; }
      #team .team_container .col p {
        padding-top: 0;
        margin-top: 0;
        text-align: center; }

#corporate {
  position: relative;
  z-index: 1;
  padding-top: 13.33333rem;
  margin-bottom: -20rem; }
  @media (min-width: 1024px) {
    #corporate {
      margin-bottom: 0; } }
  #corporate h1 {
    text-align: center;
    margin-bottom: 2.66667rem;
    padding: 1.5rem;
    font-size: 10vw; }
    @media (min-width: 640px) {
      #corporate h1 {
        font-size: 4rem; } }
  #corporate .accent-wrapper {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translate(-54%, 0%); }
    @media (min-width: 0px) {
      #corporate .accent-wrapper {
        display: none; } }
    @media (min-width: 1024px) {
      #corporate .accent-wrapper {
        display: flex; } }
    #corporate .accent-wrapper > div {
      flex: 1;
      position: relative; }
      #corporate .accent-wrapper > div.secondary-image {
        position: relative; }
        #corporate .accent-wrapper > div.secondary-image .color-accent {
          position: absolute;
          z-index: -1;
          bottom: -1.99rem;
          right: -1.99rem;
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
          clip-path: polygon(100% 50%, 0% 100%, 100% 100%);
          overflow: hidden; }
          #corporate .accent-wrapper > div.secondary-image .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
        #corporate .accent-wrapper > div.secondary-image:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%; }
        #corporate .accent-wrapper > div.secondary-image .thumb {
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          line-height: 0;
          padding: 6.66667rem; }
          #corporate .accent-wrapper > div.secondary-image .thumb:before, #corporate .accent-wrapper > div.secondary-image .thumb:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%; }
    #corporate .accent-wrapper .intro-video .vid-modal {
      display: none; }
  #corporate .slant-container {
    top: -250px;
    margin-bottom: 0; }
    #corporate .slant-container:nth-child(2) {
      top: 0;
      margin-bottom: -100px; }
    #corporate .slant-container .dual-slant-overlay.reverse {
      top: -25%; }
    #corporate .slant-container .slant {
      width: 150%;
      left: -25%;
      background: #f6b830; }
      #corporate .slant-container .slant > div {
        padding: 3.98rem 0 11.94rem; }
        #corporate .slant-container .slant > div:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 0% 50%;
          height: 110%;
          mix-blend-mode: multiply; }
          @media (min-width: 1200px) {
            #corporate .slant-container .slant > div:before {
              background-image: url("../images/resources-facet-overlay@2x.png"); } }
      #corporate .slant-container .slant > div > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        top: -25%;
        left: 0px;
        height: 150%; }
      #corporate .slant-container .slant .wrap {
        color: #ffffff; }
        #corporate .slant-container .slant .wrap .corporate_container {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-flow: row nowrap; }
          @media (min-width: 1024px) {
            #corporate .slant-container .slant .wrap .corporate_container {
              width: auto;
              margin: 0; } }
          #corporate .slant-container .slant .wrap .corporate_container .col {
            padding: 4rem;
            flex: 2; }
            @media (min-width: 460px) {
              #corporate .slant-container .slant .wrap .corporate_container .col {
                padding: 6rem; } }
            @media (min-width: 640px) {
              #corporate .slant-container .slant .wrap .corporate_container .col {
                padding: 0.66667rem; } }
            @media (min-width: 1024px) {
              #corporate .slant-container .slant .wrap .corporate_container .col {
                padding: 0.33333rem; } }
            #corporate .slant-container .slant .wrap .corporate_container .col h4 {
              color: #000000 !important; }
            #corporate .slant-container .slant .wrap .corporate_container .col .box {
              position: relative;
              padding: 2.66667rem;
              background: #ffffff;
              border-radius: 0.33333rem;
              margin-bottom: 1.33333rem; }
              @media (min-width: 1024px) {
                #corporate .slant-container .slant .wrap .corporate_container .col .box p {
                  max-height: 150px;
                  overflow: hidden; } }
          #corporate .slant-container .slant .wrap .corporate_container .col:nth-child(2) {
            margin-left: 1.33333rem;
            flex: 3;
            margin-bottom: 1.33333rem;
            position: relative; }
            #corporate .slant-container .slant .wrap .corporate_container .col:nth-child(2) div.primary_image {
              position: absolute;
              border-radius: 0.33333rem;
              height: 98%;
              width: 98%;
              overflow: hidden; }
              #corporate .slant-container .slant .wrap .corporate_container .col:nth-child(2) div.primary_image img {
                position: absolute;
                left: 50%;
                top: 50%;
                height: 100%;
                width: auto !important;
                max-width: none;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      @media (min-width: 0px) {
        #corporate .slant-container .slant {
          width: 180%;
          left: -40%; }
          #corporate .slant-container .slant > div {
            padding: 3.98rem 0.995rem 1.99rem; }
          #corporate .slant-container .slant .wrap {
            display: block; }
            #corporate .slant-container .slant .wrap .col {
              width: 100%; }
            #corporate .slant-container .slant .wrap .col:nth-child(2) {
              display: none; } }
      @media (min-width: 640px) {
        #corporate .slant-container .slant {
          padding: 0 10rem 6.66667rem;
          width: 150%;
          left: -25%; }
          #corporate .slant-container .slant > div {
            padding: 3.98rem 0 1.99rem; } }
      @media (min-width: 1024px) {
        #corporate .slant-container .slant {
          padding: 0 10rem 16.66667rem;
          width: 150%;
          left: -25%; }
          #corporate .slant-container .slant > div {
            padding: 3.98rem 0 3.98rem; }
          #corporate .slant-container .slant .wrap .col:nth-child(2) {
            display: block; } }
      @media (min-width: 1200px) {
        #corporate .slant-container .slant {
          padding: 3.33333rem 0 16.66667rem; }
          #corporate .slant-container .slant > div {
            padding: 3.98rem 0 5.97rem; } }
      @media (min-width: 2000px) {
        #corporate .slant-container .slant > div > img {
          top: 0; }
        #corporate .slant-container .slant > div {
          padding: 7.96rem 0 9.95rem; } }
    @media (min-width: 1200px) {
      #corporate .slant-container .slant .tek-overlay {
        display: block; }
      #corporate .slant-container .slant > div:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-position: 0% 20%;
        background-size: cover;
        height: 200%; } }

section#contact h1 {
  text-align: center;
  padding: 2.66667rem 0; }

section#contact .form-wrapper {
  background: white;
  box-shadow: 0 0 133px rgba(0, 0, 0, 0.08);
  padding: 1.99rem; }
  @media (min-width: 1024px) {
    section#contact .form-wrapper {
      flex-basis: 70%;
      padding: 60px 80px 40px 80px; } }
  section#contact .form-wrapper .gform_wrapper {
    padding: 0; }
    section#contact .form-wrapper .gform_wrapper label {
      text-align: right;
      line-height: 2.66667rem; }
    section#contact .form-wrapper .gform_wrapper input, section#contact .form-wrapper .gform_wrapper textarea, section#contact .form-wrapper .gform_wrapper select, section#contact .form-wrapper .gform_wrapper .select2, section#contact .form-wrapper .gform_wrapper .select2-container--default .select2-selection--single {
      background: linear-gradient(to left, #f5f5f5, #fafafa);
      outline: 0;
      border: 0; }
    section#contact .form-wrapper .gform_wrapper input[type="text"], section#contact .form-wrapper .gform_wrapper select {
      width: 100%;
      min-height: 2.66667rem;
      padding-left: 1rem; }
      @media (min-width: 640px) {
        section#contact .form-wrapper .gform_wrapper input[type="text"], section#contact .form-wrapper .gform_wrapper select {
          width: 70%; } }
    section#contact .form-wrapper .gform_wrapper input[type="submit"] {
      background: linear-gradient(to right, #f6b830, #ff731a); }
      section#contact .form-wrapper .gform_wrapper input[type="submit"]:hover {
        transform: scale(1.06); }
    section#contact .form-wrapper .gform_wrapper .select2 {
      width: 70% !important;
      min-height: 2.66667rem;
      line-height: 2.66667rem; }
      section#contact .form-wrapper .gform_wrapper .select2.select2-container--default .select2-selection--single {
        padding-top: 0.46667rem; }
      section#contact .form-wrapper .gform_wrapper .select2.select2-container--default .select2-selection__arrow {
        top: 0.46667rem; }
  section#contact .form-wrapper h2 {
    font-size: 24px;
    color: #000000;
    padding: 20px 10px; }
    @media (min-width: 640px) {
      section#contact .form-wrapper h2 {
        padding-bottom: 1.99rem;
        padding-top: 0px; } }
    section#contact .form-wrapper h2 span {
      font-size: 15px;
      display: block;
      font-weight: 400;
      line-height: 18px; }
    section#contact .form-wrapper h2::before {
      content: "\f0e6";
      display: inline-block;
      font-family: FontAwesome;
      padding-right: 20px;
      padding-bottom: 15px;
      font-size: calc(.83333vw + 19.33333px); }

#virtual-intro {
  text-align: center;
  padding: 2.66667rem 0; }

#virtual-description {
  position: relative;
  z-index: 2;
  padding-bottom: 5%; }
  #virtual-description .virtual-logo-container {
    text-align: center; }
    #virtual-description .virtual-logo-container img {
      width: 100%;
      max-width: 280px; }
  #virtual-description .description_container {
    display: flex;
    flex-flow: row nowrap; }
    @media (min-width: 0px) {
      #virtual-description .description_container .alignright {
        float: none; } }
    @media (min-width: 460px) {
      #virtual-description .description_container .alignright {
        float: right; } }
    #virtual-description .description_container .col {
      padding: 1rem;
      flex: 1; }
      #virtual-description .description_container .col > div {
        text-align: center; }
        @media (min-width: 640px) {
          #virtual-description .description_container .col > div {
            text-align: left; } }
      #virtual-description .description_container .col h3 {
        padding-top: 2rem; }
    #virtual-description .description_container .col:nth-child(1) img {
      max-width: 80%; }
    #virtual-description .description_container .col:nth-child(2) {
      margin-left: 3.33333rem; }
    #virtual-description .description_container .description-image {
      position: relative; }
      #virtual-description .description_container .description-image .vid-thumb {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 0;
        padding: 6.66667rem;
        cursor: pointer; }
        #virtual-description .description_container .description-image .vid-thumb:before, #virtual-description .description_container .description-image .vid-thumb:after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%; }
        #virtual-description .description_container .description-image .vid-thumb:before {
          background-color: rgba(0, 110, 136, 0.4);
          background-clip: content-box;
          z-index: 1;
          top: 0%;
          padding: 6.66667rem; }
        #virtual-description .description_container .description-image .vid-thumb:after {
          content: "";
          font-family: "FontAwesome";
          font-weight: 400;
          z-index: 2;
          font-size: 3.2rem;
          color: #ffffff;
          margin: auto;
          width: auto;
          height: auto;
          padding: 6.66667rem;
          box-sizing: padding-box;
          left: 25%;
          top: 25%;
          margin-left: -1.6rem; }
      #virtual-description .description_container .description-image:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/general-image-overlay-faceting-effect.png");
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100%; }
      #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger {
        top: 0px;
        left: 0px;
        width: 500px;
        z-index: 0;
        -webkit-backface-visibility: hidden; }
        #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger:hover {
          z-index: 3; }
        #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger .tooltip-circle {
          position: absolute;
          top: -15px;
          left: -15px; }
        #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          background-color: #ffffff;
          box-shadow: 22px 22px 52px 0 rgba(0, 0, 0, 0.1);
          padding: 2rem 3rem;
          z-index: 1; }
          #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup img {
            width: 40%;
            margin-bottom: 1rem; }
          #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup h4 {
            color: #000000;
            margin-bottom: 1rem; }
          #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup p {
            margin-bottom: 1rem; }
          #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow {
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            color: #0281a6; }
            #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow span {
              background-image: url(../images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
              background-repeat: no-repeat;
              background-position: 50%;
              border-radius: 50%; }
            #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow:hover {
              color: #ff731a; }
      #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger.closed > section.tips-popup {
        opacity: 0; }
      #virtual-description .description_container .description-image > div.tooltip-container .tooltip-trigger.open > section.tips-popup {
        opacity: 1; }
    #virtual-description .description_container .description-video .vid-modal {
      display: none; }
    @media (min-width: 0px) {
      #virtual-description .description_container {
        display: block; }
        #virtual-description .description_container div.col:nth-child(2) {
          margin-left: 0;
          margin-top: 3rem; }
        #virtual-description .description_container .description-image {
          max-width: 50%;
          margin: auto; }
          #virtual-description .description_container .description-image > img, #virtual-description .description_container .description-image:before {
            display: none; }
          #virtual-description .description_container .description-image .vid-thumb {
            position: static;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            padding: 0; }
            #virtual-description .description_container .description-image .vid-thumb:before, #virtual-description .description_container .description-image .vid-thumb:after {
              padding: 0; }
            #virtual-description .description_container .description-image .vid-thumb:after {
              left: 50%;
              top: 50%; }
        #virtual-description .description_container .description_caption {
          color: #1e2e61; } }
    @media (min-width: 1024px) {
      #virtual-description .description_container {
        display: flex; }
        #virtual-description .description_container div.col:nth-child(2) {
          margin-left: 3.33333rem; }
        #virtual-description .description_container .description-image {
          max-width: inherit; }
          #virtual-description .description_container .description-image > img, #virtual-description .description_container .description-image:before {
            display: block; }
          #virtual-description .description_container .description-image .vid-thumb {
            position: absolute;
            top: 50%;
            transform: translateX(-45%) translateY(-50%);
            line-height: 0;
            padding: 6.66667rem; }
            #virtual-description .description_container .description-image .vid-thumb:before, #virtual-description .description_container .description-image .vid-thumb:after {
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
              width: 100%;
              height: 100%; }
            #virtual-description .description_container .description-image .vid-thumb:before {
              background-color: rgba(0, 110, 136, 0.4);
              background-clip: content-box;
              z-index: 1;
              top: 0%;
              padding: 6.66667rem; }
            #virtual-description .description_container .description-image .vid-thumb:after {
              content: "";
              font-family: "FontAwesome";
              font-weight: 400;
              z-index: 2;
              font-size: 3.2rem;
              color: #ffffff;
              margin: auto;
              width: auto;
              height: auto;
              padding: 6.66667rem;
              box-sizing: padding-box;
              left: 25%;
              top: 25%;
              margin-left: -1.6rem; } }

.description-small-image-container {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  width: 28%;
  transform: translateX(73%) translateY(-85%); }
  @media (min-width: 640px) {
    .description-small-image-container {
      display: none; } }
  @media (min-width: 0px) {
    .description-small-image-container {
      display: none; } }
  @media (min-width: 1024px) {
    .description-small-image-container {
      display: flex; } }

.description-image2 {
  flex: 1; }

.description-image3 {
  position: absolute;
  top: 70%;
  transform: translateX(-90%) translateY(-50%);
  line-height: 0; }

.virtual-form-btn {
  display: flex;
  justify-content: center;
  position: relative;
  top: -2.3em;
  z-index: 1;
  margin: auto; }

.virtual-form.form-wrapper {
  background: white;
  box-shadow: 0 0 133px rgba(0, 0, 0, 0.08);
  padding: 1.99rem;
  margin-bottom: 3.98rem; }
  @media (min-width: 1024px) {
    .virtual-form.form-wrapper {
      flex-basis: 70%;
      padding: 10px 80px 40px 80px;
      margin: 0px 15% 30px 15%; } }
  .virtual-form.form-wrapper .gform_wrapper {
    padding: 0; }
    .virtual-form.form-wrapper .gform_wrapper label {
      text-align: left;
      line-height: 2.66667rem;
      vertical-align: sub; }
    @media (min-width: 1024px) {
      .virtual-form.form-wrapper .gform_wrapper label {
        text-align: left;
        line-height: 2.66667rem; } }
    .virtual-form.form-wrapper .gform_wrapper input, .virtual-form.form-wrapper .gform_wrapper textarea, .virtual-form.form-wrapper .gform_wrapper select, .virtual-form.form-wrapper .gform_wrapper .select2, .virtual-form.form-wrapper .gform_wrapper .select2-container--default .select2-selection--single {
      background: linear-gradient(to left, #f5f5f5, #fafafa);
      outline: 0;
      border: 0; }
    .virtual-form.form-wrapper .gform_wrapper input[type="text"], .virtual-form.form-wrapper .gform_wrapper select {
      width: 100%;
      min-height: 2.66667rem;
      padding-left: 1rem; }
      @media (min-width: 640px) {
        .virtual-form.form-wrapper .gform_wrapper input[type="text"], .virtual-form.form-wrapper .gform_wrapper select {
          width: 70%; } }
    .virtual-form.form-wrapper .gform_wrapper input[type="submit"] {
      background: linear-gradient(to right, #f6b830, #ff731a); }
      .virtual-form.form-wrapper .gform_wrapper input[type="submit"]:hover {
        transform: scale(1.06); }
    .virtual-form.form-wrapper .gform_wrapper .select2 {
      width: 70% !important;
      min-height: 2.66667rem;
      line-height: 2.66667rem; }
      .virtual-form.form-wrapper .gform_wrapper .select2.select2-container--default .select2-selection--single {
        padding-top: 0.46667rem; }
      .virtual-form.form-wrapper .gform_wrapper .select2.select2-container--default .select2-selection__arrow {
        top: 0.46667rem; }
  .virtual-form.form-wrapper h2 {
    font-size: 24px;
    color: #000000;
    padding: 20px 10px; }
    @media (min-width: 640px) {
      .virtual-form.form-wrapper h2 {
        padding-bottom: 1.99rem;
        padding-top: 0px; } }
    .virtual-form.form-wrapper h2 span {
      font-size: 15px;
      display: block;
      font-weight: 400;
      line-height: 18px; }
    .virtual-form.form-wrapper h2::before {
      content: "\f0e6";
      display: inline-block;
      font-family: FontAwesome;
      padding-right: 20px;
      padding-bottom: 15px;
      font-size: calc(.83333vw + 19.33333px); }
  .virtual-form.form-wrapper.panel {
    display: none; }

@media (min-width: 640px) {
  .gform_wrapper ul.gform_fields li.gfield, .gform_footer {
    margin-left: 29% !important; } }

@media (min-width: 640px) {
  .gfield_error {
    width: 50% !important; } }

.virtual-container > h1 {
  text-align: center;
  padding-bottom: 2.66667rem; }

#virtual-overview {
  position: relative; }
  #virtual-overview .virtual-container .slant-container {
    z-index: 1;
    top: -300px;
    margin-bottom: -200px; }
    @media (min-width: 0px) {
      #virtual-overview .virtual-container .slant-container {
        top: -50px;
        margin-bottom: 1rem; }
        #virtual-overview .virtual-container .slant-container .slant {
          width: 100%;
          left: initial;
          transform: rotate(0deg) translateY(0px); }
          #virtual-overview .virtual-container .slant-container .slant.reverse > div {
            transform: rotate(0deg);
            -webkit-clip-path: polygon(0 4%, 100% 0, 100% 98%, 0 94%);
            clip-path: polygon(0 4%, 100% 0, 100% 98%, 0 94%);
            background: #fff; } }
    @media (min-width: 1024px) {
      #virtual-overview .virtual-container .slant-container {
        top: -300px;
        margin-bottom: -200px; }
        #virtual-overview .virtual-container .slant-container .slant.reverse > div {
          transform: rotate(-8deg);
          -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
          clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
          background: #fff; } }
    @media (min-width: 1024px) {
      #virtual-overview .virtual-container .slant-container .slant.reverse > div {
        transform: rotate(-6deg);
        -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
        background: #fff; } }
    #virtual-overview .virtual-container .slant-container .accent-wrapper {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      z-index: 3;
      top: 250px;
      width: 100%;
      max-width: 975px;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 0px) {
        #virtual-overview .virtual-container .slant-container .accent-wrapper {
          display: none; } }
      @media (min-width: 1024px) {
        #virtual-overview .virtual-container .slant-container .accent-wrapper {
          display: flex; } }
      #virtual-overview .virtual-container .slant-container .accent-wrapper > div {
        flex: 1;
        position: relative;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div:last-child {
          pointer-events: none; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div .color-accent {
          position: absolute;
          z-index: -1;
          bottom: -11.94rem;
          left: -1.99rem;
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div:hover {
          transform: translate(-1%, 1%); }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div:hover .color-accent {
            bottom: -12.338rem;
            left: -2.388rem; }
      #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger {
        top: 0px;
        left: 0px;
        width: 500px;
        z-index: 0;
        -webkit-backface-visibility: hidden; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger:hover {
          z-index: 3; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger .tooltip-circle {
          position: absolute;
          top: -15px;
          left: -15px; }
        #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup {
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          background-color: #ffffff;
          box-shadow: 22px 22px 52px 0 rgba(0, 0, 0, 0.1);
          padding: 2rem 3rem;
          z-index: 1; }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup img {
            width: 40%;
            margin-bottom: 1rem; }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup h4 {
            color: #000000;
            margin-bottom: 1rem; }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup p {
            margin-bottom: 1rem; }
          #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow {
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms;
            color: #0281a6; }
            #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow span {
              background-image: url(../images/icon-arrow.png), linear-gradient(90deg, #f6b830, #ff731a);
              background-repeat: no-repeat;
              background-position: 50%;
              border-radius: 50%; }
            #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger section.tips-popup a.arrow:hover {
              color: #ff731a; }
      #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger.closed > section.tips-popup {
        opacity: 0; }
      #virtual-overview .virtual-container .slant-container .accent-wrapper > div.tooltip-container .tooltip-trigger.open > section.tips-popup {
        opacity: 1; }
    #virtual-overview .virtual-container .slant-container .slant .tek-overlay {
      display: none;
      opacity: 0.5;
      top: 25%;
      left: 50%;
      width: 2142px;
      height: 800px; }
    @media (min-width: 0px) {
      #virtual-overview .virtual-container .slant-container .slant > div:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        height: 110%;
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        z-index: 1 !important;
        background-color: #0281a6; } }
    @media (min-width: 1024px) {
      #virtual-overview .virtual-container .slant-container .slant > div:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/overview-facet-overlay@2x.png");
        background-repeat: no-repeat;
        background-position: 20% 50%;
        height: 110%;
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        z-index: 1 !important;
        background-color: initial; } }
    #virtual-overview .virtual-container .slant-container .slant > div > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: -25%;
      left: 0px;
      height: 150%; }
    #virtual-overview .virtual-container .slant-container .slant .wrap {
      color: #ffffff;
      min-height: 26.66667rem;
      display: flex;
      flex-flow: row nowrap;
      z-index: 2; }
      #virtual-overview .virtual-container .slant-container .slant .wrap .col {
        padding: 0 0.33333rem;
        flex: 1; }
      #virtual-overview .virtual-container .slant-container .slant .wrap .col:nth-child(2) {
        margin-left: 6.66667rem; }
      #virtual-overview .virtual-container .slant-container .slant .wrap h1 {
        padding: 1.33333rem 0 1.33333rem 0; }
      @media (min-width: 0px) {
        #virtual-overview .virtual-container .slant-container .slant .wrap {
          margin-bottom: 30%;
          flex-flow: row wrap;
          padding-top: 5em; }
          #virtual-overview .virtual-container .slant-container .slant .wrap h1 {
            text-align: center; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .virtual-main-text {
            text-align: center;
            margin: auto;
            color: white; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .items {
            display: flex;
            flex-flow: column; }
            #virtual-overview .virtual-container .slant-container .slant .wrap .items li {
              text-align: center;
              margin-bottom: 5%; }
              #virtual-overview .virtual-container .slant-container .slant .wrap .items li p {
                color: white;
                margin: 0px; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .virtual-bottom-text {
            font-size: 1.5rem;
            text-align: center;
            margin: auto;
            color: white;
            padding-top: 2rem; } }
      @media (min-width: 1024px) {
        #virtual-overview .virtual-container .slant-container .slant .wrap {
          padding-top: 2em;
          margin-bottom: initial;
          padding-bottom: 2em;
          flex-flow: row wrap; }
          #virtual-overview .virtual-container .slant-container .slant .wrap h1 {
            margin: auto; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .virtual-main-text {
            text-align: center;
            margin: auto;
            color: white; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .items {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between; }
            #virtual-overview .virtual-container .slant-container .slant .wrap .items li {
              flex: 0 32%;
              text-align: center;
              margin-bottom: 5%; }
              #virtual-overview .virtual-container .slant-container .slant .wrap .items li p {
                color: white;
                margin: 0px; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .virtual-bottom-text {
            font-size: 2.4rem;
            text-align: center;
            color: white;
            padding-top: initial; } }
      #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list {
        display: flex;
        flex-flow: row nowrap;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 6.66667rem;
        padding-left: 1.99rem; }
        #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li {
          position: relative;
          padding-left: 2rem;
          /*&:after {
			top: 10px;
			left: 5px;
			width: 6px;
			height: 6px;
			background: $white;
		}*/ }
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li:before, #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li:after {
            content: '';
            border-radius: 50%;
            display: inline-block;
            position: absolute; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li:before {
            top: 5px;
            left: 0;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: solid #f6b830 5px;
            background: linear-gradient(to top, #ff731a, #f6b830) 1 100%;
            clip-path: circle(50% at 50% 50%); }
        #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list ul li {
          padding-left: 1.32667rem; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list ul li:before, #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list ul li:after {
            width: auto;
            height: auto;
            top: auto;
            background: none; }
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list ul li:before {
            content: '-';
            color: #ff731a;
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.5; }
        #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li {
          flex: 1 0 auto;
          line-height: 1.75em;
          padding-right: 0.13333rem;
          padding-bottom: 0.53333rem; }
        @media (min-width: 0px) {
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li {
            flex: 1 100%;
            margin-left: 0.66667rem; } }
        @media (min-width: 640px) {
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list li {
            flex: 1 50%;
            max-width: 50%;
            margin-left: 0; } }
        @media (min-width: 640px) {
          #virtual-overview .virtual-container .slant-container .slant .wrap .overview-list {
            padding-left: 0; } }
    @media (min-width: 0px) {
      #virtual-overview .virtual-container .slant-container .slant > div {
        padding: 9.95rem 0 1.99rem; } }
    @media (min-width: 640px) {
      #virtual-overview .virtual-container .slant-container .slant > div {
        padding: 7.96rem 0 1.99rem; } }
    @media (min-width: 1024px) {
      #virtual-overview .virtual-container .slant-container .slant {
        width: 120%;
        left: -10%;
        transform: rotate(6deg); }
        #virtual-overview .virtual-container .slant-container .slant > div {
          padding: 7.96rem 0 7.96rem; } }
    @media (min-width: 1200px) {
      #virtual-overview .virtual-container .slant-container .slant > div {
        padding: 7.96rem 0 7.96rem; } }
    @media (min-width: 2000px) {
      #virtual-overview .virtual-container .slant-container .slant > div > img {
        top: 0; }
      #virtual-overview .virtual-container .slant-container .slant > div {
        padding: 7.96rem 0 7.96rem; } }
    @media (min-width: 1200px) {
      #virtual-overview .virtual-container .slant-container .slant .tek-overlay {
        display: block; }
      #virtual-overview .virtual-container .slant-container .slant > div:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-position: 0% 20%;
        background-size: cover;
        height: 125%; } }
  #virtual-overview .overview-gallery {
    position: relative;
    z-index: 2; }
  #virtual-overview .slider-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2.93333rem;
    left: 0px;
    z-index: 2; }
    #virtual-overview .slider-arrows .slick-arrow {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #virtual-overview .slider-arrows .slick-arrow:after {
        display: none; }
      #virtual-overview .slider-arrows .slick-arrow.slick-prev {
        transform: rotate(-180deg); }
      #virtual-overview .slider-arrows .slick-arrow.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #virtual-overview .slider-arrows .slick-arrow:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #virtual-overview .slider-arrows .slick-arrow:hover.slick-prev {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #virtual-overview .slider-arrows-P {
    position: absolute;
    top: 50%;
    margin-top: -2.93333rem;
    left: 0px;
    z-index: 2; }
    #virtual-overview .slider-arrows-P .slick-arrow-P {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #virtual-overview .slider-arrows-P .slick-arrow-P:after {
        display: none; }
      #virtual-overview .slider-arrows-P .slick-arrow-P.slick-prev-P {
        transform: rotate(-180deg); }
      #virtual-overview .slider-arrows-P .slick-arrow-P.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #virtual-overview .slider-arrows-P .slick-arrow-P:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #virtual-overview .slider-arrows-P .slick-arrow-P:hover.slick-prev-P {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #virtual-overview .slider-arrows-N {
    position: absolute;
    top: 50%;
    margin-top: -2.93333rem;
    right: 0px;
    z-index: 2; }
    #virtual-overview .slider-arrows-N .slick-arrow-N {
      background-image: url("../images/icon-arrow.png"), linear-gradient(to right, #f6b830, #ff731a);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: #ff731a; }
      #virtual-overview .slider-arrows-N .slick-arrow-N:after {
        display: none; }
      #virtual-overview .slider-arrows-N .slick-arrow-N.slick-prev {
        transform: rotate(-180deg); }
      #virtual-overview .slider-arrows-N .slick-arrow-N.slick-next {
        float: right; }
      @media (min-width: 1024px) {
        #virtual-overview .slider-arrows-N .slick-arrow-N:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1); }
          #virtual-overview .slider-arrows-N .slick-arrow-N:hover.slick-prev {
            margin-right: 0.4975rem;
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1); } }
  #virtual-overview .services-slider .gallery-wrapper {
    padding: 0.4975rem; }
  #virtual-overview .services-slider .slide-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 9.95rem; }
    #virtual-overview .services-slider .slide-bg:before {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: 0;
      content: ""; }
    #virtual-overview .services-slider .slide-bg h2, #virtual-overview .services-slider .slide-bg p {
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36); }
    #virtual-overview .services-slider .slide-bg h2 {
      display: none;
      color: #ffffff; }
    #virtual-overview .services-slider .slide-bg p {
      color: #ffffff;
      font-weight: 700;
      font-size: 0.93333rem;
      margin-top: 0rem; }
      #virtual-overview .services-slider .slide-bg p span {
        color: #f6b830;
        font-size: 1.6rem;
        padding-right: 0.4975rem; }

.image-slant-20year {
  width: 15.92rem;
  position: relative;
  margin: auto;
  filter: drop-shadow(0px 0px 60px #000000); }
  @media (min-width: 640px) {
    .image-slant-20year {
      width: 23.88rem; } }
  @media (min-width: 1024px) {
    .image-slant-20year {
      width: 23.88rem; } }
  @media (min-width: 1200px) {
    .image-slant-20year {
      width: 37.81rem; } }
  @media (min-width: 2000px) {
    .image-slant-20year {
      iwidth: 37.81rem; } }

.image-slant-title20 {
  filter: drop-shadow(1px 1px 20px #000000); }

#_20_Year_Logo {
  margin-top: 15px; }

/*
header[role="banner"]{

  @include breakpoint( large ) {
		
		.site-logo {
			margin-left: 0px!important;
  margin-right: 20px;
		}
	}
}
*/
.hero-banner h2 {
  line-height: 1.2em !important; }

.banner-slant20 > div {
  padding: 11.94rem 0 11.94rem; }
  .banner-slant20 > div:before, .banner-slant20 > div:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%; }
  .banner-slant20 > div:after {
    background-color: rgba(18, 28, 98, 0.4);
    height: 125%;
    z-index: -1;
    top: -10%; }

.banner-slant20 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: -25%;
  left: 0px;
  height: 150%; }

@media (min-width: 640px) {
  .banner-slant20 > div {
    padding: 9.95rem 0 11.94rem; } }

@media (min-width: 1024px) {
  .banner-slant20 > div {
    padding: 9.95rem 0 11.94rem; } }

@media (min-width: 1200px) {
  .banner-slant20 > div {
    padding: 11.94rem 0 19.9rem; } }

@media (min-width: 2000px) {
  .banner-slant20 img {
    top: 0;
    height: 120%; }
  .banner-slant20 > div {
    padding: 9.95rem 0 15.92rem; } }

.hero-banner {
  position: relative; }
  .hero-banner .banner-slant,
  .hero-banner .accent-slant {
    position: relative;
    overflow: hidden; }
  .hero-banner .banner-slant {
    height: 200px;
    top: -100px; }
    .hero-banner .banner-slant .tek-overlay {
      top: 50%;
      margin-top: -300px;
      left: -1500px;
      width: 2142px;
      height: 800px; }
    .hero-banner .banner-slant > div {
      padding: 0;
      top: 0; }
      .hero-banner .banner-slant > div:before {
        top: 100px;
        height: 80px;
        background: linear-gradient(to bottom, rgba(0, 18, 23, 0.7) 0%, rgba(0, 18, 23, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001217', endColorstr='#00001217', GradientType=0); }
      .hero-banner .banner-slant > div:after {
        background-color: rgba(18, 28, 98, 0.7); }
    .hero-banner .banner-slant img {
      top: 0px; }
  .hero-banner .accent-slant {
    top: -275px; }
  .hero-banner .wrap {
    text-align: center;
    color: #ffffff;
    padding: 15.92rem 0rem 9.95rem;
    padding-bottom: 7%; }
    @media (min-width: 1024px) {
      .hero-banner .wrap {
        text-align: left; } }
  .hero-banner .secondary-title-wrapper {
    text-align: left;
    display: inline-block;
    white-space: nowrap; }
  .hero-banner .secondary-title {
    margin: 0px !important; }
  .hero-banner .titlewrapper {
    display: inline-block; }
  .hero-banner .primary-title, .hero-banner .secondary-title {
    text-align: left;
    display: inline-block; }
  .hero-banner .primary-title {
    margin-left: auto;
    text-align: center;
    display: block; }
    @media (min-width: 1024px) {
      .hero-banner .primary-title {
        margin-left: 4.66667rem;
        display: inline-block; } }
  .hero-banner h2 {
    text-align: center; }
  @media (min-width: 0px) {
    .hero-banner .secondary-title-wrapper {
      height: 65px; } }
  @media (min-width: 640px) {
    .hero-banner .secondary-title-wrapper {
      height: 75px; } }
  @media (min-width: 1024px) {
    .hero-banner .secondary-title-wrapper {
      height: 108px; } }
  .hero-banner .secondary-title {
    text-align: center;
    display: block;
    margin: auto; }
    @media (min-width: 640px) {
      .hero-banner .secondary-title {
        margin-left: 4.66667rem;
        display: inline-block; } }
    .hero-banner .secondary-title[hidden] {
      display: none; }
    .hero-banner .secondary-title:before {
      content: "\00a0";
      font-size: 0em; }
  body.home .hero-banner .banner-slant > div:after {
    background-color: initial; }
  body.home .hero-banner h1 {
    font-size: calc(4.375vw + 34px); }
    @media (max-width: 320px) {
      body.home .hero-banner h1 {
        font-size: 48px; } }
    @media (min-width: 1280px) {
      body.home .hero-banner h1 {
        font-size: 90px; } }
  body.home .hero-banner h2 {
    font-size: calc(3.125vw + 2px);
    font-weight: 600;
    padding: 1.99rem 1.99rem 0;
    line-height: 2.4rem; }
    @media (max-width: 320px) {
      body.home .hero-banner h2 {
        font-size: 12px; } }
    @media (min-width: 1280px) {
      body.home .hero-banner h2 {
        font-size: 42px; } }
  body.home .hero-banner .banner-slant {
    height: auto;
    background-color: #ffffff; }
    body.home .hero-banner .banner-slant img {
      height: 115%; }
  body.home .hero-banner video {
    position: absolute;
    z-index: -1;
    top: 64%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  @media (min-width: 0px) {
    body.home .hero-banner video {
      display: block;
      min-width: 10%;
      min-height: 10%;
      width: 90%;
      height: 90%;
      top: 75%; } }
  body.home .hero-banner .hero-arrow {
    position: absolute;
    width: 138px;
    height: 90px;
    bottom: 60px;
    left: 50%;
    margin-left: -69px;
    z-index: 3; }
  @media (min-width: 640px) {
    .hero-banner .wrap {
      padding: 23.88rem 0rem 15.92rem;
      padding-bottom: 2%; }
    .hero-banner .banner-slant {
      height: 240px; }
      .hero-banner .banner-slant > div {
        height: 150%; }
        .hero-banner .banner-slant > div:before {
          height: 120px; }
    body.home .hero-banner .banner-slant img {
      height: 115%; }
    body.home .hero-banner video {
      height: initial;
      min-width: 90%;
      min-height: 90%;
      top: 72%; } }
  @media (min-width: 1024px) {
    .hero-banner .banner-slant {
      height: 300px; }
      .hero-banner .banner-slant .tek-overlay {
        margin-top: -480px;
        left: -2220px;
        width: 3213px;
        height: 1200px; }
    .hero-banner .wrap {
      padding: 23.88rem 0rem 15.92rem;
      padding-bottom: 22%; }
    body.home .hero-banner h2 {
      padding: 1.99rem 7.96rem 0; }
    body.home .hero-banner video {
      height: initial;
      min-width: 90%;
      min-height: 90%;
      top: 68%; }
    body.home .hero-banner .hero-arrow {
      width: 214px;
      height: 140px;
      margin-left: -112px;
      bottom: 35px;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      body.home .hero-banner .hero-arrow:hover {
        bottom: 15px; } }
  @media (min-width: 1440px) {
    .hero-banner .wrap {
      padding: 31.84rem 0rem 23.88rem;
      padding-bottom: 16%; } }
  @media (min-width: 2000px) {
    .hero-banner .banner-slant {
      height: 350px;
      top: -150px; }
      .hero-banner .banner-slant img {
        height: 115%; }
    .hero-banner .accent-slant {
      top: -400px; } }

#year20-intro {
  position: relative;
  z-index: 2; }
  #year20-intro .accent-wrapper {
    position: initial;
    z-index: 0;
    top: initial;
    width: initial;
    max-width: 975px;
    left: initial;
    transform: none; }
    #year20-intro .accent-wrapper .color-accent {
      display: none; }
    @media (min-width: 0px) {
      #year20-intro .accent-wrapper {
        display: block; }
        #year20-intro .accent-wrapper > div {
          display: block; } }
    @media (min-width: 1024px) {
      #year20-intro .accent-wrapper {
        display: flex; }
        #year20-intro .accent-wrapper.accent-wrapper {
          display: flex;
          flex-flow: row nowrap;
          position: absolute;
          z-index: 3;
          top: 0px;
          width: 100%;
          max-width: 975px;
          left: 50%;
          transform: translateX(-50%); }
        #year20-intro .accent-wrapper .color-accent {
          display: block; } }
    #year20-intro .accent-wrapper > div {
      flex: 1;
      position: relative; }
      #year20-intro .accent-wrapper > div.year20-intro-image {
        position: relative;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        #year20-intro .accent-wrapper > div.year20-intro-image.year20-intro-image img:nth-child(2) {
          margin-top: 0px;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #year20-intro .accent-wrapper > div.year20-intro-image.year20-intro-image img:nth-child(2):hover {
            -moz-transform: translate(1%, -2.1%);
            -webkit-transform: translate(1%, -2.1%);
            transform: translate(1%, -2.1%); }
        #year20-intro .accent-wrapper > div.year20-intro-image.year20-intro-image img:nth-child(3) {
          margin-top: 50px; }
        #year20-intro .accent-wrapper > div.year20-intro-image .color-accent {
          position: absolute;
          z-index: -1;
          bottom: 23.084rem;
          left: -1.194rem;
          width: 90%;
          height: 30%;
          background: linear-gradient(to right, #f6b830, #ff731a);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          #year20-intro .accent-wrapper > div.year20-intro-image .color-accent:before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/accent-triangle-overlay.png");
            background-repeat: no-repeat;
            background-position: 240% 180%; }
    #year20-intro .accent-wrapper .intro-video .vid-modal {
      display: none; }
  #year20-intro .intro_container {
    display: flex;
    flex-flow: row nowrap; }
    #year20-intro .intro_container .col {
      padding: 1rem;
      flex: 1; }
      #year20-intro .intro_container .col.p_space {
        padding-top: 0px; }
      #year20-intro .intro_container .col h1 {
        font-size: 12vw; }
        @media (min-width: 460px) {
          #year20-intro .intro_container .col h1 {
            font-size: 4rem; } }
      #year20-intro .intro_container .col h3 {
        padding-top: 2rem; }
    @media (min-width: 0px) {
      #year20-intro .intro_container {
        display: block; }
        #year20-intro .intro_container > div {
          display: block; }
        #year20-intro .intro_container .col:nth-child(2) {
          margin-left: 0; } }
    @media (min-width: 1024px) {
      #year20-intro .intro_container {
        display: flex; }
        #year20-intro .intro_container .col:nth-child(2) {
          margin-left: 3.33333rem; } }

#services-overview {
  padding-top: 3.5rem; }
  #services-overview > h1 {
    text-align: left;
    margin-left: 7%; }

.p_space {
  color: green; }
  .p_space p:nth-child(1) {
    margin-top: 0px; }
  .p_space p:nth-child(2) {
    margin-top: 0px; }

#congratulations-intro {
  text-align: center;
  padding: 2.66667rem 0; }

#congratulations {
  position: relative;
  z-index: 2;
  padding-bottom: 5%; }
  #congratulations .congratulations-container {
    display: flex;
    flex-flow: row nowrap;
    flex-flow: row wrap;
    text-align: center; }
    #congratulations .congratulations-container .col {
      position: relative;
      padding: 1rem;
      flex: 1 25%;
      min-width: 300px;
      box-shadow: 5px 5px 48px 0 rgba(0, 0, 0, 0.1);
      margin: 20px; }
      #congratulations .congratulations-container .col .thumbnail {
        position: relative;
        -webkit-background-size: cover;
        background-size: cover; }
        @media (min-width: 0px) {
          #congratulations .congratulations-container .col .thumbnail {
            max-width: 100%;
            height: auto; } }
      #congratulations .congratulations-container .col .info > p {
        margin: initial; }

.holiday-card {
  margin: auto;
  background-image: url("/wp-content/uploads/2021/12/Snowflake-Background.jpg");
  background-repeat: no-repeat;
  background-size: 110%; }

.holiday-video-container {
  width: auto;
  position: relative;
  transform: translate(-50%);
  left: 50%; }
  @media (min-width: 640px) {
    .holiday-video-container {
      width: 640px; } }

.ribbon {
  display: none;
  width: 300px;
  z-index: 2;
  position: absolute;
  right: -92px;
  top: -79px; }
  @media (min-width: 640px) {
    .ribbon {
      display: initial; } }

.gform_confirmation_wrapper .gquiz-field-label, .gform_wrapper .gquiz-correct-choice {
  color: #339900 !important; }

input#gform_submit_button_6 {
  background-color: #365e83; }
  input#gform_submit_button_6:hover {
    background-color: #ff751a; }

.gform_heading {
  font-family: Birthstone;
  color: #01478b; }

.gform_wrapper.gravity-theme .gfield_required {
  display: none; }

.gfield_required {
  display: none !important; }

#gform_6_validation_container {
  display: none; }

#validation_message_6_1 {
  display: none; }

#validation_message_6_2 {
  display: none; }

#validation_message_6_3 {
  display: none; }

#validation_message_6_4 {
  display: none; }

#validation_message_6_5 {
  display: none; }

#validation_message_6_6 {
  display: none; }

#validation_message_6_7 {
  display: none; }

#validation_message_6_8 {
  display: none; }

#validation_message_6_9 {
  display: none; }

#validation_message_6_10 {
  display: none; }

.gform_wrapper, #gform_confirmation_message_6 {
  width: 95%;
  margin: auto; }

.gform_heading {
  text-align: center; }
