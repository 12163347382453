/**
 * TEK | Testimonials
 */

#testimonials {
	position: relative;
	padding: rem-calc(100) 0 rem-calc(100);
	z-index: 2;

	h1 {
		font-size: 10vw;
		@include breakpoint ( medium ) { font-size: 4rem; }
	}

	.slider-arrows {
		padding: 1rem 0;

		@include breakpoint ( medium ) {
			position: absolute;
			top: 105%;
			left: 0px;
			z-index: 2;
		}

		.slick-arrow {
			background-image: url('../images/icon-arrow.png'), linear-gradient( to right, $orange-light, $orange );
			background-repeat: no-repeat;
			background-position: center center;
			text-indent: -9999px;
			border-radius: 0px;
			background-color: $orange;

			&:after {
				display: none;
			}

			&.slick-prev {
				transform: rotate(-180deg);
				margin-right: bsu(0.25);
			}

			@include breakpoint( large ) {
				&:hover {
					-moz-transform: scale(1.1);
					-webkit-transform: scale(1.1);
					transform: scale(1.1);

					&.slick-prev {
						margin-right: bsu(0.25);
						-moz-transform: rotate(-180deg) scale(1.1);
						-webkit-transform: rotate(-180deg) scale(1.1);
						transform: rotate(-180deg) scale(1.1);
					}
				}
			}
		}
	}
	.testimonial-slider {

		clip-path: inset(0 rem-calc(25) 0 0);
		margin-left: rem-calc(-25);
		padding-left: rem-calc(25);
		overflow: hidden;

		.slick-list {
			overflow: visible;

			.slick-slide p {
				//font-size: rem-calc(12);
				font-size: rem-calc(15);
			}

			@include breakpoint ( medium ) {
				.slick-active p {
					//font-size: rem-calc(12);
				}
				.slick-current p {
					//font-size: rem-calc(15);
				}
			}
			
		}

		.testimonial-wrapper {

			position: relative;
			margin: 0 rem-calc(20) 0 rem-calc(-20);
			padding: 0 rem-calc(25) 0 rem-calc(25);

				p {
					padding-right: rem-calc(15);
					padding-bottom: rem-calc(15);
					//max-height: 255px;
					//overflow: scroll;
				}


			&:before {
				@include overlay();
				z-index: -2;
				background-image: url('../images/quote.png');
				background-repeat: no-repeat;
			}
		}
	}
}