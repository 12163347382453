/**
 * TEK | HEADER
 */
header[role="banner"] {
	@include transition-ease-in-out();
	padding: bsu(0.5);
	position: fixed;
	width: 100%;
	z-index: 3;

	> div {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#menu-toggle {
		display: flex;
		align-items: center;
		padding: 0;
		line-height: 1;
		overflow: unset;
		background: none;

		&:after {
			background: none;
		}

		span {
			display: inline-block;

			&:last-child {
				margin-left: bsu(0.3);
				width: 29px;
				height: 17px;
			}
		}
	}

	&.scrolled,
	&.toggled {
		background-color: $white;

		#menu-toggle {
			color: $gray-darkest;
		}

		rect {
		    fill: $gray-darkest;
		}
	}

	&.scrolled {
		&:before {
			@include overlay();
			height: 10px;
			top: initial;
			bottom: -10px;
			background: $orange;
			background: linear-gradient(to right, $orange-light 0%,$orange 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 );
		}
	}


	@include breakpoint( large ) {
		padding: 1.5rem; // Previous: bsu(1)

		&.toggled {
			background: none;
		}

		&.scrolled {
			background-color: $white;
		}

		> div {
			justify-content: center;
			max-width: 300px;
			margin: 0 auto;
		}

		.site-logo {
			margin-left: 30px;
		}

		#menu-toggle {
			display: none;
		}
	}


	/**
	 * Admin bar fix - for "non-static" headers
	 */
	body.admin-bar & {
		@include breakpoint( 600px ) {
			top: 46px !important;
		}
		@include breakpoint( 782px ) {
			top: 32px !important;
		}
	}

} // header[role="banner"]

.site-logo {
	@include transition-ease-in-out();
	display: block;
	width: 108px;
	height: 30px;

	p {
		display: none;
	}

	@include breakpoint( medium ) {
		height: 35px; // Previous 40px;
	}

	@include breakpoint( large ) {
		width: 160px;
		height: 60px;
	}
}

// home page adjustments
body.home {
	@include breakpoint( xlarge ) {
		header[role="banner"] {
			.site-logo {
				width: 268px;
				height: 100px;
				margin-top: 50px;

				&:before {
					@include overlay();
					z-index: -1;
					background-color: rgba(0,0,0,0.7);
					box-shadow: 0 0 60px 60px rgba(0,0,0,0.7);
					border-radius: 50%;
					opacity: 0.25;
					top: 50px;
				}
			}

			&.scrolled {
				.site-logo {
					width: 160px;
					height: 60px;
					margin-top: 0;

					&:before {
						opacity: 0;
					}
				}
			}
		}
	}
}