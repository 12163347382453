/**
 * TEK | FORM COMPONENT
 */

form {

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
}

input, textarea, select, label {

	&:focus {
		outline: none;
	}
}

input, textarea {
	@include transition-ease-in-out();
}

input {

}

textarea {
}

label {
}