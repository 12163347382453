/**
 * TEK | HERO VIDEOPLAYER MUTE
 */


.mute-controls{
	position: relative;
	left: 10%;
}
.mute-btn {
	background-color: transparent;
	padding: initial;
	font-size: 3rem;
}
.mute {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&.unmuted {
		opacity: 0.5;
		
		.slash {
			display: none;
		}
	}
	.slash {
		position: absolute;
		width: 3px;
		height: 100%;
		background: white;
		transform: rotate(-50deg);
		display: block;

	}
}

@include breakpoint(zero) {
	.mute-controls{
		position: relative;
		left: 15%;
	}
}


@include breakpoint(small) {
	.mute-controls{
		left: 10%;
	}
}