/**
 * TEK | VENDOR PARTIALS
 */
 @media (min-width: 1024px){
    .has-ednbar nav {
        margin-top: 57px;
    }
    }

    .edn-opanel-trigger-image{
        animation-name: rob-example;
        animation-duration: 5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        position: relative;
    }
    @keyframes rob-example {
        0%   {bottom:-136px;}
        50%   {bottom:0px;}
        100% {bottom:0px;}
    }