.image-slant-20year{
  width: bsu(8);
  position: relative;
  margin: auto;
  filter: drop-shadow(0px 0px 60px #000000);

  @include breakpoint( medium ) {
		width: bsu(12);
	} //medium

	@include breakpoint( large ) {
		width: bsu(12);
	} //large

	@include breakpoint( xlarge ) {

		width: bsu(19);
	} //xlarge

	@include breakpoint( mega ) {
	
		iwidth: bsu(19);
	} //mega

}
.image-slant-title20{
  filter: drop-shadow(1px 1px 20px #000000);
}


//Site logo
#_20_Year_Logo{
  margin-top: 15px;
  //height: 80%;
}


/*
header[role="banner"]{

  @include breakpoint( large ) {
		
		.site-logo {
			margin-left: 0px!important;
  margin-right: 20px;
		}
	}
}
*/
// Leading for h2

.hero-banner h2 {
  line-height: 1.2em !important;
}

//body.home .hero-banner h2 {
  //font-size: calc(2.00833vw + 9.33333px);
//}

.banner-slant20 {
	> div {
		padding: bsu(6) 0 bsu(6);

		&:before,
		&:after {
			@include overlay();
		}

		&:after {
			background-color: rgba(18,28,98,0.4);
			height: 125%;
			z-index: -1;
			top: -10%;
		}
	}

	// background image
	img {
		@include cover-image();
		position: absolute;
		z-index: -2;
		top: -25%;
		left: 0px;
		height: 150%;
	}

	@include breakpoint( medium ) {
		> div {
			padding: bsu(5) 0 bsu(6);
		}
	} //medium

	@include breakpoint( large ) {
		> div {
			padding: bsu(5) 0 bsu(6);
		}
	} //large

	@include breakpoint( xlarge ) {

		> div {
			padding: bsu(6) 0 bsu(10);
		}
	} //xlarge

	@include breakpoint( mega ) {
		// background image
		img {
			top: 0;
			height: 120%; // Previous nothing
		}

		> div {
			padding: bsu(5) 0 bsu(8);
		}
	} //mega
} 






// Drop orphan on to next line on mobile for the h2 tag in image slant

.hero-banner {
  position: relative;

  .banner-slant,
  .accent-slant {
    position: relative;
    overflow: hidden;
  }

  .banner-slant {
    height: 200px;
    top: -100px;

    .tek-overlay {
      top: 50%;
      margin-top: -300px;
      left: -1500px;
      width: 2142px;
      height: 800px;
    }

    > div {
      padding: 0;
      top: 0;

      &:before {
        top: 100px;
        height: 80px;
        background: linear-gradient(to bottom, rgba(0, 18, 23, 0.7) 0%, rgba(0, 18, 23, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001217', endColorstr='#00001217', GradientType=0);
      }

      &:after {
        background-color: rgba(18, 28, 98, 0.7);
      }
    }

    // background image
    img {
      top: 0px;
    }
  }

  .accent-slant {
    top: -275px;
  }

  .wrap {
    text-align: center;
    color: $white;
    padding: bsu(8) bsu(0) bsu(5);
    padding-bottom: min(7%);
    @include breakpoint(large) {
      

      text-align: left;
    }
  }
  .secondary-title-wrapper {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
  }
  .secondary-title {
    margin: 0px !important;
  }

  .titlewrapper {
    display: inline-block;
  }

  //title rotate
  .primary-title, .secondary-title {
    text-align: left;
    display: inline-block;

  }
  .primary-title {

    margin-left: auto;
    text-align: center;
    display: block;
    @include breakpoint(large) {
      margin-left: rem-calc(70);
      display: inline-block;
    }
  }
  h2 {
    text-align: center;
  }
  .secondary-title-wrapper, .slick-slider {

  }
  // Prevents h2 text from shifting up/down during title transitions
  .secondary-title-wrapper {
    @include breakpoint(  zero  ) { height: 65px;  }
    @include breakpoint( medium ) { height: 75px;  }
    @include breakpoint( large  ) { height: 108px; }
  }
  .secondary-title {
    text-align: center;
    display: block;
    margin: auto;
    @include breakpoint(medium) {
      margin-left: rem-calc(70);
      display: inline-block;
    }

    &[hidden] {
      display: none;
    }

    &:before {
      content: "\00a0";
      font-size: 0em; //weird spacing fix
    }

  }
  // home page adjustments (parent selector)
  body.home & {

    .banner-slant {
      > div {
        &:after {
          background-color: initial;
        }
      }
    }

    h1 {
      @include fp(font-size, 48, 90);
    }

    h2 {
      @include fp(font-size, 12, 42);
      font-weight: 600;
      padding: bsu(1) bsu(1) 0;
      line-height: rem-calc(36px);
    }

    .banner-slant {
      height: auto;
      background-color: $white;
      img {
        height: 115%;
      }
    }

    video {
      // display: none;
      position: absolute;
      z-index: -1;
      top: 64%;
      left: 50%;
      //  min-width: 10%;
      //  min-height: 10%;
      // width: 90%;
      // height: 90%;
      transform: translateX(-50%) translateY(-50%);
    }
    @include breakpoint( zero ) {
      video {
        display: block;
        min-width: 10%;
        min-height: 10%;
        width: 90%;
        height: 90%;
        top: 75%;
        
      }
    }

    .hero-arrow {
      position: absolute;
      width: 138px;
      height: 90px;
      bottom: 60px;
      left: 50%;
      margin-left: -69px;
      z-index: 3;
    }

  }

  @include breakpoint(medium) {
    .wrap {
      padding: bsu(12) bsu(0) bsu(8);
      padding-bottom: min(2%);
    }
    .banner-slant {
      height: 240px;

      > div {
        height: 150%;

        &:before {
          height: 120px;
        }
      }
    }

    body.home & {
      .banner-slant {
        img {
          height: 115%;
        }
      }
      video {
        // width: initial;
        height: initial;
        min-width: 90%;
        min-height: 90%;
        top: 72%;
      }
    }
  }
  //medium

  @include breakpoint(large) {
    .banner-slant {
      height: 300px;

      .tek-overlay {
        margin-top: -480px;
        left: -2220px;
        width: 3213px;
        height: 1200px;
      }
    }

    .wrap {
      padding: bsu(12) bsu(0) bsu(8);
      padding-bottom: min(22%);
    }

    body.home & {
      h2 {
        padding: bsu(1) bsu(4) 0;
      }

      video {
        // width: initial;
        height: initial;
        min-width: 90%;
        min-height: 90%;
        top: 68%;
      }

      .hero-arrow {
        width: 214px;
        height: 140px;
        margin-left: -112px;
        bottom: 35px;
        @include transition-ease-in-out();

        &:hover {
          bottom: 15px;
        }
        
      }
    }
  }
  //large

  @include breakpoint(xxlarge) {

    .wrap {
      padding: bsu(16) bsu(0) bsu(12);
      padding-bottom: min(16%);
    }
  }
  //xxlarge

  @include breakpoint(mega) {
    .banner-slant {
      height: 350px;
      top: -150px;

      img {
          height: 115%;
        }
    }

    .accent-slant {
      top: -400px;
    }
  }
  //mega
}



// 20 Year intro section

#year20-intro {

  position: relative;
  z-index: 2;

  .accent-wrapper {
    position: initial;
  z-index: 0;
  top: initial;
  width: initial;
  max-width: 975px;
  left: initial;
  transform: none;
  .color-accent{
    display: none;
  }

    @include breakpoint(zero) {
      display: block;
      
      > div {
        display: block;
      }
    }
    @include breakpoint(large) {
      display: flex;
      &.accent-wrapper {
        @include flex-row();
    position: absolute;
    z-index: 3;
    top: 0px;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%);
    
      }
      .color-accent{
        display: block;
      }
    }

    > div {
      flex: 1;
      position: relative;
     
      &.year20-intro-image {
        position: relative;
        @include transition-ease-in-out();
        
        &.year20-intro-image img:nth-child(2) {
          margin-top:0px;
          @include transition-ease-in-out();
          &:hover {
            -moz-transform: translate(1%, -2.1%);
            -webkit-transform: translate(1%, -2.1%);
            transform: translate(1%, -2.1%);
      }
      }
      
       &.year20-intro-image img:nth-child(3) {
         
          margin-top:50px;
          
    }

      

        .color-accent {
          position: absolute;
          z-index: -1;
          bottom: bsu(11.6);
          left: bsu(-.6);
          width: 90%;
          height: 30%;
          background: linear-gradient(to right, $orange-light, $orange);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          @include transition-ease-in-out();

          &:before {
            @include overlay();
            background-image: url('../images/accent-triangle-overlay.png');
            background-repeat: no-repeat;
            background-position: 240% 180%;
          }
        }


      
      }
    }
    .intro-video {

      .vid-modal {
        display: none;
      }
    }
  }

  .intro_container {

    @include flex-row();

    .col {
      padding: rem-calc(15);
      flex: 1;
      &.p_space{
        padding-top: 0px;
      }

      h1 {
        font-size: 12vw;

        @include breakpoint( small ) { font-size: 4rem; }
      }

      h3 {
        padding-top: rem-calc(30);
      }
    }
    .col:nth-child(2) {
    }

    @include breakpoint(zero) {
      display: block;
      > div {
        display: block;
      }
      .col:nth-child(2) {
        margin-left: rem-calc(0);
      }
    }
    @include breakpoint(large) {
      display: flex;

      .col:nth-child(2) {
        margin-left: rem-calc(50);
      }
    }
  }

}

//center gallery title

#services-overview{
  & > h1{
    text-align: left;
    margin-left: 7%;
  }
  padding-top: 3.5rem;
} 


.p_space {
  color: green;
  p:nth-child(1) {
    margin-top: 0px;
  }
  p:nth-child(2) {
    margin-top: 0px;
  }
}
