/**
 * TEK | BUTTON COMPONENT
 */


/**
 * Angled button
 */
@mixin button-angled() {
	padding: rem-calc(26) rem-calc(28);
	text-transform: uppercase;
	font-size: rem-calc(20);
	clip-path: polygon(
		0 10px, 66px 0px,                               // top
		100% 10px, 100% calc( 100% - 10px ),            // right
		calc( 100% - 66px ) 100%, 0 calc( 100% - 10px) // bottom
	);

	&:after {
		background: linear-gradient( to right, $orange-light, $orange );
		width: 100%;
	}

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0; 
		left: 0;
		right: 0;
		background: linear-gradient( to right, #fac019, #f3661c );
		clip-path: polygon(
			0 10px, 100% calc( 100% - 10px ), 100% 100%, 0 100%
		);
	}

	// cancel hover
	&:hover:after, &:focus:after {
		left: 0;
	}

	&:visited {
		color: $white;
	}
}


/**
 * Base button
 */
@mixin button() {
	position: relative;
	z-index: 0;
	display: inline-block;
	padding: rem-calc(14) rem-calc(28) rem-calc(16);
	text-align: center;
	font-size: rem-calc(17);
	font-weight: $weight-extrabold;
	line-height: $line-height-medium;
	letter-spacing: 0.2px;
	color: $white;
	border: none;
	overflow: hidden;
	outline: none;
	cursor: pointer;

	@include transition-ease-in-out();

	// background gradient
	&:after {
		z-index: -2;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 200%;
		height: 100%;
		background: linear-gradient( 155deg, $orange-light 18%, $orange 40%, $orange 55%, $orange-light );
		@include transition-ease-in-out();
	}

	// icon
	.fa {
		margin: 0 bsu(1/4) 0 -2px;
	}

	&:hover, &:focus {
		color: $white;

		&:after {
			left: -100%;
		}
	}


	// "angled" button
	&.angled {
		@include button-angled();
	}
	&.btn-sm {
		padding: rem-calc(7) rem-calc(14) rem-calc(8);
		font-size: rem-calc(15);
		color: $white;
		margin-right: rem-calc(10);
	}
	&.btn-primary:after {
		background: linear-gradient( 155deg, $blue 18%, $blue-dark 40%, $blue-dark 55%, $blue );
	}
}

button, [role="button"], [type="submit"], [type="button"] {
	@include button();
}

/**
 * Base button for Video
 */
 .wp-video [role=button]:after, [type=button]:after, [type=submit]:after, button:after {
    background: none;
}
.mejs-overlay:hover > .mejs-overlay-button {
    background-position: 0px -39px !important;
}