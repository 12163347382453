/**
 * TEK | FOOTER
 */

footer[role="contentinfo"] {
	position: relative;
	text-align: center;
	padding: bsu(0.5);

	&:before {
		@include overlay();
		height: 10px;
		top: initial;
		bottom: 0px;
		background: $orange;
		background: linear-gradient(to right, $orange-light 0%,$orange 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange-light', endColorstr='$orange',GradientType=1 );
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.menu,
	.sub-menu {
		display: none;
	}

	section {
		padding: bsu(1) 0;
		p {
			margin: 0px;
			font-size: rem-calc(14px);
			color: $gray-darkest;
		}
	}

	@include breakpoint( large ) {

		.menu {
			display: flex;
			width: 50%;

			&#primary-left-footer {
				padding-right: bsu(1.25);
				justify-content: flex-end;
			}

			&#primary-right-footer {
				padding-left: bsu(1.25);
				justify-content: flex-start;
			}

			li {
				padding: 0 bsu(1);
			}

			a {
				color: $gray-darkest;
				font-weight: 600;

				&:hover {
					color: $orange;
				}
			}
		}

		.wrap {
			display: block;
			margin: auto;
			text-align: center;
		}

		section {
			padding: bsu(2) 0 bsu(0.5);
		}
	} //large

} // footer[role="contentinfo"]