/**
 * TEK | HOME PORTFOLIO
 */

#portfolio-front {
	position: relative;

	.intro {
		z-index: 2;
	}

	.slider-arrows {
		position: absolute;
		bottom: bsu(4);
		left: bsu(1);
		z-index: 2;

		.slick-arrow {
			background-image: url('../images/icon-arrow.png'), linear-gradient( to right, $orange-light, $orange );;
			background-repeat: no-repeat;
			background-position: center center;
			text-indent: -9999px;
			border-radius: 0px;
			background-color: $orange;

			&:after {
				display: none;
			}

			&.slick-prev {
				transform: rotate(-180deg);
				margin-right: bsu(0.25);
			}

			@include breakpoint( large ) {
				&:hover {
					&.slick-next {
						-moz-transform: rotate(0deg) scale(1.1);
						-webkit-transform: rotate(0deg) scale(1.1);
						transform: rotate(0deg) scale(1.1);
					}

					&.slick-prev {
						margin-right: bsu(0.25);
						-moz-transform: rotate(-180deg) scale(1.1);
						-webkit-transform: rotate(-180deg) scale(1.1);
						transform: rotate(-180deg) scale(1.1);
					}
				}
			}
		}
	}

	.portfolio-slider {

		.slide-bg {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			padding: bsu(10) bsu(1) bsu(6);

			@include breakpoint( medium ) {
				padding: bsu(10) bsu(1) bsu(3);
			}

			&:before {
				@include overlay();
				background: linear-gradient(to top, rgba(0,18,23,0.35) 0%,rgba(0,18,23,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001217', endColorstr='#00001217',GradientType=0 );
			}

			h2, p {
				text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36);
			}

			h2 {
				//display: none;
				color: $white;
				font-size: 10vw; // rem-calc(30px);
				max-width: 500px;
			}

			p {
				//display: none;
				color: $white;
				font-weight: 700;
				font-size: rem-calc(14px);
				margin-top: bsu(0);

				span {
					color: $orange-light;
					font-size: rem-calc(24px);
					padding-right: bsu(0.25);
				}
			}
		}
	}

	.slant-container {
		margin-top: 0px;
		top: -50px;
	}

	@include breakpoint( medium ) {
		.intro {
			display: none;
		}

		.slider-arrows {
			bottom: bsu(5);
			left: bsu(1);
		}

		.portfolio-slider {

			.slide-bg {
				padding: bsu(7.5) bsu(1) bsu(8);

				h2 {
					display: block;
					font-size: rem-calc(60px);
					max-width: 500px;
				}

				p {
					display: block;
				}
			}
		}
	} //medium

	@include breakpoint( large ) {
		.slider-arrows {
			bottom: bsu(7);
		}
		.portfolio-slider {
			.slide-bg {
				padding: bsu(11) bsu(1) bsu(11);
			}
		}
	} //large

	@include breakpoint( xlarge ) {
		.slider-arrows {
			max-width: 1140px;
			left: 50%;
			margin-left: -580px;
		}
	} //xlarge

	@include breakpoint( mega ) {
		.slider-arrows {
			bottom: bsu(7);
		}
		.portfolio-slider {
			.slide-bg {
				padding: bsu(13) bsu(1) bsu(11.5);
			}
		}
	} //mega
}