/**
 * TEK | TIPS
 */

#tips-intro {

  position: relative;
  z-index: 2;

  .accent-wrapper {
    @include flex-row();
    position: absolute;
    z-index: 3;
    top: 0px;
    width: 100%;
    max-width: 975px;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(zero) {
      display: none;
    }
    @include breakpoint(large) {
      display: flex;
    }

    > div {
      flex: 1;
      position: relative;
      &.intro-image {
        position: relative;
        @include transition-ease-in-out();

        .color-accent {
          position: absolute;
          z-index: -1;
          bottom: bsu(-6);
          left: bsu(-1);
          width: 70%;
          height: 70%;
          background: linear-gradient(to right, $orange-light, $orange);
          -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
          overflow: hidden;
          @include transition-ease-in-out();

          &:before {
            @include overlay();
            background-image: url('../images/accent-triangle-overlay.png');
            background-repeat: no-repeat;
            background-position: 240% 180%;
          }
        }

        &:before {
          @include overlay();
          background-image: url("../images/general-image-overlay-faceting-effect.png");
          background-repeat: no-repeat;
          background-position: 0% 0%;
          background-size: 100%;
        }

        &:hover {
          -moz-transform: translate(-1%, 1%);
          -webkit-transform: translate(-1%, 1%);
          transform: translate(-1%, 1%);

          .color-accent {
            bottom: bsu(-6.2);
            left: bsu(-1.2);
          }
        }
      }
    }
    .intro-video {

      .vid-modal {
        display: none;
      }
    }
  }

  .intro_container {

    @include flex-row();

    .col {
      padding: rem-calc(15);
      flex: 1;

      .tek-tips-logo img {
        min-width: 256px;

        @include breakpoint( medium ) { width: 350px; }
      }

      h3 {
        padding-top: rem-calc(30);
      }
    }
    .col:nth-child(2) {
      margin-left: rem-calc(50);
    }
    @include breakpoint(zero) {
      display: block;
      > div {
        display: block;
      }
      .col:nth-child(2) {
        margin-left: rem-calc(0);
      }
    }
    @include breakpoint(large) {
      display: flex;

      .col:nth-child(2) {
        margin-left: rem-calc(50);
      }
    }
  }
}

#tips-resources {
  position: relative;
  margin-bottom: 14rem;

  @include breakpoint ( large ) { margin-bottom: 0; }

  .tips-container {
    position: relative;

    .patch {
      width: 100%;
      height: 700px;
      position: absolute;
      bottom: -100px;
      background-color: $gray-lighter;
    }

    .slant-container {

      top: -250px;
      margin-bottom: 0;

      @include breakpoint(zero) {
        top: 0;
      }
      @include breakpoint(large) {
        top: -150px;
      }

      .dual-slant-overlay.reverse {
        transform: translate(0, -36px) rotate(-8deg);
      }

      .slant {
        padding: rem-calc(150) rem-calc(100) rem-calc(25);
        background: $orange-light;

        > div {

          padding: bsu(2) 0 bsu(6);
          &:before {
            @include overlay();
            background-repeat: no-repeat;
            background-position: 0% 50%;
            height: 110%;
            mix-blend-mode: multiply;
            @include breakpoint( xlarge ) {
              background-image: url('../images/resources-facet-overlay@2x.png');
            }
          }
        }
        // background image
        > div > img {
          @include cover-image();
          position: absolute;
          z-index: -2;
          top: -25%;
          left: 0px;
          height: 150%;
        }

        h2 {
          color: $white;
          padding-bottom: rem-calc(20);
          padding-right: 2rem;
          padding-left: 2rem;
          text-align: center;
          flex: 1 1 100%;
          z-index: 2;
          width: 80%;
          margin: 0 auto;

          @include breakpoint( medium ) {
            width: 100%;
          }
        }

        .tips_slider {

          .slick-slide:not(.slick-active) {
            
            &:before { visibility: hidden; }
          }

          .slick-arrow {
            position: relative;
            background-image: url('../images/arrow-right-icon-white.png');
            background-repeat: no-repeat;
            background-position: center center;
            text-indent: -9999px;
            border-radius: 0px;
            background-color: transparent;

            &:before {
              @include overlay();
              @include icon( '\f061' );
              color: $white;
              text-align: center;
            }

            &:after {
              display: none;
            }

            @include breakpoint( medium ) {
              position: absolute;
              z-index: 2;
              top: 50%;

              &.slick-prev {
                margin-left:0;
                left: -50px;
              }

              &.slick-next {
                left: initial;
                top: 50%;
                right: -50px;
              }
            }

            @include breakpoint( large ) {
              &:hover {
                &.slick-next {
                  -moz-transform: rotate(0deg) scale(1.1);
                  -webkit-transform: rotate(0deg) scale(1.1);
                  transform: rotate(0deg) scale(1.1);
                }

                &.slick-prev {
                  margin-right: bsu(0.25);
                  -moz-transform: rotate(-180deg) scale(1.1);
                  -webkit-transform: rotate(-180deg) scale(1.1);
                  transform: rotate(-180deg) scale(1.1);
                }
              }
            }

            @include breakpoint( xlarge ) {
              &.slick-prev {
                left: -100px;
              }

              &.slick-next {
                right: -100px;
              }
            }
          }

           .slick-prev {
              transform: rotate(-180deg);
              margin-left: 30%;
            }

            .slick-next {
              position: absolute;
              left: 55%;
              top: 0;
              z-index: 2;


            }

            @include breakpoint( medium ) {
              .slick-prev {
                margin-left: 10%;
              }

              .slick-next {
                left: 20%;
              }
            }

            @include breakpoint( large ) {
              .slick-prev {
                margin-left: 5px;
              }

              .slick-next {
                left: 8%;
              }
            }

          .tips-wrapper {
            color: $white;
            @include flex-row();
            flex-flow: row wrap;
            max-width: 945px;
            margin: 0 auto;
            justify-content: center;

            @include breakpoint ( large ) {
              justify-content: flex-start;
            }

            > * {

              @include breakpoint( zero ) {
                width: 70%;
              }
              @include breakpoint( small ) {
                width: 51%;
              }
              @include breakpoint( medium ) {
                width: 50%;
              }
              @include breakpoint( large ) {
                width: 33%;
              }
            }

            .col {
              padding: rem-calc(5);
              position: relative;
              background-color: $white;
              background-clip: content-box;

              .tip-box {
                color: $black;
                background: $white;
                height: 100%;
                padding: rem-calc(20);
                margin-bottom: rem-calc(25);
                line-height: 2;
                clear: both;

                .vid-modal {
                  display: none;
                }

                div.buttons-placeholder {
                  visibility: hidden;
                  float:left;
                  width: 260px;
                  height: 50px;
                  margin-bottom: 1rem;
                }

                div.buttons {
                  position: absolute;
                  bottom: rem-calc(15);
                }

                h4 {
                  color: $black;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                }
                p {
                  margin: rem-calc(15) 0;
                }
              }
            }

            h2, nav {
              padding-bottom: rem-calc(20);
              padding-right: 2rem;
              padding-left: 2rem;
              text-align: center;
              flex: 1 100%;
            }
            nav {
              margin-top: rem-calc(20);
              span.current {
                text-decoration: underline;
                font-weight: $weight-bold;
              }
              a {

                color: $white;
                &:visited, &:active, &:hover {
                  color: $orange-red;
                }
              }
            }

          }
        }



        @include breakpoint(zero) {
          width: 200%;
          left: -50%;
          padding: rem-calc(0) rem-calc(0) rem-calc(100);
          > div {
            padding: bsu(1) bsu(1) bsu(1);
          }
          .wrap {
            padding: 0 rem-calc(60) 0 rem-calc(50);
            justify-content: center;
          }
        }
        @include breakpoint(medium) {
          width: 130%;
          left: -15%;
          > div {
            padding: bsu(2) 0 bsu(1);
          }
        }
        //medium

        @include breakpoint(large) {
          width: 130%;
          left: -15%;
          padding: rem-calc(100) rem-calc(0) rem-calc(120);
          > div {
            padding: bsu(2) 0 bsu(2);
          }.wrap {
             padding: 0;
             justify-content: flex-start;
           }

        }
        //large

        @include breakpoint(xlarge) {

          > div {
            padding: bsu(2) 0 bsu(3);
          }
        }
        //xlarge

        @include breakpoint(mega) {
          // background image
          > div > img {
            top: 0;
          }

          > div {
            padding: bsu(4) 0 bsu(5);
          }
        }
        //mega
      }

      @include breakpoint(xlarge) {
        .slant {
          .tek-overlay {
            display: block;
          }

          > div {
            &:before {
              @include overlay();
              background-position: 0% 20%;
              background-size: cover;
              height: 200%;
            }
          }
        }
      }
      //large
    }

  }

}

#tips-glossary {

  position: relative;
  z-index: 1;
  background: $gray-lighter;
  margin-top: -350px;
  padding-bottom: rem-calc(80);
  h1 {
    text-align: center;
    margin-bottom: rem-calc(40);
  }
  @include breakpoint( zero ) {
    margin-top: -150px;
  }
  @include breakpoint( large ) {
    margin-top: 0; //-350px;
  }
  .glossary_container {
    @include flex-row();
    .progress {
      display: block;
      text-align: center;
      width: 0;
      height: 3px;
      background: $gray-lighter;
      transition: width .3s;
    }
    .progress.hide {
      opacity: 0;
      transition: opacity 1.3s;
    }
    .col {
      padding: rem-calc(15);
      flex: 1;
      > dl {
        max-height: 60vh;
        overflow-y: scroll;
      }

      dl {
        position: relative;
        margin: 0;
        padding: 0;

        dt {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-weight: $weight-extrabold;
          font-size: rem-calc(36);
        }
        dd.group {
          background: $gray-lightest;
          margin: rem-calc(10) 0;
          padding: rem-calc(20);
          border-radius: rem-calc(5);
          &:hover {
            background: $white;
          }
        }
        dd a {
          // width: 250px;
          display: block;
          margin-left: rem-calc(20);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $blue;
          font-weight: $weight-bold;

        }
        dd.active {
          position: relative;
          a {
            color: $orange-light;
            position: relative;
          }
          &:before, &:after {
            content: '';
            border-radius: 50%;
            display: inline-block;
            position: absolute;
          }

          &:before {
            top: 5px;
            left: rem-calc(0);
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            border: solid $orange-light 5px;
            background: linear-gradient(to top, $orange, $orange-light) 1 100%;
            clip-path: circle(50% at 50% 50%);

          }
        }
      }
    }
    .col:nth-child(2) {
      margin-left: rem-calc(1);
      margin-top: rem-calc(25);
      flex: 2;
      padding: rem-calc(50);
      border-radius: rem-calc(5);
      background: $white;
      -webkit-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      -moz-box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);
      box-shadow: 0px 0px 45px 9px rgba(112, 112, 112, 0.1);

      h4 {
        color: $black;
        margin-bottom: rem-calc(25);
      }

      div.item_image {
        margin-top: rem-calc(15);
      }

      p.definition {
        color: $blue;
        font-weight: $weight-bold;
      }

      .details {
        @include breakpoint ( large ) {
          clear: both;
        }
      }

      blockquote {
        border: solid 3px $gray-lighter;
        border-radius: rem-calc(5);
        margin: rem-calc(50) 0;
        padding: rem-calc(100) rem-calc(20) rem-calc(20);
        position: relative;
        &:before {
          @include icon('\f06a');
          font-size: rem-calc(72);
          position: absolute;
          left: 50%;
          top: 50px;
          transform: translateY(-50%);
          margin: 0;
          margin-left: -40px;
          line-height: rem-calc(72);
          padding-left: rem-calc(5);
          overflow: hidden;
          background: -webkit-gradient(linear, left top, left bottom, from($orange), to($orange-light));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // display: none;
        }

        @include breakpoint( large ) {
          padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(100);
          &:before {
            left: rem-calc(20);
            top: 50%;
            margin: 0;
          }
        }

      }
      a.download_link {
        font-weight: $weight-extrabold;
        color: $blue;
        position: relative;
        display: block;
        padding-left: rem-calc(30);
        margin-top: rem-calc(20);
        &:before {
          @include icon('\f0a9');
          font-size: rem-calc(24);
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;

          line-height: rem-calc(25);
          padding-left: rem-calc(5);
          overflow: hidden;
          background: -webkit-gradient(linear, left top, left bottom, from($orange), to($orange-light));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: initial;
        }
      }
    }
    @include breakpoint( zero ) {
      display: block;
      .col {
        > dl {
          max-height: 26vh;
          overflow-y: scroll;
        }
      }
    }
    @include breakpoint( medium ) {
      display: flex;
      .col {
        > dl {
          max-height: 60vh;
          overflow-y: scroll;
        }
      }
    }

  }

}

.vid-modal video {
  width: 95vw;
  height: auto;

  @include breakpoint( medium ){
    width: 75vw;
    height: auto;
  }
  @include breakpoint( xlarge ){
    width: 50vw;
    height: auto;
  }
}