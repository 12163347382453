#virtual-intro {
    text-align: center;
    padding: rem-calc(40) 0;
  
  
}


#virtual-description {
  position: relative;
  z-index: 2;
  padding-bottom: 5%;

  .virtual-logo-container {
    text-align: center;

    img {
      width: 100%;
      max-width: 280px;
    }
  }

  .description_container {
    @include breakpoint ( zero ) { .alignright {
      float: none;
    }}
    @include breakpoint ( small ) { .alignright {
      float: right;
    }}

   

    @include flex-row();

    .col {
      padding: rem-calc(15);
      flex: 1;

      > div {
        text-align: center;

        @include breakpoint ( medium ) { text-align: left; }
      }

      h3 {
        padding-top: rem-calc(30);
      }
    }
    .col:nth-child(1) {
      img {
        max-width: 80%;
      }
    }
    .col:nth-child(2) {
      margin-left: rem-calc(50);
    }

    .description-image {
      position: relative;

      .vid-thumb {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 0;
        padding: rem-calc(100);
        cursor: pointer;

        &:before,
        &:after {
          @include overlay();
        }
        &:before {
          background-color: rgba($green-darker, 0.4);
          background-clip: content-box;
          z-index: 1;
          top: 0%;
          padding: rem-calc(100);
        }
        &:after {
          @include icon('\f04b');
          z-index: 2;
          font-size: rem-calc(48);
          color: $white;
          margin: auto;
          width: auto;
          height: auto;
          padding: rem-calc(100);
          box-sizing: padding-box;
          left: 25%;
          top: 25%;
          margin-left: rem-calc(-24);
        }
      }

      &:before {
        @include overlay();
        background-image: url("../images/general-image-overlay-faceting-effect.png");
        background-repeat: no-repeat;
        background-position: 0% 0%;
        background-size: 100%;
      }
      > div.tooltip-container {

        .tooltip-trigger {
          top: 0px;
          left: 0px;
          width: 500px;
          z-index: 0;
          -webkit-backface-visibility: hidden;

          &:hover { z-index: 3; }
          
          .tooltip-circle {
            position: absolute;
            top: -15px;
            left: -15px;
          }

          section.tips-popup {
            @include transition-ease-in-out();
            background-color: $white;
            box-shadow: 22px 22px 52px 0 rgba(0,0,0,.1);
            padding: 2rem 3rem;
            z-index: 1;

            img {
              width: 40%;
              margin-bottom: 1rem;
            }

            h4 {
              color: $black;
              margin-bottom: 1rem;
            }

            p {
              margin-bottom: 1rem;
            }

            a.arrow {
              @include transition-ease-in-out();
              color: $blue;

              span {
                background-image: url(../images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
                background-repeat: no-repeat;
                background-position: 50%;
                border-radius: 50%;
              }

              &:hover {
                color: $orange;
              }
            }

          }
        }

        .tooltip-trigger.closed > section.tips-popup { 
          opacity: 0;
        }
        .tooltip-trigger.open   > section.tips-popup { 
          opacity: 1;
        }
      }
    }
    .description-video {

      .vid-modal {
        display: none;
      }
    }

    @include breakpoint(zero) {
      display: block;
      div.col:nth-child(2) {
        margin-left: 0;
        margin-top: 3rem;
      }
      .description-image {
        max-width: 50%;
        margin: auto;
        > img, &:before {
          display: none;
        }
        .vid-thumb {
          position: static;
          -webkit-transform: none;
          -moz-transform: none;
          -ms-transform: none;
          -o-transform: none;
          transform: none;
          padding: 0;

          &:before, &:after {
            padding: 0;

          }
          &:after {
            left: 50%;
            top: 50%;
          }
        }
      }
      .description_caption {
        color: #1e2e61;
      }

    }

    @include breakpoint(large) {
      display: flex;

      div.col:nth-child(2) {
        margin-left: rem-calc(50);
      }
      .description-image {
        max-width: inherit;
        > img, &:before {
          display: block;
        }
        .vid-thumb {
          position: absolute;
          top: 50%;
          transform: translateX(-45%) translateY(-50%);
          line-height: 0;
          padding: rem-calc(100);

          &:before,
          &:after {
            @include overlay();
          }
          &:before {
            background-color: rgba($green-darker, 0.4);
            background-clip: content-box;
            z-index: 1;
            top: 0%;
            padding: rem-calc(100);
          }
          &:after {
            @include icon('\f04b');
            z-index: 2;
            font-size: rem-calc(48);
            color: $white;
            margin: auto;
            width: auto;
            height: auto;
            padding: rem-calc(100);
            box-sizing: padding-box;
            left: 25%;
            top: 25%;
            margin-left: rem-calc(-24);
          }
        }
        

      }

    }

  }
}
.description-small-image-container {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  width: 28%;
  transform: translateX(73%) translateY(-85%);
  @include breakpoint(medium) {
    display: none;
  }
  @include breakpoint(zero) {
    display: none;
  }

  @include breakpoint(large) {
    display: flex;
  }


}
  
.description-image2{
  flex:1;
  
}

.description-image3{
  position: absolute;
  top: 70%;
  transform: translateX(-90%) translateY(-50%);
  line-height: 0;
}

.virtual-form-btn {
display: flex;
justify-content: center; 
position: relative;
top: -2.3em;
z-index: 1;
margin: auto;
&.flip{

}
}

.virtual-form {
  &.form-wrapper {
    background: white;
    box-shadow: 0 0 133px rgba(0, 0, 0, 0.08);
    padding: bsu(1);
    margin-bottom: bsu(2);

    @include breakpoint(large) {
      flex-basis: 70%;
      padding: 10px 80px 40px 80px;
      margin: 0px 15% 30px 15%;
    }

    .gform_wrapper {
      padding: 0;

      label {
        text-align: left;
        line-height: rem-calc(40);
        vertical-align: sub;
      }
      @include breakpoint(large){
        label {
          text-align: left;
          line-height: rem-calc(40);
        }
      }
      input, textarea, select, .select2, .select2-container--default .select2-selection--single {
        background: linear-gradient(to left, $gray-lighter, $gray-lightest);
        outline: 0;
        border: 0;
      }
      input[type="text"], select {
        width: 100%;
        min-height: rem-calc(40);
        padding-left: rem-calc(15);

        @include breakpoint ( medium ) { width: 70%; }
      }
      input[type="submit"] {
        background: linear-gradient(to right, $orange-light, $orange);

        &:hover {
          transform: scale(1.06);
        }
      }
      .select2 {
        width: 70% !important;
        min-height: rem-calc(40);
        line-height: rem-calc(40);
        &.select2-container--default {
          .select2-selection--single {
            padding-top: rem-calc(7);
          }
          .select2-selection__arrow {
            top: rem-calc(7);
          }
        }
      }
    }
    h2 {
      font-size: 24px;
      color: $black;
      padding: 20px 10px;
      @include breakpoint(medium) {
        padding-bottom: bsu(1);
        padding-top: 0px;
      }
      span {
        font-size: 15px;
        display: block;
        font-weight: 400;
        line-height: 18px;
      }
      &::before {
        content: "\f0e6";
        display: inline-block;
        font-family: FontAwesome;
        padding-right: 20px;
        padding-bottom: 15px;
        font-size: calc(.83333vw + 19.33333px);
      }
    }
    &.panel{
      display: none;
    }}
  }

  .gform_wrapper ul.gform_fields li.gfield, .gform_footer{
    @include breakpoint(medium){
      margin-left: 29% !important;
    }
   
  }

.gfield_error{
  @include breakpoint(medium){ 
    width: 50% !important;
  }
}

.virtual-container > h1 {
  text-align: center;
  padding-bottom: rem-calc(40);
}

#virtual-overview {
  position: relative;

  .virtual-container {

    .slant-container {
      z-index: 1;
      top: -300px;
      margin-bottom: -200px;

      @include breakpoint(zero) {
        top: -50px;
        margin-bottom: 1rem;
        .slant{
          width: 100%;
          left: initial;
          transform: rotate(0deg) translateY(0px);
         // overflow: initial;
        &.reverse{ 
         &> div {
          transform: rotate(0deg);
          -webkit-clip-path: polygon(0 4%,100% 0,100% 98%,0 94%);
          clip-path: polygon(0 4%,100% 0,100% 98%,0 94%);
          background: #fff;
        }
        }
        }
      }
      @include breakpoint(large) {
        top: -300px;
        margin-bottom: -200px;
       // .slant{
          //width: 120%;
          //left: -10%;
         // transform: rotate(-8deg) translateY(-20px);
       // }
        .slant.reverse > div {
          transform: rotate(-8deg);
          -webkit-clip-path: polygon(0 15%,100% 0,100% 100%,0 95%);
          clip-path: polygon(0 15%,100% 0,100% 100%,0 95%);
          background: #fff;
      }
      }
      @include breakpoint(large) {
        
        .slant.reverse > div {
          transform: rotate(-6deg);
          -webkit-clip-path: polygon(0 15%,100% 0,100% 100%,0 95%);
          clip-path: polygon(0 15%,100% 0,100% 100%,0 95%);
          background: #fff;
      }
      }

      .accent-wrapper {
        @include flex-row();
        position: absolute;
        z-index: 3;
        top: 250px;
        width: 100%;
        max-width: 975px;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(zero) {
          display: none;
        }
        @include breakpoint(large) {
          display: flex;
        }

        > div {
          flex: 1;
          position: relative;
          @include transition-ease-in-out();

          &:last-child {
            pointer-events: none;
          }

          .color-accent {
            position: absolute;
            z-index: -1;
            bottom: bsu(-6);
            left: bsu(-1);
            width: 70%;
            height: 70%;
            background: linear-gradient(to right, $orange-light, $orange);
            -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
            clip-path: polygon(0% 0%, 0% 0%, 0% 50%, 100% 50%, 0% 0%);
            overflow: hidden;
            @include transition-ease-in-out();

            &:before {
              @include overlay();
              background-image: url('../images/accent-triangle-overlay.png');
              background-repeat: no-repeat;
              background-position: 240% 180%;
            }
          }

          &:hover {
            transform: translate(-1%, 1%);

            .color-accent {
              bottom: bsu(-6.2);
              left: bsu(-1.2);
            }
          }
        }
        // Tool Tip Pop-up
        > div.tooltip-container {

          .tooltip-trigger {
            top: 0px;
            left: 0px;
            width: 500px;
            z-index: 0;
            -webkit-backface-visibility: hidden;

            &:hover { z-index: 3; }
            
            .tooltip-circle {
              position: absolute;
              top: -15px;
              left: -15px;
            }

            section.tips-popup {
              @include transition-ease-in-out();
              background-color: $white;
              box-shadow: 22px 22px 52px 0 rgba(0,0,0,.1);
              padding: 2rem 3rem;
              z-index: 1;

              img {
                width: 40%;
                margin-bottom: 1rem;
              }

              h4 {
                color: $black;
                margin-bottom: 1rem;
              }

              p {
                margin-bottom: 1rem;
              }

              a.arrow {
                @include transition-ease-in-out();
                color: $blue;

                span {
                  background-image: url(../images/icon-arrow.png),linear-gradient(90deg,#f6b830,#ff731a);
                  background-repeat: no-repeat;
                  background-position: 50%;
                  border-radius: 50%;
                }

                &:hover {
                  color: $orange;
                }
              }

            }
          }

          .tooltip-trigger.closed > section.tips-popup { 
            opacity: 0;
          }
          .tooltip-trigger.open   > section.tips-popup { 
            opacity: 1;
          }
        }

      }

      
      .slant {
        .tek-overlay {
          display: none;
          opacity: 0.5;
          top: 25%;
          left: 50%;
          width: 2142px;
          height: 800px;
        }

        > div {
          @include breakpoint(zero){
            &:before {
              @include overlay();
             // background-image: url('../images/overview-facet-overlay@2x.png');
              //background-repeat: no-repeat;
              //background-position: 20% 50%;
              height: 110%;
              background-blend-mode: multiply;
              mix-blend-mode: multiply;
              z-index: 1 !important;
              background-color: #0281a6;
            }
          }
          @include breakpoint(large){
            &:before {
              @include overlay();
              background-image: url('../images/overview-facet-overlay@2x.png');
              background-repeat: no-repeat;
              background-position: 20% 50%;
              height: 110%;
              background-blend-mode: multiply;
              mix-blend-mode: multiply;
              z-index: 1 !important;
              background-color: initial;
            }
          }

          
        }
        // background image
        > div > img {
          @include cover-image();
          position: absolute;
          z-index: 0; // Negative values cause cross-browser issues
          top: -25%;
          left: 0px;
          height: 150%;
        }
        .wrap {

          color: $white;
          min-height: rem-calc(400);
          @include flex-row();
          z-index: 2;

          .col {
            padding: 0 rem-calc(5);
            flex: 1;

          }
          .col:nth-child(2) {
            margin-left: rem-calc(100);
          }
          h1 {
            padding: rem-calc(20) 0 rem-calc(20) 0 ;
          }

          @include breakpoint(zero) {
            margin-bottom: 30%;
            flex-flow: row wrap;
            padding-top: 5em;
            h1 {
              text-align: center;
            }
            
            .virtual-main-text{
              text-align: center;
              margin:auto;
              color: white;
            }
            
            .items{
              display: flex;
              flex-flow: column;
              li{
                text-align: center;
                margin-bottom: 5%;
                p{
                  color: white;
                  margin: 0px;
                }
              }
              
            }

            .virtual-bottom-text{
              font-size: 1.5rem;
              text-align: center;
              margin:auto;
              color: white;
              padding-top: 2rem;
            }
            
          }
          @include breakpoint(large) {
            padding-top: 2em;
            margin-bottom: initial;
            padding-bottom: 2em;
            flex-flow: row wrap;
            h1 {
              margin: auto;
            }
            
            .virtual-main-text{
              text-align: center;
              margin:auto;
              color: white;
            }
            
            .items{
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              li{
                flex: 0 32%;
                text-align: center;
                margin-bottom: 5%;
                p{
                  color: white;
                  margin: 0px;
                }
              }
            }
            .virtual-bottom-text{
              font-size: 2.4rem;
              text-align: center;
              color: white;
              padding-top: initial;
            }
          }



          .overview-list {
            @include flex-row();
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: rem-calc(100);
            padding-left: bsu(1);

            @include bulleted-list();
            li {
              flex: 1 0 auto;
              line-height: 1.75em;
              padding-right: rem-calc(2);
              padding-bottom: rem-calc(8);
            }

            @include breakpoint(zero) {
              li {
                flex: 1 100%;
                margin-left: rem-calc(10);
              }
            }
            @include breakpoint(medium) {
              li {
                flex: 1 50%;
                max-width: 50%;
                margin-left: 0;
              }
            }
            @include breakpoint(medium) {
              padding-left: 0;
            }
          }
        }

        @include breakpoint(zero) {
          > div {
            padding: bsu(5) 0 bsu(1);
          }
        }

        @include breakpoint(medium) {
          > div {
            padding: bsu(4) 0 bsu(1);
          }
        }
        //medium

        @include breakpoint(large) {
          > div {
            padding: bsu(4) 0 bsu(4);
          }
            width: 120%;
            left: -10%;
            transform: rotate(6deg);

          
        }
        //large

        @include breakpoint(xlarge) {

          > div {
            padding: bsu(4) 0 bsu(4);
          }
        }
        //xlarge

        @include breakpoint(mega) {
          // background image
          > div > img {
            top: 0;
          }

          > div {
            padding: bsu(4) 0 bsu(4);
          }
        }
        //mega
      }

      @include breakpoint(xlarge) {
        .slant {
          .tek-overlay {
            display: block;
          }

          > div {
            &:before {
              @include overlay();
              background-position: 0% 20%;
              background-size: cover;
              height: 125%;
            }
          }
        }
      }
      //large
    }

  }
  .overview-gallery {
    position: relative;
    z-index: 2;
  }

  .slider-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: rem-calc(-44);
    left: 0px;
    z-index: 2;

    .slick-arrow {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;

      &:after {
        display: none;
      }

      &.slick-prev {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }
  .slider-arrows-P {
    position: absolute;
    top: 50%;
    margin-top: rem-calc(-44);
    left: 0px;
    z-index: 2;

    .slick-arrow-P {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;


      &:after {
        display: none;
      }

      &.slick-prev-P {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev-P {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }
  .slider-arrows-N {
    position: absolute;
    top: 50%;
    margin-top: rem-calc(-44);
    right: 0px;
    z-index: 2;
    

    .slick-arrow-N {
      background-image: url('../images/icon-arrow.png'), linear-gradient(to right, $orange-light, $orange);
      background-repeat: no-repeat;
      background-position: center center;
      text-indent: -9999px;
      border-radius: 0px;
      background-color: $orange;

      

      &:after {
        display: none;
      }

      &.slick-prev {
        transform: rotate(-180deg);
      }
      &.slick-next {
        float: right;
      }

      @include breakpoint( large ) {
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);

          &.slick-prev {
            margin-right: bsu(0.25);
            -moz-transform: rotate(-180deg) scale(1.1);
            -webkit-transform: rotate(-180deg) scale(1.1);
            transform: rotate(-180deg) scale(1.1);
          }
        }
      }
    }
  }



  .services-slider {
    .gallery-wrapper {
      padding: bsu(0.25);
    }
    .slide-bg {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: bsu(5);

      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 0;
        content: "";
      }

      h2, p {
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.36);
      }

      h2 {
        display: none;
        color: $white;
      }

      p {
        color: $white;
        font-weight: 700;
        font-size: rem-calc(14px);
        margin-top: bsu(0);

        span {
          color: $orange-light;
          font-size: rem-calc(24px);
          padding-right: bsu(0.25);
        }
      }
    }
  }
}