/**
 * TEK | VARIABLES
 */

/**
 * Primary & Secondary Colors
 */
$purple				: #534e81;
$blue				: #0281a6;
$blue-dark			: #01478b;
$orange-light		: #f6b830;
$orange				: #ff731a;
$orange-red			: #c14a19;
$green				: #699e22;
$green-dark			: #007d9c;
$green-darker		: #006e88;

/**
 * Neutral & Support Colors
 */
$gray				: #666666;
$gray-darkest		: #0f0f0f;
$gray-lighter		: #f5f5f5;
$gray-lightest		: #fafafa;
$white				: #ffffff;
$black				: #000000;
$transparent		: rgba( $white, 0 );


/**
 * Fonts & typography spacing
 */
$line-height-normal	: 1.99;
$line-height-medium	: 1.5;
$line-height-xsmall	: 1.2;

$global-font-size	: 15px;
$global-line-height	: $line-height-normal;
$font-body			: 'Raleway', sans-serif;
$font-icon			: 'FontAwesome';

$weight-regular		: 500;
$weight-bold		: 600;
$weight-extrabold	: 800;


/**
 * Spacing
 */
$bsu: 1.99rem;

@function bsu( $multiplier ) {
	@return $multiplier * $bsu;
}