/**
 * TEK | MIXINS
 */


/**
 * Object-fit "cover"
 */
@mixin cover-image() {
	object-fit: cover;
	width: 100%;
	height: 100%;
}


/**
 * Centered block
 */
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


/**
 * Centered element trick!
 */
@mixin absolute-center( $extra-transforms: "", $top: 50%, $left: 50% ) {
	position: absolute;
	top: $top;
	left: $left;
	width: auto;
	min-width: 100%;
	min-width: 100vw;
	max-width: 1000%;
	height: auto;
	min-height: 100%;
	padding-bottom: 1px;
	transform: translateX( -50% ) translateY( -50% ) unquote( $extra-transforms );
}


/**
 * Make a flex "row"
 */
@mixin flex-row( $align-center: false, $inline-flex: false ) {

	@if $inline-flex {
		display: inline-flex;
	} @else {
		display: flex;
	}

	flex-flow: row nowrap;	

	@if $align-center {
		align-items: center;
	}
}


/**
 * Font Icon
 *
 * @param string  $content  escaped content code, such as FontAwesome's \1055
 */
@mixin icon( $content ) {

	@if $content == 'check' {
		$content: '\f00c';
	}

	content: $content;
	font-family: $font-icon;
	font-weight: 400;
}


/**
 * Remove bottom margin if last child
 */
@mixin no-bottom-if-last() {

	&:last-child {
		margin-bottom: 0;
	}
}


/**
 * Remove top margin if first child
 */
@mixin no-top-if-first() {

	&:first-child {
		margin-top: 0;
	}
}


/**
 * Text gradient
 */
@mixin text-gradient( $top: $orange-light, $bottom: $orange ) {
	background: linear-gradient( $top, $bottom );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}


/**
 * List
 */
 @mixin list() {
	 color: $gray;
	 
	li {
		margin: bsu(1/2) 0;
		position: relative;
		padding-left: bsu(1);

		&:before, &:after {
			position: absolute;
		}
	}

	// nested ul
	ul, ol {
		margin-top: 0;

		li {
			margin: bsu(1/5) 0;
		}
	}

	
	@include breakpoint( medium ) {
		
		li {
			margin: bsu(2/3) 0;
		}
	}
}


/**
* Bulleted list
*/
@mixin bulleted-list() {

	// bullet
	li {
		position: relative;
		padding-left: 2rem;
		&:before, &:after {
			content: '';
			border-radius: 50%;
			display: inline-block;
			position: absolute;
		}

		&:before {
			top: 5px;
			left: 0;
			width: 16px;
			height: 16px;
			box-sizing: border-box;
			border: solid $orange-light 5px;
			background: linear-gradient( to top, $orange, $orange-light ) 1 100%;
			clip-path: circle(50% at 50% 50%);

		}

		/*&:after {
			top: 10px;
			left: 5px;
			width: 6px;
			height: 6px;
			background: $white;
		}*/
	} // li
	
	// nested ul
	ul li {
		padding-left: bsu(2/3);

		&:before, &:after {
			width: auto;
			height: auto;
			top: auto;
			background: none;
		}

		&:before {
			content: '-';
			color: $orange;
			font-size: rem-calc(18);
			font-weight: $weight-bold;
			line-height: 1.5;
		}
	}
}


/**
 * Numbered list
 */
@mixin numbered-list() {
	counter-reset: section;

	// icon
	li:before {
		counter-increment: section;
		content: counters( section, "." ) ".";
		left: 0;
		top: -1px;
		font-size: rem-calc(24);
		line-height: 1;
		font-weight: $weight-extrabold;
		color: $orange;
		@include text-gradient();
	}

	// nested list
	ol li {
		padding-left: bsu(1.25);

		&:before {
			font-size: rem-calc(18);
			line-height: $line-height-medium;
		}
	}
}


/**
 * WYSIWYG Content Format
 */
@mixin wysiwyg() {

	h1, h2, h3, h4, h5, h6 {
		margin-top: bsu(1.2);
	}
	
	h1, > h2, > h3, > h4, > h5, > h6, > p, > ol, > ul, > img {
		@include no-bottom-if-last();
		@include no-top-if-first();
	}
	
	// bulleted list
	ul, ol {
		@include list();
		@include no-bottom-if-last();
		margin-top: bsu(1);
		margin-bottom: bsu(1.5);
	}

	ul {
		@include bulleted-list();
	}

	ol {
		@include numbered-list();
	}

	// button spacing
	[role="button"] {
		margin: bsu(1/4) bsu(1/4) bsu(1/4) 0;
	}
}


/**
 * Kill font smoothing
 */
 @mixin kill-font-smoothing() {
	-webkit-font-smoothing: auto;
}


/**
 * Fluid Property!
 */
@mixin fp( $property, $min, $max, $start: 320, $end: 1280, $clip: true, $clipAtStart: true, $clipAtEnd: true ) {

	$multiplier: ($max - $min) / ($end - $start) * 100;
	$adder: ($min * $end - $max * $start) / ($end - $start);
	$formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});

	@if $clip and $clipAtStart {
		@media (max-width: #{$start + 0px}) {
			#{$property}: $min + 0px;
		}
	}
	@if $clip and $clipAtEnd {
		@media (min-width: #{$end + 0px}) {
			#{$property}: $max + 0px;
		}
	}

	#{$property}: $formula;

} // fp()

@function fp-calc( $min, $max, $start: 320, $end: 1280 ) {

	$multiplier: ($max - $min) / ($end - $start) * 100;
	$adder: ($min * $end - $max * $start) / ($end - $start);
	$formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});

	@return $formula;
}


/**
 * Overlay
 */
@mixin overlay( $z-index: 0 ) {
	display: block;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-index;
	width: 100%;
	height: 100%;
}


/**
 * Generic transition
 */
@mixin transition-ease-in-out( $speed: 200ms ) {
	-webkit-transition: all ease-in-out $speed;
			transition: all ease-in-out $speed;
}


/**
 * Clearfix
 */
@mixin clearfix() {

	&:before {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
		content: " ";
		display: table;
	}
}



/**
 * Rem Calc!
 */
$global-font-size: 100% !default;

@function strip-unit( $num ) {
	@return $num / ( $num * 0 + 1 );
}

@function rem-calc( $values, $base: null ) {

	$rem-values: ();
	$count: length( $values );

	// If no base is defined, defer to the global font size
	@if $base == null {
		$base: $global-font-size;
	}

	// If the base font size is a %, then multiply it by 16px
	// This is because 100% font size = 16px in most all browsers
	@if unit( $base ) == '%' {
		$base: ( $base / 100% ) * 15px;
	}

	@if $count == 1 {
		@return -zf-to-rem( $values, $base );
	}

	@for $i from 1 through $count {
		$rem-values: append( $rem-values, -zf-to-rem( nth( $values, $i ), $base ) );
	}

	@return $rem-values;
}

@function -zf-to-rem( $value, $base: null ) {

	// Check if the value is a number
	@if type-of( $value ) != 'number' {
		@warn inspect( $value ) + ' was passed to rem-calc(), which is not a number.';
		@return $value;
	}

	// Calculate rem if units for $value is not rem
	@if unit( $value ) != 'rem' {
		$value: strip-unit( $value ) / strip-unit( $base ) * 1rem;
	}

	// Turn 0rem into 0
	@if $value == 0rem {
		$value: 0;
	}

	@return $value;
}